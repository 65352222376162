
import Vue from "vue";
import Api from "@/services/Api";
import Auth from "@/lib/Auth";
import { AxiosResponse } from "axios";
import { Overlay } from "@/lib/Overlay";
import { Alert } from "@/lib/Alert";
import VueClipboard from "vue-clipboard2";
import { Snackbar } from "@/lib/Snackbar";
import moment from "moment";
import { DepositoBoleto } from "@/models/DepositoBoleto";
import { VMoney } from "v-money";

import Swal from "sweetalert2";

Vue.use(VueClipboard);

export default Vue.extend({
  name: "AdicionarSaldoBoleto",

  data() {
    return {
      pinCode: "",
      dialogBoleto: false,
      dialogPIN: false,
      form: {} as DepositoBoleto,
      errors: [],
      valid: true,
      btnLoading: false,
      alert: {} as Alert,
      panelAtividadeRecentes: 0,
      panelModuloInfo: 0,
      isMobile: false,
      dialogInformarDeposito: false,
      ModuloInfo: [],
      Tarifas: [],
      ContasDisponivels: [],
      AtividadeRecente: [],
      BoletoData: [],
      loadingData: true,
      lang: navigator.language,
      idBoletoCancela: 0,
      moment: moment,
      dadosUsuario: {},
      money: {
        decimal: ",",
        thousands: ".",
        prefix: "R$ ",
        suffix: "",
        precision: 2,
        masked: false /* doesn't work with directive */,
      },
    };
  },
  created() {
    this.isMobile = this.detectMobile();
    this.moduloInfo();
    this.dadosUsuario = Auth.getUserData();
  },

  methods: {
    dialogCancelaBoleto(id: number) {
      this.idBoletoCancela = id;
      this.dialogPIN = true;
      this.$nextTick(() => {
        this.focusPin();
      });
    },
    closeDialogPin() {
      this.pinCode = "";
      this.dialogPIN = false;
    },
    focusPin() {
      this.$nextTick(() => {
        const wrapper = this.$refs.pincode as any;
        const cells = wrapper.$el.getElementsByClassName(
          "vue-pincode-input"
        ) as any;

        window.setTimeout(() => wrapper?.focus(), 0);
      });
    },
    ckStatusAlert(status: string) {
      if (status === "PENDENTE") {
        return "warning";
      }
      if (status === "CANCELADO") {
        return "error";
      }

      if (status === "APROVADO") {
        return "success";
      }
    },

    ckIconStatus(status: string) {
      if (status === "PENDENTE") {
        return "mdi mdi-clock-outline";
      }
      if (status === "CANCELADO") {
        return "mdi mdi-block-helper";
      }
      if (status === "APROVADO") {
        return "mdi mdi-check-circle-outline";
      }
    },
    ckStatusChip(status: string) {
      if (status === "PENDENTE") {
        return "orange";
      }
      if (status === "CANCELADO") {
        return "red";
      }
      if (status === "APROVADO") {
        return "green";
      }
    },

    detectMobile() {
      if (screen.width <= 760) {
        return true;
      } else {
        return false;
      }
    },
    visualizarBoleto(data: any) {
      this.BoletoData = data;
      this.dialogBoleto = true;
    },

    moduloInfo() {
      const id = this.$route.params.id;
      Overlay.show();

      this.loadingData = true;
      Api.get("/v2/client/financeiro/adicionar/boleto/info")
        .then((response) => {
          this.ModuloInfo = response.data.body.moduloInfo;
          this.Tarifas = response.data.body.tarifas;

          // this.ContasDisponivels = response.data.body.bancos;
          this.AtividadeRecente = response.data.body.boletosRecentes;
          this.loadingData = false;
        })
        .catch((error: any) => {
          const response = error.response as AxiosResponse;
          const status = response.status;
          const data = response.data;

          if (status === 404) {
            Snackbar.show(data.body.message, "error");
          }
        })
        .finally(() => {
          Overlay.hide();
        });
    },
    gerarBoleto() {
      // console.log(this.form.valor.length);
      // console.log(this.form.valor);

      if (this.form.valor.length < 3) {
        Swal.fire({
          text: "Informe um valor",
          icon: "error",
        });
        return false;
      }
      Swal.fire({
        icon: "question",
        title: "Confirmação",
        text: "Confirma a geração do boleto?",
        showCancelButton: true,
        confirmButtonText: "Sim",
        cancelButtonText: "Não",
      }).then((result) => {
        if (result.isConfirmed) {
          this.errors = [];
          this.btnLoading = true;
          Overlay.show();
          Api.post("/v2/client/financeiro/adicionar/boleto/emitir", this.form)
            .then((response) => {
              //
              this.BoletoData = response.data.body;
              this.dialogBoleto = true;
              this.moduloInfo();
            })
            .catch((error: any) => {
              const response = error.response as AxiosResponse;
              const status = response.status;
              const data = response.data;

              Swal.fire({
                text: data.body.error,
                icon: "error",
                confirmButtonText: "Ok",
                showConfirmButton: true,
                customClass: {
                  confirmButton: "d-block",
                },
              });
            })
            .finally(() => {
              this.btnLoading = false;

              Overlay.hide();
            });
        }
      });
    },
    cancelaBoleto() {
      this.showAlert();
      if (this.pinCode.length != 6) {
        this.focusPin();
        this.showAlert(true, "Informe seu PIN");
        return false;
      }
      this.errors = [];
      this.btnLoading = true;
      Overlay.show();
      Api.put(
        "/v2/client/financeiro/adicionar/boleto/cancelar/" +
          this.idBoletoCancela,
        { transactionPin: this.pinCode }
      )
        .then((response) => {
          //
          Swal.fire({
            text: response.data.body.message,
            icon: "success",
            confirmButtonText: "Ok",
            showConfirmButton: true,
            customClass: {
              confirmButton: "d-block",
            },
          });
          this.moduloInfo();
          this.dialogPIN = false;
          this.pinCode = "";
        })
        .catch((error: any) => {
          const response = error.response as AxiosResponse;
          const status = response.status;
          const data = response.data;

          Swal.fire({
            text: data.body.error,
            icon: "error",
            confirmButtonText: "Ok",
            showConfirmButton: true,
            customClass: {
              confirmButton: "d-block",
            },
          });
          this.pinCode = "";
        })
        .finally(() => {
          this.btnLoading = false;

          Overlay.hide();
        });
    },

    doCopy(str: string) {
      const container = document.querySelector(".v-dialog") as HTMLElement;

      this.$copyText(str, container).then(function () {
        Snackbar.show("Copiado com sucesso!");
      });
    },

    onCopy() {
      // console.log(str);

      Snackbar.show("Copiado com sucesso!");
    },
    onCopyError() {
      // console.log(str);

      Snackbar.show("Erro ao copiar para área de transferência", "error");
    },
    showAlert(show = false, message = "", type = "error"): void {
      this.alert = {
        show,
        type,
        message,
      };
    },
  },
  directives: { money: VMoney },
});
