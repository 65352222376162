
import Vue from "vue";
import Api from "@/services/Api";
import { AxiosResponse } from "axios";
import { Overlay } from "@/lib/Overlay";
import moment from "moment";
import { Snackbar } from "@/lib/Snackbar";
import Avatar from "@/views/profile/Avatar.vue";

import Swal from "sweetalert2";
import Auth from "@/lib/Auth";
export default Vue.extend({
  name: "Perfil",

  data() {
    return {
      isMobile: false,
      pinCode: "",
      stepEncerraConta: 1,
      dialogEncerrarConta: false,
      dadosComprovanteBoleto: [],
      loadingData: true,
      lang: navigator.language,
      errors: [],
      moment: moment,
      profileData: {
        dados: { faltaDadosBasicos: false },
        endereco: { faltaDadosEndereco: false },
      },
      selectedFile: null,
      avatarUpload: "",
      profileAvatar: "",
      hasDocsPendente: false,
    };
  },

  created() {
    this.isMobile = this.detectMobile();

    this.getSituacaoConta();
  },

  components: {
    Avatar,
  },
  methods: {
    focusPin() {
      this.$nextTick(() => {
        const wrapper = this.$refs.pincode as any;
        const cells = wrapper.$el.getElementsByClassName(
          "vue-pincode-input"
        ) as any;

        window.setTimeout(() => wrapper?.focus(), 0);
      });
    },
    openDialogEncerraConta() {
      this.stepEncerraConta = 1;
      this.dialogEncerrarConta = true;
    },

    closeDialogEncerraConta() {
      this.stepEncerraConta = 1;
      this.pinCode = "";
      this.dialogEncerrarConta = false;
    },
    detectMobile() {
      if (screen.width <= 760) {
        return true;
      } else {
        return false;
      }
    },
    SolicitaCancelamento() {
      if (this.pinCode.length < 6) {
        Snackbar.show("Informe seu PIN de transações", "error");
        this.focusPin();
        return false;
      }
      Overlay.show();

      this.dialogEncerrarConta = false;

      Api.post("/v2/client/account/encerramento/solicita", {
        transactionPin: this.pinCode,
      })
        .then((response) => {
          Swal.fire({
            title: "Para prosseguir, abra seu email e clique no link enviado.",
            text: response.data.body.message,
            icon: "success",
            confirmButtonText: "Ok",
            showConfirmButton: true,
            customClass: {
              confirmButton: "d-block",
            },
          }).then((result) => {
            if (result.isConfirmed) {
              Auth.logout();
            }
          });
        })
        .catch((error: any) => {
          const response = error.response as AxiosResponse;
          const status = response.status;
          const data = response.data;
          this.pinCode = "";
          Swal.fire({
            text: data.body.error,
            icon: "error",
            confirmButtonText: "Ok",
            showConfirmButton: true,
            customClass: {
              confirmButton: "d-block",
            },
          });
        })
        .finally(() => {
          Overlay.hide();
        });
    },
    moduloInfo() {
      const id = this.$route.params.id;
      Overlay.show();

      this.loadingData = true;
      Api.get("/v2/client/profile/show")
        .then((response) => {
          this.profileData = response.data.body;
          this.profileAvatar = response.data.body.dados.avatar;
          this.loadingData = false;
        })
        .catch((error: any) => {
          const response = error.response as AxiosResponse;
          const status = response.status;
          const data = response.data;

          if (status != 200) {
            //exe
          }
          // this.resetRecaptcha();
        })
        .finally(() => {
          Overlay.hide();
        });
    },

    getSituacaoConta() {
      this.loadingData = true;
      Overlay.show();
      Api.get("/v2/client/dashboard/statusConta")
        .then((response) => {
          this.hasDocsPendente = response.data.body.doc_pendente;
        })
        .catch((error: any) => {
          const response = error.response as AxiosResponse;
          const status = response.status;
          const data = response.data;
        })
        .finally(() => {
          this.loadingData = false;
          Overlay.hide();
          this.moduloInfo();
        });
    },
  },
});
