
import Vue from "vue";
import Auth from "@/lib/Auth";
import Api from "@/services/Api";
import { Login } from "@/models/Login";
import Axios, { AxiosResponse } from "axios";
import Swal from "sweetalert2";
import { Overlay } from "@/lib/Overlay";
import router from "@/router";
import { Snackbar } from "@/lib/Snackbar";

export default Vue.extend({
  name: "RegistroDispositivo",
  data: () => ({
    iProof: "email",
    codigoRegistroDispositivo: "",
    step: 1,
    valid: true,
    form: {} as Login,
    dadosRegistroDispositivo: [] as any,
    dadosUser: [] as any,
    errors: [],
    btnLoading: false,
    lembraLogin: false,
    clientIp: "0.0.0.0",
    deviceInfo: {},
    sitekey: process.env.VUE_APP_RECAPTCHA_SITE_KEY,
  }),

  created() {
    this.getdata();
  },

  methods: {
    getdata() {
      Overlay.show();
      if (
        sessionStorage.userData == undefined ||
        sessionStorage.userData == null
      ) {
        router.push("/login");
        return false;
      }

      this.dadosRegistroDispositivo = JSON.parse(
        sessionStorage.deviceRegisterData
      );
      this.dadosUser = JSON.parse(atob(sessionStorage.userData));

      Overlay.hide();
    },

    sendCode() {
      this.errors = [];
      this.btnLoading = true;
      Overlay.show();

      Api.post("/v2/client/auth/device/sendcode", {
        hashDevice: this.dadosRegistroDispositivo.hashDevice,
        optionSendCode: "email",
      })
        .then((response) => {
          this.step = 2;
        })
        .catch((error: any) => {
          const response = error.response as AxiosResponse;
          const status = response.status;
          const data = response.data;

          if (status === 422) {
            this.errors = data.body.errors;
          } else if (status === 409) {
            this.step = 2;
          } else {
            Swal.fire({
              text: data.body.error,
              icon: "error",
              confirmButtonText: "Ok",
              showConfirmButton: true,
              customClass: {
                confirmButton: "d-block",
              },
            });
          }
          // this.resetRecaptcha();
        })
        .finally(() => {
          this.btnLoading = false;
          //  console.log("finalizou");

          Overlay.hide();
        });
    },

    validCode() {
      if (this.codigoRegistroDispositivo.length < 6) {
        return false;
      }

      this.errors = [];
      this.btnLoading = true;
      Overlay.show();
      Api.post("/v2/client/auth/device/validatecode", {
        hashDevice: this.dadosRegistroDispositivo.hashDevice,
        code: this.codigoRegistroDispositivo,
      })
        .then((response) => {
          sessionStorage.setItem(
            "_vid",
            this.dadosRegistroDispositivo.hashDevice
          );
          // Auth.login(atob(sessionStorage.pre_access_token));
          router.push("/login");
          Snackbar.show("Dispositivo regitrado");
        })
        .catch((error: any) => {
          const response = error.response as AxiosResponse;
          const status = response.status;
          const data = response.data;

          if (status === 422) {
            this.errors = data.body.errors;
          } else {
            Swal.fire({
              text: data.body.error,
              icon: "error",
              confirmButtonText: "Ok",
              showConfirmButton: true,
              customClass: {
                confirmButton: "d-block",
              },
            });
            this.codigoRegistroDispositivo = "";
          }
          // this.resetRecaptcha();
        })
        .finally(() => {
          this.btnLoading = false;
          //  console.log("finalizou");

          Overlay.hide();
        });
    },
  },
});
