
import Vue from "vue";
import Api from "@/services/Api";
import { AxiosResponse } from "axios";
import { Overlay } from "@/lib/Overlay";
import { Alert } from "@/lib/Alert";
import moment from "moment";
import { Snackbar } from "@/lib/Snackbar";
import Swal from "sweetalert2";
import { TableOptions } from "@/models/TableOptions";

export interface ResumoAnual {
  monthName?: string;
  in?: string;
  out?: string;
  balance?: string;
}

export default Vue.extend({
  name: "ResumoAnual",

  data() {
    return {
      ano: new Date().getFullYear(),
      anos: Array.from(
        { length: new Date().getFullYear() - 2020 },
        (value, index) => 2021 + index
      ),
      startYear: 2021,
      endYear: new Date().getFullYear(),
      menuFinalDate: false,

      loadingData: false,
      search: "",
      loadingDesserts: false,
      lang: navigator.language,

      errors: [],
      moment: moment,
      isMobile: false,
      page: 1,
      pageCount: 0,
      itemsPerPage: 20,
      options: {} as TableOptions,
      desserts: new Array<ResumoAnual>(),
      saldoTotal: "0,00",
      totalDesserts: 0,
      timeout: 0,
      startDate: moment().startOf("month").format("YYYY-MM-DD"),
      endDate: moment().endOf("month").format("YYYY-MM-DD"),
      defaultStartDate: moment().startOf("month").format("YYYY-MM-DD"),
      defaultEndDate: moment().endOf("month").format("YYYY-MM-DD"),
      maxDate: moment().format("YYYY-MM-DD"),
    };
  },
  created() {
    this.isMobile = this.detectMobile();
    // this.anos = this.yearList();
  },

  methods: {
    yearList() {
      const years = [];
      for (let i = this.endYear; i >= this.startYear; i--) {
        years.push(i);
      }
      this.anos = JSON.parse(JSON.stringify(years));
     // console.log(this.anos);
      // return years;
    },
    getYears() {
      let years = [];
      const curYear = new Date().getFullYear();

      for (let index = 2020; index <= curYear; index++) {
        years.push(index);
       // console.log(index);
      }
      //return years;
    },
    doDelayedSearch() {
      if (this.timeout) {
        clearTimeout(this.timeout);
      }
      this.timeout = setTimeout(() => {
        //this is your existing function
        this.loadDatas();
      }, 1000);
    },
    detectMobile() {
      if (screen.width <= 760) {
        return true;
      } else {
        return false;
      }
    },
    formataValor(data: number) {
      const valor = new Intl.NumberFormat("pt-BR", {
        style: "currency",
        currency: "BRL",
      }).format(data);

      return valor.toString().replace("R$", "").trim();
    },
    loadDatas() {
      Overlay.show();

      this.loadingData = true;

      this.loadingDesserts = true;
      Api.get("/v2/client/financeiro/resumoAnual/" + this.ano)
        .then((response) => {
          this.desserts = response.data.body.results as ResumoAnual[];
          this.saldoTotal = response.data.body.balancePeriod;

          this.loadingDesserts = false;
          this.loadingData = false;
        })
        .catch((error: any) => {
          const response = error.response as AxiosResponse;
          const status = response.status;
          const data = response.data;

          if (status != 200) {
            //exe
          }
          // this.resetRecaptcha();
        })
        .finally(() => {
          Overlay.hide();
        });
    },
  },
  computed: {
    breadcrumbs() {
      return [
        {
          text: this.$t("components.ResumoAnual.template.Home"),
          disabled: false,
          href: "/",
        },
        {
          text: this.$t("components.ResumoAnual.template.Reports"),
          disabled: false,
          href: "/relatorios",
        },
        {
          text: this.$t("components.ResumoAnual.template.AnnualSummary"),
          disabled: false,
          href: "/relatorios/resumo/anual",
        },
      ];
    },
  },
});
