
import Vue from "vue";
import moment from "moment";
import { Overlay } from "@/lib/Overlay";
import { Alert } from "@/lib/Alert";
import Api from "@/services/Api";
import { AxiosResponse } from "axios";

export default Vue.extend({
  name: "homeMenuAfiliados",
  data() {
    return {
      ModuloInfo: [],
      emv: "",
      alert: {} as Alert,
      loadingData: true,
      btnloading: false,
      btnDisabled: true,
      dialogPixCopyPaste: false,
      isMobile: false,
      lang: navigator.language,
      errors: [],
      moment: moment,
      mfaEnabled: true,
      hasInfoSituacaoConta: false,
      situacaoConta: {},
      loadedData: false,
    };
  },
  computed: {
    breadcrumbs() {
      return [
        {
          text: this.$t("components.preCadastro.template.form.Home"),
          disabled: false,
          href: "/",
        },
        {
          text: this.$t("components.preCadastro.template.form.Affiliates"),
          disabled: true,
          href: "/pix",
        },
      ];
    },
  },
  created() {
    this.isMobile = this.detectMobile();
  },
  methods: {
    detectMobile() {
      return screen.width <= 760;
    },
  },
});
