
import Vue from "vue";
import Api from "@/services/Api";

import { AxiosResponse } from "axios";
import { Overlay } from "@/lib/Overlay";
import { TableOptions } from "@/models/TableOptions";
import { Snackbar } from "@/lib/Snackbar";
import DialogNovoCliente from "@/views/cobrancas/clientes/ClienteNovoDialog.vue";
import DialogEditCliente from "@/views/cobrancas/clientes/ClienteEditDialog.vue";
import moment from "moment";
import Swal from "sweetalert2";

export interface cobrancasClientes {
  id?: number;
  createdAt?: string;
  uuid?: string;
  name?: string;
  taxId?: string;
  phone?: string;
  email?: string;
  street?: string;
  number?: string;
  complement?: string;
  neighborhood?: string;
  city?: string;
  state?: string;
  zipCode?: string;
}

export default Vue.extend({
  data() {
    return {
      lang: navigator.language,
      showEditCliente: false,
      moment: moment,
      search: "",
      isMobile: false,
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
      options: {} as TableOptions,
      headers: [] as { text: string, value: string, sortable: boolean }[],
      desserts: new Array<cobrancasClientes>(),
      loadingDesserts: false,
      totalDesserts: 0,
      timeout: 0,
    };
  },
  created() {
    this.isMobile = this.detectMobile();
    this.updateHeaders();
  },
  components: {
    DialogNovoCliente,
    DialogEditCliente,
  },
  computed: {
    translatedHeaders() {
      return [
        { text: this.$t('components.preCadastro.template.form.Name'), value: 'name' },
        { text: this.$t('components.preCadastro.template.form.Document'), value: 'taxId' },
        { text: this.$t('components.preCadastro.template.form.Phone'), value: 'phone' },
        { text: this.$t('components.preCadastro.template.form.Creation'), value: 'createdAt' },
      ];
    }
  },
  watch: {
    options: {
      handler() {
        this.loadDatas();
      },
    },
    search: {
      handler() {
        this.doDelayedSearch();
      },
    },
    '$i18n.locale': {
      handler() {
        this.updateHeaders();
      },
      immediate: true,
    },
  },
  methods: {
    updateHeaders() {
      this.headers = [
        { text: "", value: "id", sortable: false },
        { text: this.$t("components.preCadastro.template.form.Name") as string, value: "name", sortable: false },
        { text: this.$t("components.preCadastro.template.form.Document") as string, value: "taxId", sortable: false },
        { text: this.$t("components.preCadastro.template.form.Phone") as string, value: "phone", sortable: false },
        { text: "E-mail", value: "email", sortable: false },
        { text: this.$t("components.preCadastro.template.form.Creation") as string, value: "createdAt", sortable: false },
      ];
    },
    detectMobile() {
      if (screen.width <= 760) {
        return true;
      } else {
        return false;
      }
    },
    doDelayedSearch() {
      if (this.timeout) {
        clearTimeout(this.timeout);
      }
      this.timeout = setTimeout(() => {
        //this is your existing function
        this.loadDatas();
      }, 1000);
    },
    loadDatas() {
      Overlay.show();
      const { sortBy, sortDesc, page, itemsPerPage } = this.options;
      this.loadingDesserts = true;
      
      Api.get("/v2/client/search", {
        params: {
          query: this.search,
          page: page,
          pageSize: itemsPerPage,
          sort: sortBy.toString(),
          desc: sortDesc.toString(),
        },
      })
        .then((response) => {
          this.desserts = response.data.body.rows as cobrancasClientes[];
          this.totalDesserts = Number(response.data.body.count.__total);
        })
        .finally(() => {
          Overlay.hide();
          this.loadingDesserts = false;
        });
    },
    editarCliente(item: any) {
      localStorage.setItem("editCliente", JSON.stringify(item));
      this.$nextTick(() => {
        this.showEditCliente = true;
        const dialogEditClient = this.$refs.dialogEditCliente as any;
        dialogEditClient.show();
      });
    },
    formataCPFCNPJ(str: string) {
      let temp = str.replace(/[^\d]/g, "");

      if (temp.length < 12) {
        temp = temp.replace(/^(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
      } else {
        temp = temp.replace(
          /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
          "$1.$2.$3/$4-$5"
        );
      }
      return temp.toString().trim();
    },

    confirmRemove(id: number) {
      // Use sweetalert2

      Swal.fire({
        html: '<h3  style="color:red !important;  margin-bottom:15px;">Você deseja excluir o cliente selecionado?</h3><h5>As cobranças emitidas para esses clientes continuarão ativas.</h5><div style="color:red !important; margin-top:15px; margin-bottom:15px;"><h5>Esta alteração não poderá ser desfeita.</h5></div>',
        icon: "question",
        iconColor: "#F44336",
        showCancelButton: true,
        confirmButtonColor: "#F44336",
        cancelButtonColor: "#757575",
        cancelButtonText: "Não excluir",
        confirmButtonText: "Excluir cliente",
      }).then((result) => {
        if (result.isConfirmed) {
          Api.delete("/v2/client/del/" + id).then(
            (response) => {
              Snackbar.show("Cliente excluido com sucesso");
              this.loadDatas();
            }
          );
        }
      });
    },
  },
});
