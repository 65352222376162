
import Vue from "vue";
import Auth from "@/lib/Auth";
import Api from "@/services/Api";
import { RedefinirSenhaForgot } from "@/models/RedefinirSenhaForgot";
import Axios, { AxiosResponse } from "axios";
import Swal from "sweetalert2";
import { Overlay } from "@/lib/Overlay";
import router from "@/router";
import VueRecaptcha from "vue-recaptcha";

export default Vue.extend({
  name: "resetPIN",
  data: () => ({
    showpass: false,

    valid: true,
    hasSent: false,
    msgHasSent: "Senha alterada com sucesso!",
    form: {} as RedefinirSenhaForgot,
    errors: [],
    btnLoading: false,
    lembraLogin: false,
    clientIp: "0.0.0.0",
  }),
  created() {
    this.redefinirPin();
  },
  methods: {
    redefinirPin() {
      this.errors = [];
      this.btnLoading = true;
      Overlay.show();
      Api.put("/v2/client/profile/security/forgotPIN/reset", {
        token: this.$route.params.token,
      })
        .then((response) => {
          this.msgHasSent = response.data.body.message;

          this.hasSent = true;
        })
        .catch((error: any) => {
          const response = error.response as AxiosResponse;
          const status = response.status;
          const data = response.data;

          Swal.fire({
            text: data.body.error,
            icon: "error",
            confirmButtonText: "Ok",
            showConfirmButton: true,
            customClass: {
              confirmButton: "d-block",
            },
          });
        })
        .finally(() => {
          this.btnLoading = false;
          //  console.log("finalizou");

          Overlay.hide();
        });
    },
  },
});
