
import Vue from "vue";
import Api from "@/services/Api";
import Auth from "@/lib/Auth";
import { AxiosResponse } from "axios";
import { Overlay } from "@/lib/Overlay";
import { Alert } from "@/lib/Alert";
import moment from "moment";
import { Snackbar } from "@/lib/Snackbar";
import Swal from "sweetalert2";
import router from "@/router";
import { TableOptions } from "@/models/TableOptions";

export interface IbTransferencias {
  id?: number;
  data?: string;
  identificacao?: string;
  nome?: string;
  usuario?: string;
  cpfcnpj?: string;
  valor?: number;
  valorFormatado?: string;
}

export default Vue.extend({
  name: "AdicionarSaldoTED",

  data() {
    return {
      txtAction: "Autorizar ",
      pinCodeProcessaPagtos: "",
      dialogPINProcessaPagtos: false,
      countSelecionados: "",
      btnLoadingCancela: false,
      btnLoadingAutoriza: false,
      btnLoading: false,
      pendentesPagarQtd: 0,
      pendentesPagarVlr: "0,00",
      saldoDisponivel: "0,00",
      singleSelect: false,
      buttonDisabled: true,
      selected: [],
      ModuloInfo: [],
      search: "",
      loadingDesserts: true,
      transferenciasPagar: [],
      transferenciasReceber: [],
      lang: navigator.language,
      errors: [],
      moment: moment,
      isMobile: false,
      page: 1,
      pageCount: 0,
      itemsPerPage: 20,
      options: {} as TableOptions,
      headersPagar: [] as { text: string, value: string, sortable: boolean, align?: string }[], // Inicialize como um array vazio
      dessertsPagar: new Array<IbTransferencias>(),
      totalDessertsPagar: 0,
      timeout: 0,
    };
  },
  created() {
    this.isMobile = this.detectMobile();
    this.updateHeaders();
  },
  computed: {
    translatedHeaders() {
      return [
        { text: this.$t('components.preCadastro.template.form.BENEFICIARY') as string, value: 'nome' },
        { text: this.$t('components.preCadastro.template.form.Identification') as string, value: 'identificacao' },
        { text: this.$t('components.preCadastro.template.form.Data') as string, value: 'data' },
        { text: this.$t('components.preCadastro.template.form.Value') as string, value: 'valorFormatado' },
      ];
    }
  },
  watch: {
    '$i18n.locale': {
      handler() {
        this.updateHeaders();
      },
      immediate: true
    },
    options: {
      handler() {
        this.loadDatas();
      },
    },
    search: {
      handler() {
        this.doDelayedSearch();
      },
    },
  },
  methods: {
    updateHeaders() {
      this.headersPagar = [
        { text: "", value: "id", sortable: false, align: " d-none" },
        { text: this.$t("components.preCadastro.template.form.BENEFICIARY") as string, value: "nome", sortable: false },
        { text: this.$t("components.preCadastro.template.form.Identification") as string, value: "identificacao", sortable: false },
        { text: this.$t("components.preCadastro.template.form.Data") as string, value: "data", sortable: false },
        { text: this.$t("components.preCadastro.template.form.Value") as string, value: "valorFormatado", sortable: false, align: "right fw-bolder" },
      ];
    },
    closedialogPINProcessaPagtos() {
      this.pinCodeProcessaPagtos = "";
      this.txtAction = "Autorizar ";
      this.dialogPINProcessaPagtos = false;
    },
    focusPinProcessaPagtos() {
      this.$nextTick(() => {
        const wrapper = this.$refs.pincodeProcessaPagtos as any;
        window.setTimeout(() => wrapper?.focus(), 0);
      });
    },
    getSelecion() {
      if (this.selected.length > 0) {
        this.buttonDisabled = false;
        this.countSelecionados = " (" + this.selected.length.toString() + ")  ";
      } else {
        this.buttonDisabled = true;
        this.countSelecionados = "";
      }
    },
    doDelayedSearch() {
      if (this.timeout) {
        clearTimeout(this.timeout);
      }
      this.timeout = setTimeout(() => {
        this.loadDatas();
      }, 1000);
    },
    detectMobile() {
      return screen.width <= 760;
    },
    getSaldo() {
      Overlay.show();
      Api.get("/v2/client/financeiro/saldo")
        .then((response) => {
          this.saldoDisponivel = response.data.body.saldoFormatado;
        })
        .finally(() => {
          Overlay.hide();
          this.loadingDesserts = false;
        });
    },
    loadDatas() {
      const us = Auth.getUser();
      if (!us.data.apiStatus) {
        router.push("/");
        return false;
      }
      Overlay.show();
      const { sortBy, sortDesc, page, itemsPerPage } = this.options;
      this.loadingDesserts = true;
      Api.get("/v2/client/financeiro/transferencia/pendentes", {
        params: {
          q: this.search,
          page: page,
          limit: itemsPerPage,
          sort: sortBy.toString(),
          desc: sortDesc.toString(),
        },
      })
        .then((response) => {
          this.dessertsPagar = response.data.body.pagar.rows as IbTransferencias[];
          this.totalDessertsPagar = Number(response.data.body.pagar.count.__total);
          this.pendentesPagarQtd = response.data.body.pagar.count.__total;
          this.pendentesPagarVlr = response.data.body.pagar.valorFormatado;
          this.getSaldo();
        })
        .finally(() => {
          Overlay.hide();
          this.loadingDesserts = false;
        });
    },
    showDialogProcessaPagamentos(action: string) {
      if (this.selected.length > 0) {
        this.txtAction = action;
        this.dialogPINProcessaPagtos = true;
        this.focusPinProcessaPagtos();
      }
    },
    ProcessarPagamentos() {
      if (this.selected.length < 1) {
        Snackbar.show("Não foi encontrada uma seleção", "error");
        return false;
      }
      let url = "autorizaPendente";
      if (this.txtAction === "Cancelar ") {
        url = "cancelaPendente";
      }
      const arrTransferencias = [] as any;
      for (let index = 0; index < this.selected.length; index++) {
        const element = this.selected[index]["id"];
        arrTransferencias.push(element);
      }
      this.dialogPINProcessaPagtos = false;
      Overlay.show();
      this.loadingDesserts = true;
      Api.post("/v2/client/financeiro/transferencia/" + url, {
        transactionPin: this.pinCodeProcessaPagtos,
        idTransferencia: arrTransferencias,
      })
        .then((response) => {
          Swal.fire({
            text: response.data.body.message,
            icon: "success",
            confirmButtonText: "Ok",
            showConfirmButton: true,
            customClass: { confirmButton: "d-block" },
          });
          this.selected = [];
        })
        .catch((error: any) => {
          const response = error.response as AxiosResponse;
          const status = response.status;
          const data = response.data;
          Swal.fire({
            text: data.body.error,
            icon: "error",
            confirmButtonText: "Ok",
            showConfirmButton: true,
            customClass: { confirmButton: "d-block" },
          });
        })
        .finally(() => {
          this.loadDatas();
          Overlay.hide();
          this.loadingDesserts = false;
        });
    },
    CancelaPagamentos() {
      for (let index = 0; index < this.selected.length; index++) {
        const element = this.selected[index]["id"];
        console.log(element);
      }
    },
    SomaPagamentos() {
      let total = 0 as number;
      for (let index = 0; index < this.selected.length; index++) {
        total += parseInt(this.selected[index]["valor"]);
      }
      console.log(total);
    },
  },
});
