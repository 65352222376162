import router from "../router";
import jwtDecode from "jwt-decode";

interface User {
  data?: any;
  id: number;
  sub: string;
  name: string;
  username: string;
  email: string;
  phone: string;
  avatar: string;
  cartola: string;
}

class Auth {
  login(token: string) {
    //sessionStorage.token = token;
    sessionStorage.setItem("token", token);
    sessionStorage.removeItem("pre_access_token");
    sessionStorage.removeItem("deviceRegisterData");
    const dadosUser = JSON.parse(JSON.stringify(this.getUser()));

    router.push("/");
  }

  getUser(): User {
    return jwtDecode(sessionStorage.token) as User;
  }

  logout() {
    sessionStorage.removeItem("token");
    sessionStorage.removeItem("userData");
    sessionStorage.removeItem("access_token");
    window.location.reload();
    router.push("/login");
  }
  getUserData() {
    const dados = JSON.parse(atob(sessionStorage.userData));
    return dados;
  }
  mfaEnabled() {
    const userData = this.getUser();
    if (userData.data.mfa == undefined || userData.data.mfa == null) {
      this.logout();
    }
    if (userData.data.mfa) {
      return true;
    }
    return false;
  }

  isLogged(): boolean {
    if (
      sessionStorage.token == undefined ||
      sessionStorage.token == null ||
      localStorage.MachineId == undefined ||
      localStorage.MachineId == null ||
      sessionStorage.userData == undefined ||
      sessionStorage.userData == null ||
      sessionStorage._vid == undefined ||
      sessionStorage._vid == null
    ) {
      return false;
    } else {
      return true;
    }
  }
}
export default new Auth();
