
import Vue from "vue";
import Api from "@/services/Api";
import { AxiosResponse } from "axios";
import { Overlay } from "@/lib/Overlay";
import { Alert } from "@/lib/Alert";
import moment from "moment";
import { Snackbar } from "@/lib/Snackbar";
import Swal from "sweetalert2";
export default Vue.extend({
  name: "ConfiguracoesPIX",

  data() {
    return {
      ModuloInfo: [],
      loadingData: true,
      dialogLimiteNoturno: false,
      dialogLimiteDiurno: false,
      lang: navigator.language,
      errors: [],
      moment: moment,
      isMobile: false,
    };
  },
  created() {
    this.isMobile = this.detectMobile();
    this.moduloInfo();
  },

  methods: {
    detectMobile() {
      if (screen.width <= 760) {
        return true;
      } else {
        return false;
      }
    },
    moduloInfo() {
      const id = this.$route.params.id;
      Overlay.show();

      this.loadingData = true;
      Api.get("/v2/public/comprovantes/boleto/" + id)
        .then((response) => {
          this.ModuloInfo = response.data.body.moduloInfo;
          this.loadingData = false;
        })
        .catch((error: any) => {
          const response = error.response as AxiosResponse;
          const status = response.status;
          const data = response.data;

          if (status != 200) {
            //exe
          }
          // this.resetRecaptcha();
        })
        .finally(() => {
          Overlay.hide();
        });
    },
  },
});
