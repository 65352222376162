
import Vue from "vue";
import Api from "@/services/Api";
import { AxiosResponse } from "axios";
import { Overlay } from "@/lib/Overlay";
import { Alert } from "@/lib/Alert";
import moment from "moment";
import { Snackbar } from "@/lib/Snackbar";
import Swal from "sweetalert2";
import VueMask from "v-mask";
import { VMoney } from "v-money";
import { TransferenciasPixChave } from "@/models/TransferenciaPixChave";

interface Breadcrumb {
  text: string;
  disabled: boolean;
  href: string;
}

Vue.use(VueMask);
export default Vue.extend({
  name: "AdicionarSaldoTED",

  data() {
    return {
      dialogProcessandoPix: false,
      dialogPixEmProcessamento: false,
      dialogValorTransferPix: false,
      dialogRevisaoTransferPix: false,
      dialogInformacoesTarifas: false,
      dialogComprovantePixEnviado: false,
      dialogInfoChave: false,
      ModuloInfo: [] as any,
      formTransferPix: {} as TransferenciasPixChave,
      initiationType: "DICT",
      valid: true,
      btnloading: false,
      btnDisabled: true,
      chavePix: "",
      chavePixCelular: "",
      chavePixCPF: "",
      chavePixCNPJ: "",
      chavePixEmail: "",
      chavePixAleatoria: "",
      loadingData: true,
      curStep: 0,
      tipoChave: "",
      lang: navigator.language,
      errors: [] as any,
      moment: moment,
      dadosComprovantePixEnviado: [] as any,
      isMobile: false,
      validChavePixCPF: true,
      dadosChavePixDICT: {
        key: "fulano@gmail.com",
        keyType: "EMAIL",
        account: {
          openingDate: "2014-01-22T03:00:00Z",
          participant: "60701190",
          participantName: "ITAÚ UNIBANCO S.A.",
          branch: 1500,
          accountNumber: "00611833",
          accountType: "CACC",
        },
        owner: {
          taxIdNumber: "05216208000166",
          taxIdNumberObfuscate: "05.***.208/****-66",
          type: "LEGAL_PERSON",
          name: "TESTE DE RAZAO SOCIAL",
        },
        endtoendid: "E1393589320221005110900023724106",
      },
      money: {
        decimal: ",",
        thousands: ".",
        prefix: "R$ ",
        suffix: "",
        precision: 2,
        masked: false /* doesn't work with directive */,
      },
      breadcrumbs: [] as Breadcrumb[],
      ckStatusPagto: false,
      progressPixCkinterval: 0,
      progressTempoCkPix: 0,
      idPixChecking: "",
      qtdCheckStatus: 0,
      mfaEnabled: true,
      hasInfoSituacaoConta: false,
      situacaoConta: {},
      loadedData: false,
    };
  },
  created() {
    this.isMobile = this.detectMobile();
    this.updateBreadcrumbs();
    this.getSituacaoConta();
  },
  methods: {
    detectMobile() {
      if (screen.width <= 760) {
        return true;
      } else {
        return false;
      }
    },
    updateBreadcrumbs() {
    this.breadcrumbs = [
      {
        text: this.$t("components.preCadastro.template.form.Home") as string,
        disabled: false,
        href: "/",
      },
      {
        text: this.$t("components.preCadastro.template.form.transfer") as string,
        disabled: true,
        href: "/pix",
      },
    ];
  },
    resetModulo() {
      this.chavePix = "";
      this.chavePixCPF = "";
      this.chavePixCelular = "";
      this.chavePixCNPJ = "";
      this.chavePixEmail = "";
      this.chavePixAleatoria = "";
    },
    selectionaTipoChave(tipo: string) {
      this.chavePix = "";
      this.chavePixCPF = "";
      this.chavePixCelular = "";
      this.chavePixCNPJ = "";
      this.chavePixEmail = "";
      this.chavePixAleatoria = "";

      this.btnDisabled = true;
      if (tipo === "CPF") {
        const wrapper = this.$refs.chavecpfref as any;
        this.$nextTick(() => {
          window.setTimeout(() => wrapper?.focus(), 0);
        });
        this.tipoChave = "CPF";
      }
      if (tipo === "CNPJ") {
        const wrapper = this.$refs.chavecnpjref as any;
        this.$nextTick(() => {
          window.setTimeout(() => wrapper?.focus(), 0);
        });
        this.tipoChave = "CNPJ";
      }
      if (tipo === "EMAIL") {
        const wrapper = this.$refs.chaveemailref as any;
        this.$nextTick(() => {
          window.setTimeout(() => wrapper?.focus(), 0);
        });
        this.tipoChave = "EMAIL";
      }
      if (tipo === "TELEFONE") {
        const wrapper = this.$refs.chavecelularref as any;
        this.$nextTick(() => {
          window.setTimeout(() => wrapper?.focus(), 0);
        });
        this.tipoChave = "TELEFONE";
      }

      if (tipo === "CHAVE_ALEATORIA") {
        const wrapper = this.$refs.chaveAleatoriaref as any;
        this.$nextTick(() => {
          window.setTimeout(() => wrapper?.focus(), 0);
        });
        this.tipoChave = "CHAVE_ALEATORIA";
      }
    },
    ckLenghtChave() {
      if (this.tipoChave === "CPF" && this.chavePixCPF.length == 14) {
        this.chavePix = this.chavePixCPF;
        this.btnDisabled = false;
      }
      if (this.tipoChave === "CNPJ" && this.chavePixCNPJ.length == 18) {
        this.chavePix = this.chavePixCNPJ;
        this.btnDisabled = false;
      }

      if (this.tipoChave === "TELEFONE" && this.chavePixCelular.length == 14) {
        this.chavePix = this.chavePixCelular;
        this.btnDisabled = false;
      }
      if (
        this.tipoChave === "CHAVE_ALEATORIA" &&
        this.chavePixAleatoria.length > 30
      ) {
        this.chavePix = this.chavePixAleatoria;
        this.btnDisabled = false;
      }

      if (this.tipoChave === "EMAIL" && this.chavePixEmail.length > 10) {
        this.chavePix = this.chavePixEmail;
        this.btnDisabled = false;
      }
    },
    ckChavePix() {
      if (this.tipoChave === "EMAIL") {
        if (this.validateEmail(this.chavePixEmail)) {
          Swal.fire({
            title: "Opps",
            text: "Formato de chave inválido",
            icon: "error",
          });
          return false;
        }
      }
      Overlay.show();
      this.btnloading = true;
      Api.post("/v2/client/financeiro/pix/dict", {
        tipoChave: this.tipoChave,
        chave: this.chavePix,
      })
        .then((response) => {
          this.dadosChavePixDICT = response.data.body;
          this.formTransferPix.dadosChave = response.data.body;
          this.loadingData = false;
          this.dialogValorTransferPix = true;
          this.formTransferPix.initiationType = "DICT";
          console.log(this.formTransferPix);
          this.$nextTick(() => {
            const wrapper = this.$refs.valorTransferencia as any;
            window.setTimeout(() => wrapper?.focus(), 0);
          });
        })
        .catch((error: any) => {
          const response = error.response as AxiosResponse;
          const status = response.status;
          const data = response.data;

          if (status != 200) {
            Swal.fire({
              title: "Opps",
              text: data.body.error,
              icon: "error",
            });
          }
          // this.resetRecaptcha();
        })
        .finally(() => {
          Overlay.hide();
          this.btnloading = false;
        });
    },
    validateEmail(email: string) {
      if (/.+@.+\..+/.test(email)) {
        return false;
      } else {
        return true;
      }
    },
    moduloInfo() {
      Overlay.show();

      this.loadingData = true;
      Api.get("/v2/client/financeiro/pix/info")
        .then((response) => {
          this.ModuloInfo = response.data.body.moduloInfo;
          this.loadingData = false;
        })
        .catch((error: any) => {
          const response = error.response as AxiosResponse;
          const status = response.status;
          const data = response.data;

          if (status != 200) {
            //exe
          }
          // this.resetRecaptcha();
        })
        .finally(() => {
          Overlay.hide();
        });
    },
    cancelaTransferenciaPix() {
      this.formTransferPix.transactionPin = "";
      this.dialogValorTransferPix = false;
      this.dialogRevisaoTransferPix = false;
    },
    showRevisao() {
      if (
        this.formTransferPix.valor === "" ||
        this.formTransferPix.valor === "R$ 0,00" ||
        this.formTransferPix.valor === "0,00" ||
        this.formTransferPix.valor === "0.00"
      ) {
        Swal.fire({
          title: "Opps",
          text: "Informe o valor",
          icon: "error",
        });
        this.$nextTick(() => {
          const wrapper = this.$refs.valorTransferencia as any;

          window.setTimeout(() => wrapper?.focus(), 0);
        });
      } else {
        this.dialogValorTransferPix = false;
        this.dialogRevisaoTransferPix = true;
        this.$nextTick(() => {
          const wrapper = this.$refs.pincode as any;

          window.setTimeout(() => wrapper?.focus(), 0);
        });
      }
    },
    transferePix() {
      this.btnloading = true;
      if (
        this.formTransferPix.transactionPin === "" ||
        this.formTransferPix.valor.length < 6
      ) {
        Swal.fire({
          title: "Opps",
          text: "Informe seu PIN de transações",
          icon: "error",
        });
        this.$nextTick(() => {
          const wrapper = this.$refs.pincode as any;
          this.btnloading = false;
          window.setTimeout(() => wrapper?.focus(), 0);
        });
      } else {
        Overlay.show();
        this.btnloading = true;
        this.dialogRevisaoTransferPix = false;
        this.dialogProcessandoPix = true;
        Api.post("/v2/client/financeiro/pix/transfer", this.formTransferPix)
          .then((response) => {
            this.dialogRevisaoTransferPix = false;
            this.formTransferPix.valor = "";
            this.idPixChecking = response.data.body.transactionId;
            this.dadosComprovantePixEnviado = response.data.body;
            this.idPixChecking = response.data.body.transactionId;
            //console.log(this.idPixChecking);
            if (response.data.body.awaitComprovante) {
              this.ckStatusPagto = true;
              this.queryAndIndeterminate();
            } else {
              this.dialogProcessandoPix = false;
              this.dialogPixEmProcessamento = true;
              this.ckStatusPagto = false;
            }
          })
          .catch((error: any) => {
            const response = error.response as AxiosResponse;
            const status = response.status;
            const data = response.data;

            if (status != 200) {
              this.dialogRevisaoTransferPix = true;
              this.dialogProcessandoPix = false;
              Swal.fire({
                title: "Opps",
                text: data.body.error,
                icon: "error",
              });
            }
            // this.resetRecaptcha();
          })
          .finally(() => {
            Overlay.hide();
            this.btnloading = false;
          });
      }
    },

    Comprovante(id: string) {
      Api.get("/v2/public/comprovantes/pix/sent/" + id)
        .then((response) => {
          this.dadosComprovantePixEnviado = response.data.body;
          this.dialogComprovantePixEnviado = true;
        })
        .catch((error: any) => {
          const response = error.response as AxiosResponse;
          const status = response.status;
          const data = response.data;
          Snackbar.show(data.body.error, "error");
        })
        .finally(() => {
          Overlay.hide();
        });
    },
    checkStatusPagamento() {
      this.qtdCheckStatus++;
      if (this.qtdCheckStatus == 20) {
        Swal.fire({
          title: "Opps",
          text: "Essa operação está demorando mais que o esperado. Confirme em seu extrato daqui alguns minutos se ela foi processada.",
          icon: "warning",
        });
        this.ckStatusPagto = false;
        this.$router.push("/pix/menuPix");
      }
      this.dialogProcessandoPix = true;
      Api.get(
        "/v2/client/financeiro/pix/transfer/" + this.idPixChecking + "/status"
      )
        .then((response) => {
          // compensado
          if (response.status == 200) {
            this.ckStatusPagto = false;
            this.dialogProcessandoPix = false;
            this.formTransferPix.dadosChave = null;
            this.formTransferPix.valor = "0,00";
            this.Comprovante(this.idPixChecking);
            this.cancelaTransferenciaPix();
            this.resetModulo();
          }

          //pendente
          if (response.status == 201) {
            this.ckStatusPagto = true;
            // this.queryAndIndeterminate();
          }

          //erro
          if (response.status == 202) {
            this.ckStatusPagto = false;
            //  this.queryAndIndeterminate();
            Swal.fire({
              title: "Opps",
              text: response.data.body.error,
              icon: "error",
            });
          }

          //this.moduloInfo();
        })
        .catch((error: any) => {
          const response = error.response as AxiosResponse;
          const status = response.status;
          const data = response.data;
        })
        .finally(() => {
          this.btnloading = false;

          Overlay.hide();
        });
    },
    queryAndIndeterminate() {
      if (this.ckStatusPagto && this.qtdCheckStatus < 20) {
        setTimeout(() => {
          this.progressPixCkinterval = setInterval(() => {
            if (this.progressTempoCkPix === 100) {
              if (this.ckStatusPagto) {
                this.checkStatusPagamento();
              }
              clearInterval(this.progressPixCkinterval);

              return setTimeout(this.queryAndIndeterminate, 100);
            }
            this.progressTempoCkPix += 10;
          }, 1000);
        }, 1000);
      }
    },
    getSituacaoConta() {
      Overlay.show();
      Api.get("/v2/client/dashboard/statusConta")
        .then((response) => {
          this.situacaoConta = response.data.body;
          this.hasInfoSituacaoConta = true;
          this.loadedData = true;
          this.moduloInfo();
        })
        .catch((error: any) => {
          const response = error.response as AxiosResponse;
          const status = response.status;
          const data = response.data;
        })
        .finally(() => {
          //
        });
    },
  },
  watch: {
  '$i18n.locale'(newLocale: string) {
    this.updateBreadcrumbs();
  }
},
  directives: { money: VMoney },
});
