
import Vue from "vue";
import Api from "@/services/Api";
import { AxiosResponse } from "axios";
import Auth from "@/lib/Auth";
import { Overlay } from "@/lib/Overlay";
import moment from "moment";
import { Snackbar } from "@/lib/Snackbar";
import Swal from "sweetalert2";
export default Vue.extend({
  name: "AlterarSenhaPIN",

  data() {
    return {
      pinCode: "",
      showpass: false,
      btnLoading: false,
      isMobile: false,
      currentPin: "",
      newPin: "",
      newPinConfirm: "",
      dadosUsuario: { email: "", email_mask: "" },
      dialogAlteraPIN: false,
      dialogAlteraSenha: false,
      lang: navigator.language,
      errors: [],
      moment: moment,
    };
  },
  created() {
    this.isMobile = this.detectMobile();
    this.dadosUsuario = Auth.getUserData();
  },
  methods: {
    detectMobile() {
      if (screen.width <= 760) {
        return true;
      } else {
        return false;
      }
    },

    requestUpdatePassword() {
      if (this.pinCode.length != 6) {
        Snackbar.show("informe seu PIN de transações", "error");
        return false;
      }
      this.dialogAlteraSenha = false;
      Overlay.show();
      Api.post("/v2/client/profile/security/forgotPassword", {
        transactionPin: this.pinCode,
      })
        .then((response) => {
          Swal.fire({
            text: response.data.body.message,
            icon: "success",
            confirmButtonText: "Ok",
            showConfirmButton: true,
            customClass: {
              confirmButton: "d-block",
            },
          });
        })
        .catch((error: any) => {
          const response = error.response as AxiosResponse;
          const status = response.status;
          const data = response.data;
          this.dialogAlteraSenha = true;
          Swal.fire({
            text: data.body.error,
            icon: "error",
            confirmButtonText: "Ok",
            showConfirmButton: true,
            customClass: {
              confirmButton: "d-block",
            },
          });
        })
        .finally(() => {
          this.btnLoading = false;

          Overlay.hide();
        });
    },
    cancelRequestUpdatePassword() {
      this.pinCode = "";
      this.dialogAlteraSenha = false;
    },
    cancelChangePin() {
      this.newPin = "";
      this.currentPin = "";
      this.newPinConfirm = "";
      this.dialogAlteraPIN = false;
    },
    forgotPIN() {
      Swal.fire({
        icon: "question",
        title: "Confirmação",
        text:
          "Será enviado um e-mail para " +
          this.dadosUsuario.email_mask +
          " para redefinição do PIN de transações",
        showCancelButton: true,
        confirmButtonText: "Confirmar",
        cancelButtonText: "Cancelar",
      }).then((result) => {
        if (result.isConfirmed) {
          Overlay.show();
          this.dialogAlteraPIN = false;

          Api.post("/v2/client/profile/security/forgotPIN/request", {
            transactionPin: this.pinCode,
          })
            .then((response) => {
              Swal.fire({
                text: response.data.body.message,
                icon: "success",
                confirmButtonText: "Ok",
                showConfirmButton: true,
                customClass: {
                  confirmButton: "d-block",
                },
              });
            })
            .catch((error: any) => {
              const response = error.response as AxiosResponse;
              const status = response.status;
              const data = response.data;
              this.dialogAlteraSenha = true;
              Swal.fire({
                text: data.body.error,
                icon: "error",
                confirmButtonText: "Ok",
                showConfirmButton: true,
                customClass: {
                  confirmButton: "d-block",
                },
              });
            })
            .finally(() => {
              this.btnLoading = false;

              Overlay.hide();
            });
        }
      });
    },
    changePin() {
      Overlay.show();
      Api.post("/v2/client/profile/security/changePIN", {
        currentPin: this.currentPin,
        newPin: this.newPin,
        newPinConfirm: this.newPinConfirm,
      })
        .then((response) => {
          Swal.fire({
            text: response.data.body.message,
            icon: "success",
            confirmButtonText: "Ok",
            showConfirmButton: true,
            customClass: {
              confirmButton: "d-block",
            },
          });
          this.cancelChangePin();
        })
        .catch((error: any) => {
          const response = error.response as AxiosResponse;
          const status = response.status;
          const data = response.data;

          if (status === 422) {
            this.errors = data.body.errors;
          } else {
            Swal.fire({
              text: data.body.error,
              icon: "error",
              confirmButtonText: "Ok",
              showConfirmButton: true,
              customClass: {
                confirmButton: "d-block",
              },
            });
          }
        })
        .finally(() => {
          Overlay.hide();
        });
    },
  },
});
