
import Vue from "vue";
import Api from "@/services/Api";
import { AxiosResponse } from "axios";
import { Overlay } from "@/lib/Overlay";
import moment from "moment";
import { Snackbar } from "@/lib/Snackbar";
import { TableOptions } from "@/models/TableOptions";

export interface RelatorioPix {
  data?: string;
  transactionId?: string;
  valorOriginal?: string;
  valorOriginalFormatado?: string;
  valorPago?: string;
  valorPagoFormatado?: string;
  status?: string;
  dataPagamento?: number;
  NomePagador?: string;
}

export default Vue.extend({
  name: "RelatorioPixEnviados",

  data() {
    return {
      btnLoadingResent: false,
      dialogWebhooks: false,
      dialogDevolucoes: false,
      dialogColaborador: false,
      hasLoadedData: false,
      dialogComprovantePixEnviado: false,
      dadosComprovantePixEnviado: [] as any,
      menuInitialDate: false,
      menuFinalDate: false,
      menuInitialDateMobile: false,
      ModuloInfo: [],
      loadingData: false,
      search: "",
      loadingDesserts: false,
      lang: navigator.language,
      stats: [],
      errors: [] as any,
      moment: moment,
      isMobile: false,
      page: 1,
      pageCount: 0,
      itemsPerPage: 20,
      options: {} as TableOptions,
      selectedEntryStatusPix: { id: "TODOS", descricao: "Todos" },
      selectedEntryOrigemPix: { id: "TODAS", descricao: "Todas" },
      queryField: [
        { id: "transactionE2E", descricao: "Id Pix (E2E)" },
        { id: "transactionId", descricao: "ID" },
        { id: "externalId", descricao: "ID Externo" },
        { id: "payeeDocument", descricao: "Documento Recebedor" },
        { id: "payeeName", descricao: "Nome do Recebedor" },
        { id: "pixKey", descricao: "Chave Pix" }
      ],
      selectedEntryQueryField: { id: "transactionE2E", descricao: "Id Pix (E2E)" },
      translatedQueryField: [] as  { id: string; descricao: string; }[],
      statusPix: [
        { id: "TODOS", descricao: this.$t('components.RelatorioPixEnviados.template.statusPix.All') },
        { id: "CONFIRMADA", descricao: this.$t('components.RelatorioPixEnviados.template.statusPix.Confirmed') },
        { id: "PENDENTE", descricao: this.$t('components.RelatorioPixEnviados.template.statusPix.InProcessing') },
        { id: "PEND_CONFIRM", descricao: this.$t('components.RelatorioPixEnviados.template.statusPix.PendingConfirmation') },
        { id: "CANCELED", descricao: this.$t('components.RelatorioPixEnviados.template.statusPix.Canceled') },
        { id: "ERRO", descricao: this.$t('components.RelatorioPixEnviados.template.statusPix.WithErrors') },
        { id: "DEVOLVIDA", descricao: this.$t('components.RelatorioPixEnviados.template.statusPix.Returned') },
      ],
      origemPix: [
        { id: "TODAS", descricao: this.$t('components.RelatorioPixEnviados.template.origemPix.All') },
        { id: "PIX_API", descricao: this.$t('components.RelatorioPixEnviados.template.origemPix.ModAPI') },
        { id: "TRANSFER", descricao: this.$t('components.RelatorioPixEnviados.template.origemPix.ModPayments') },
        { id: "QRCODE", descricao: this.$t('components.RelatorioPixEnviados.template.origemPix.ModQRCode') },
      ],
      headers: [
        { text: "components.RelatorioPixEnviados.template.DataTableHeaders.Empty", value: "status", sortable: false },
        { text: "components.RelatorioPixEnviados.template.DataTableHeaders.Date", value: "data", sortable: false },
        { text: "components.RelatorioPixEnviados.template.DataTableHeaders.IDExternalID", value: "transactionId", sortable: false },
        { text: "components.RelatorioPixEnviados.template.DataTableHeaders.FavoredKey", value: "nomeDestino", sortable: false },
        { text: "components.RelatorioPixEnviados.template.DataTableHeaders.Description", value: "descricao", sortable: false },
        { text: "components.RelatorioPixEnviados.template.DataTableHeaders.Value", value: "valorOriginalFormatado", sortable: false, align: "right" },
        { text: "components.RelatorioPixEnviados.template.DataTableHeaders.ValueEmpty", value: "valor", sortable: false, align: "right" },
      ],
      /*
      statusPix: [
        { id: "TODOS", descricao: "Todos" },
        { id: "CONFIRMADA", descricao: "Confirmados" },
        { id: "PENDENTE", descricao: "Em processamento" },
        { id: "PEND_CONFIRM", descricao: "Pendente confirmação" },
        { id: "CANCELED", descricao: "Cancelados" },
        { id: "ERRO", descricao: "Com erros" },
        { id: "DEVOLVIDA", descricao: "Devolvidas" },
      ],
      origemPix: [
        { id: "TODAS", descricao: "Todas" },
        { id: "PIX_API", descricao: "Mod API" },
        { id: "TRANSFER", descricao: "Mod Pagamentos" },
        { id: "QRCODE", descricao: "Mod QRCode" },
      ],
      
      headers: [
        { text: "", value: "status", sortable: false },
        { text: "Data", value: "data", sortable: false },
        {
          text: "ID / External Id",
          value: "transactionId",
          sortable: false,
        },
        { text: "Favorecido / Chave", value: "nomeDestino", sortable: false },
        { text: "Descrição", value: "descricao", sortable: false },
        {
          text: "Valor",
          value: "valorOriginalFormatado",
          sortable: false,
          align: "right",
        },
        {
          text: "",
          value: "valor",
          sortable: false,
          align: "right",
        },
      ],
      */
      desserts: new Array<RelatorioPix>(),

      totalDesserts: 0,
      timeout: 0,
      startPeriod: moment().add(-1, "hours").format("YYYY-MM-DDTHH:mm"),
      endPeriod: moment().format("YYYY-MM-DDT23:59"),
      startDate: moment().format("YYYY-MM-DD"),
      endDate: moment().format("YYYY-MM-DD"),
      defaultStartDate: moment().format("YYYY-MM-DD"),
      defaultEndDate: moment().format("YYYY-MM-DD"),
      maxDate: moment().format("YYYY-MM-DD"),
      btnDownloadDisabled: true,
      btnDownloadLoading: false,
      toExport: false,
      colaboradorData: {
        id: "7",
        createdAt: "2022-08-17 10:05:43",
        nome: "João Ferreira",
        cpf: "80548105391",
        telefone: "(66)9.9715-5421",
        email: "joao_f_filho@yahoo.com.br",
        status: "1",
        updatedAt: "2024-02-06 15:46:49",
      },
      devolucoes: [] as any,
      webhooks: {
        webhook: {
          id: "27033002",
          createdAt: "2024-03-07 11:52:07",
          type: "COBPIX",
          createdAt_ano: "2024",
          createdAt_mes: "3",
          createdAt_date: "2024-03-07",
          webhook_project_id: "22",
          clientId: "1",
          transactionId: "63510ABD51FB1B7F",
          url: "https://financeiro.invizza.com/tests/webook.php",
          sendStatus: "2",
          sendCount: "3",
        },
        attemps: {
          count: 3,
          rows: [
            {
              id: "20737407",
              webhook: "27033002",
              sendDate: "2024-03-07 11:54:14",
              sendDate_ano: "2024",
              sendDate_mes: "3",
              sendDate_date: "2024-03-07",
              sendBody:
                '{"requestBody":{"transactionType":"RECEIVEPIX","transactionId":"63510ABD51FB1B7F","external_id":"92271b39-494d-4def-98c1-648386e38f1c","amount":1,"dateApproval":"2024-03-07 11:54:14","payerName":"JOAO FERREIRA FILHO","creditParty":{"userId":null,"email":"edilson@invizza.com","taxId":"11935552000108","name":"EDILSON MOURA DA SILVA"},"debitParty":{"account":"BCO BRADESCO S.A.","bank":"BCO BRADESCO S.A.","branch":"953","taxId":"80548105391","name":"JOAO FERREIRA FILHO"},"authentication":"E60746948202403071454A9530hA97kc"}}',
              sendHeader:
                '["Verify-Signature: t=1709823254,vsign=3cc021fce5ed983f611e8dc6e9ae7c429e07555dcc4ac580aadf53e44751c844","Content-Type: application\\/json","Authorization: Basic aW52aXp6YTppbnZpenphQDEyMzQ1Ng=="]',
              responseHttpStatus: "200",
              responseBody: null,
              executionTime: "0.606188",
            },
            {
              id: "21510690",
              webhook: "27033002",
              sendDate: "2024-03-15 15:38:42",
              sendDate_ano: "2024",
              sendDate_mes: "3",
              sendDate_date: "2024-03-15",
              sendBody:
                '{"requestBody":{"transactionType":"RECEIVEPIX","transactionId":"63510ABD51FB1B7F","external_id":"92271b39-494d-4def-98c1-648386e38f1c","amount":1,"dateApproval":"2024-03-07 11:54:14","payerName":"JOAO FERREIRA FILHO","creditParty":{"userId":null,"email":"edilson@invizza.com","taxId":"11935552000108","name":"EDILSON MOURA DA SILVA"},"debitParty":{"account":"BCO BRADESCO S.A.","bank":"BCO BRADESCO S.A.","branch":"953","taxId":"80548105391","name":"JOAO FERREIRA FILHO"},"authentication":"E60746948202403071454A9530hA97kc"}}',
              sendHeader:
                '["Verify-Signature: t=1710527922,vsign=9d1da194e1662931aa550616d7cdef5d0e5e5fc7ed23a1f8099734ff42bfaa80","Content-Type: application\\/json","Authorization: Basic aW52aXp6YTppbnZpenphQDEyMzQ1Ng=="]',
              responseHttpStatus: "200",
              responseBody: null,
              executionTime: "0.707154",
            },
            {
              id: "21510702",
              webhook: "27033002",
              sendDate: "2024-03-15 15:38:56",
              sendDate_ano: "2024",
              sendDate_mes: "3",
              sendDate_date: "2024-03-15",
              sendBody:
                '{"requestBody":{"transactionType":"RECEIVEPIX","transactionId":"63510ABD51FB1B7F","external_id":"92271b39-494d-4def-98c1-648386e38f1c","amount":1,"dateApproval":"2024-03-07 11:54:14","payerName":"JOAO FERREIRA FILHO","creditParty":{"userId":null,"email":"edilson@invizza.com","taxId":"11935552000108","name":"EDILSON MOURA DA SILVA"},"debitParty":{"account":"BCO BRADESCO S.A.","bank":"BCO BRADESCO S.A.","branch":"953","taxId":"80548105391","name":"JOAO FERREIRA FILHO"},"authentication":"E60746948202403071454A9530hA97kc"}}',
              sendHeader:
                '["Verify-Signature: t=1710527936,vsign=820376a6e6acfa63247c03fdd17d1acf42a90848e4ac7965d0052d6227bf6597","Content-Type: application\\/json","Authorization: Basic aW52aXp6YTppbnZpenphQDEyMzQ1Ng=="]',
              responseHttpStatus: "200",
              responseBody: null,
              executionTime: "0.550223",
            },
          ],
        },
      },
    };
  },
  created() {
    this.isMobile = this.detectMobile();
    this.translateStatusPix();
    this.translateOrigemPix();
    this.translateQueryField();
    
  },

  methods: {
    next(page: number) {
      this.page = page;
      this.loadDatas();
    },
    getDevolucoes(transactionId: string) {
      Overlay.show();
      Api.get(
        "/v2/client/financeiro/pix/relatorios/enviados/" +
          transactionId +
          "/devolucoes"
      )
        .then((response) => {
          this.devolucoes = response.data.body.rows;
          this.dialogDevolucoes = true;
        })
        .catch((error: any) => {
          const response = error.response as AxiosResponse;
          const status = response.status;
          const data = response.data;

          if (status === 422) {
            Snackbar.show(data.body.error, "error");
          }
          // this.resetRecaptcha();
        })
        .finally(() => {
          Overlay.hide();
          this.btnDownloadLoading = false;
        });
    },
    getWebhooksHistory(transactionId: string) {
      Overlay.show();
      Api.get("/v2/client/webhooks/" + transactionId + "/view")
        .then((response) => {
          this.webhooks = response.data.body;
          this.dialogWebhooks = true;
        })
        .catch((error: any) => {
          const response = error.response as AxiosResponse;
          const status = response.status;
          const data = response.data;

          if (status === 404) {
            Snackbar.show(data.body.error, "error");
          }
          // this.resetRecaptcha();
        })
        .finally(() => {
          Overlay.hide();
        });
    },
    resendWebhook(transactionId: string) {
      this.btnLoadingResent = true;
      Overlay.show();
      Api.get("/v2/client/webhooks/" + transactionId + "/send")
        .then((response) => {
          this.getWebhooksHistory(transactionId);
          Snackbar.show("Webhook reenviado", "success");
        })
        .catch((error: any) => {
          const response = error.response as AxiosResponse;
          const status = response.status;
          const data = response.data;

          if (status === 404) {
            Snackbar.show(data.body.error, "error");
          }
          // this.resetRecaptcha();
        })
        .finally(() => {
          this.btnLoadingResent = false;
          Overlay.hide();
        });
    },
    downloadReport() {
      this.btnDownloadLoading = true;
      Overlay.show();

      Api.get("/v2/client/financeiro/pix/relatorios/enviados", {
        params: {
          startDate: this.startPeriod,
          endDate: this.endPeriod,
          query: this.search,
          status: this.selectedEntryStatusPix.id,
          origem: this.selectedEntryOrigemPix.id,
          export: true,
          queryField:this.selectedEntryQueryField.id
        },
        responseType: "arraybuffer",
      })
        .then((response) => {
          let blob = new Blob([response.data], {
            type: "application/vnd.ms-excel",
          });
          let link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download =
            "pix_" +
            this.selectedEntryOrigemPix +
            "_" +
            this.selectedEntryStatusPix.id +
            "-" +
            this.startPeriod +
            "-" +
            this.endPeriod +
            ".xlsx";
          link.click();
        })
        .catch((error: any) => {
          const response = error.response as AxiosResponse;
          const status = response.status;
          const data = response.data;

          if (status === 422) {
            this.errors = data.body.errors;
          }
          // this.resetRecaptcha();
        })
        .finally(() => {
          Overlay.hide();
          this.btnDownloadLoading = false;
        });
    },
    doDelayedSearch() {
      this.page = 1;
      if (this.timeout) {
        clearTimeout(this.timeout);
      }
      this.timeout = setTimeout(() => {
        //this is your existing function
        this.loadDatas();
      }, 1000);
    },
    detectMobile() {
      if (screen.width <= 760) {
        return true;
      } else {
        return false;
      }
    },
    loadDatas() {
      const intervalo = Math.round(
        (new Date(this.endPeriod).getTime() -
          new Date(this.startPeriod).getTime()) /
          (1000 * 3600 * 24)
      );

      if (intervalo > 31) {
        Snackbar.show(
          "O intervalo de pesquisa não pode ser superior a 31 dias",
          "error"
        );
        return false;
      }
      this.hasLoadedData = true;
      Overlay.show();

      this.loadingData = true;
      const { sortBy, sortDesc, page, itemsPerPage } = this.options;
      this.loadingDesserts = true;
      Api.get("/v2/client/financeiro/pix/relatorios/enviados", {
        params: {
          startDate: this.startPeriod,
          endDate: this.endPeriod,
          query: this.search,
          status: this.selectedEntryStatusPix.id,
          page: this.page,
          origem: this.selectedEntryOrigemPix.id,
          queryField:this.selectedEntryQueryField.id
        },
      })
        .then((response) => {
          this.desserts = response.data.body.rows as RelatorioPix[];
          this.totalDesserts = Number(response.data.body.count.__total);
          this.stats = response.data.body.stats;
          this.loadingDesserts = false;
          this.loadingData = false;
          if (this.totalDesserts == 0) {
            this.btnDownloadDisabled = true;
          } else {
            this.btnDownloadDisabled = false;
          }
        })
        .catch((error: any) => {
          const response = error.response as AxiosResponse;
          const status = response.status;
          const data = response.data;

          if (status != 200) {
            //exe
          }
          // this.resetRecaptcha();
        })
        .finally(() => {
          Overlay.hide();
        });
    },
    Comprovante(id: string) {
      Api.get("/v2/public/comprovantes/pix/sent/" + id)
        .then((response) => {
          this.dadosComprovantePixEnviado = response.data.body;
          this.dialogComprovantePixEnviado = true;
        })
        .catch((error: any) => {
          const response = error.response as AxiosResponse;
          const status = response.status;
          const data = response.data;
          Snackbar.show(data.body.error, "error");
        })
        .finally(() => {
          Overlay.hide();
        });
    },
    rowClasses(item: any) {
      const ToDate = new Date();
      if (item.status === "PENDENTE") {
        return "";
      }

      if (item.status === "CONFIRMADA") {
        if (item.devolvida) {
          return "orange--text text--lighten-3";
        }
        return "success--text";
      }

      if (item.status === "PEND_CONFIRM") {
        return "warning--text";
      }
      if (item.status === "DEVOLVIDA") {
        return "error--text";
      }
      if (item.status === "ERRO") {
        return "error--text";
      }
    },
    abrirColaborador(colaborador: string) {
      Overlay.show();
      Api.get("/v2/client/colaboradores/show/" + colaborador)
        .then((response) => {
          this.colaboradorData = response.data.body;
          this.$nextTick(() => {
            this.dialogColaborador = true;
          });
        })
        .catch((error: any) => {
          const response = error.response as AxiosResponse;
          const status = response.status;
          const data = response.data;

          if (status === 422) {
            this.errors = data.body.errors;
          }
          // this.resetRecaptcha();
        })
        .finally(() => {
          Overlay.hide();
        });
    },
    translateStatusPix() {
      this.statusPix = [
        { id:"TODOS", descricao: this.$t('components.RelatorioPixEnviados.template.statusPix.All') },
        { id:"CONFIRMADA", descricao: this.$t('components.RelatorioPixEnviados.template.statusPix.Confirmed') },
        { id:"PENDENTE", descricao: this.$t('components.RelatorioPixEnviados.template.statusPix.InProcessing') },
        { id:"PEND_CONFIRM", descricao: this.$t('components.RelatorioPixEnviados.template.statusPix.PendingConfirmation') },
        { id:"CANCELED", descricao: this.$t('components.RelatorioPixEnviados.template.statusPix.Canceled') },
        { id:"ERRO", descricao: this.$t('components.RelatorioPixEnviados.template.statusPix.WithErrors') },
        { id:"DEVOLVIDA", descricao: this.$t('components.RelatorioPixEnviados.template.statusPix.Returned') },
      ];
    },
    translateQueryField() {
      this.translatedQueryField = this.queryField.map(status => {
        const translationKey = `components.RelatorioPixEnviados.template.queryField.${status.id}`;
      
        return {
          id: status.id,
          descricao: this.$t(translationKey) as string
        };
      });
    },
    translateOrigemPix() {
      this.origemPix = [
        { id:"TODAS", descricao: this.$t('components.RelatorioPixEnviados.template.origemPix.All') },
        { id:"PIX_API", descricao: this.$t('components.RelatorioPixEnviados.template.origemPix.ModAPI') },
        { id:"TRANSFER", descricao: this.$t('components.RelatorioPixEnviados.template.origemPix.ModPayments') },
        { id:"QRCODE", descricao: this.$t('components.RelatorioPixEnviados.template.origemPix.ModQRCode') },
      ];
    },
   
  },
  watch: {
    '$i18n.locale'() {
      this.translateStatusPix();
      this.translateOrigemPix();
      this.translateQueryField();
      
    },
  },
  computed: {
    translatedHeaders() {
      let vm = this as any;
      return vm.headers.map((header: { text: string; }) => ({
        ...header,
        text: String(vm.$t(header.text)),
      }));
    },
  },
});
