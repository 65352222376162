<template>
  <v-row>
    <v-col md="6" cols="12" class="mx-auto" v-if="!loadingData">
      <v-alert class="text-center" v-if="dadosDocs.falta_dados_basicos">
        <v-img
          src="@/assets/images/ballon.svg"
          width="200"
          class="mx-auto"
        ></v-img>
        
        <h3>{{ $t("components.preCadastro.template.form.Beforedetails") }}</h3>

        <v-btn link to="/perfil" color="purple darken-3" class="mt-5" outlined>
          <v-icon left>mdi mdi-chevron-left</v-icon> {{ $t("components.preCadastro.template.form.Review") }}</v-btn
        >
      </v-alert>

      <div v-else>
        <v-toolbar flat color="transparent">
          <v-toolbar-title>
            <router-link to="/perfil"
              ><v-icon left>mdi-arrow-left</v-icon></router-link
            > {{ $t("components.preCadastro.template.form.YourDocuments") }}</v-toolbar-title
          >
        </v-toolbar>
        <v-card class="mb-4">
          <v-list>
            <v-list-item
              @click="
                openDialogDocFrente(
                  dadosDocs.documentos_status.doc_imagem_frente.status
                )
              "
            >
              <v-list-item-avatar rounded size="48">
                <v-img src="../../assets/images/documento-frente.png"></v-img>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-row>
                  <v-col> <h4> {{ $t("components.preCadastro.template.form.DocumentFront") }}</h4></v-col>
                  <v-col class="text-right"
                    ><span>
                      <v-chip
                        v-if="
                          dadosDocs.documentos_status.doc_imagem_frente
                            .status === 'Pendente'
                        "
                        color="warning"
                        small
                        ><v-icon left>mdi-alert</v-icon>  {{ $t("components.preCadastro.template.form.PENDING") }}</v-chip
                      >
                      <v-chip
                        v-if="
                          dadosDocs.documentos_status.doc_imagem_frente
                            .status === 'Aprovado'
                        "
                        color="success"
                        small
                        ><v-icon left>mdi-check-circle</v-icon>  {{ $t("components.preCadastro.template.form.APROVED") }}</v-chip
                      >
                      <v-chip
                        v-if="
                          dadosDocs.documentos_status.doc_imagem_frente
                            .status === 'Recusado'
                        "
                        color="error"
                        small
                        ><v-icon left>mdi-check-circle</v-icon>  {{ $t("components.preCadastro.template.form.REJECTED") }}</v-chip
                      >
                      <v-chip
                        v-if="
                          dadosDocs.documentos_status.doc_imagem_frente
                            .status === 'Enviado'
                        "
                        color="info"
                        small
                        ><v-icon left>mdi-clock-time-three-outline</v-icon> {{ $t("components.preCadastro.template.form.Underreview") }}</v-chip
                      >
                    </span></v-col
                  >
                </v-row>
              </v-list-item-content>
              <v-list-item-action
                v-if="
                  dadosDocs.documentos_status.doc_imagem_frente.status ===
                    'Recusado' ||
                  dadosDocs.documentos_status.doc_imagem_frente.status ===
                    'Pendente'
                "
              >
                <v-icon> mdi-chevron-right </v-icon>
              </v-list-item-action>
            </v-list-item>
          </v-list>
          <v-col
            cols="12"
            v-if="
              dadosDocs.documentos_status.doc_imagem_frente.status ===
              'Recusado'
            "
            ><v-alert outlined dense type="error"
              ><small
                v-html="dadosDocs.documentos_status.doc_imagem_frente.reacao"
              ></small></v-alert
          ></v-col>
        </v-card>
        <v-card class="mb-4">
          <v-list>
            <v-list-item
              @click="
                openDialogDocVerso(
                  dadosDocs.documentos_status.doc_imagem_verso.status
                )
              "
            >
              <v-list-item-avatar rounded size="48">
                <v-img src="../../assets/images/documento-verso.png"></v-img>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-row>
                  <v-col> <h4> {{ $t("components.preCadastro.template.form.DocumentBack") }}</h4></v-col>
                  <v-col class="text-right"
                    ><span>
                      <v-chip
                        v-if="
                          dadosDocs.documentos_status.doc_imagem_verso
                            .status === 'Pendente'
                        "
                        color="warning"
                        small
                        ><v-icon left>mdi-alert</v-icon>  {{ $t("components.preCadastro.template.form.PENDING") }}</v-chip
                      >
                      <v-chip
                        v-if="
                          dadosDocs.documentos_status.doc_imagem_verso
                            .status === 'Aprovado'
                        "
                        color="success"
                        small
                        ><v-icon left>mdi-check-circle</v-icon>  {{ $t("components.preCadastro.template.form.APROVED") }}</v-chip
                      >
                      <v-chip
                        v-if="
                          dadosDocs.documentos_status.doc_imagem_verso
                            .status === 'Recusado'
                        "
                        color="error"
                        small
                        ><v-icon left>mdi-check-circle</v-icon>  {{ $t("components.preCadastro.template.form.REJECTED") }}</v-chip
                      >
                      <v-chip
                        v-if="
                          dadosDocs.documentos_status.doc_imagem_verso
                            .status === 'Enviado'
                        "
                        color="info"
                        small
                        ><v-icon left>mdi-clock-time-three-outline</v-icon>  {{ $t("components.preCadastro.template.form.Underreview") }}</v-chip
                      >
                    </span></v-col
                  >
                </v-row>
              </v-list-item-content>
              <v-list-item-action
                v-if="
                  dadosDocs.documentos_status.doc_imagem_verso.status ===
                    'Recusado' ||
                  dadosDocs.documentos_status.doc_imagem_verso.status ===
                    'Pendente'
                "
              >
                <v-icon> mdi-chevron-right </v-icon>
              </v-list-item-action>
            </v-list-item>
          </v-list>
          <v-col
            cols="12"
            v-if="
              dadosDocs.documentos_status.doc_imagem_verso.status === 'Recusado'
            "
            ><v-alert outlined dense type="error"
              ><small
                v-html="dadosDocs.documentos_status.doc_imagem_verso.reacao"
              ></small></v-alert
          ></v-col>
        </v-card>

        <v-card class="mb-4">
          <v-list>
            <v-list-item
              @click="
                openSelfieInstructions(
                  dadosDocs.documentos_status.doc_selfie.status
                )
              "
            >
              <v-list-item-avatar rounded size="48">
                <v-img src="../../assets/images/documento-selfie.png"></v-img>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-row>
                  <v-col> <h4> {{ $t("components.preCadastro.template.form.SelfiewithDocument") }}</h4></v-col>
                  <v-col class="text-right"
                    ><span>
                      <v-chip
                        v-if="
                          dadosDocs.documentos_status.doc_selfie.status ===
                          'Pendente'
                        "
                        color="warning"
                        small
                        ><v-icon left>mdi-alert</v-icon>  {{ $t("components.preCadastro.template.form.PENDING") }}</v-chip
                      >
                      <v-chip
                        v-if="
                          dadosDocs.documentos_status.doc_selfie.status ===
                          'Aprovado'
                        "
                        color="success"
                        small
                        ><v-icon left>mdi-check-circle</v-icon>  {{ $t("components.preCadastro.template.form.APROVED") }}</v-chip
                      >
                      <v-chip
                        v-if="
                          dadosDocs.documentos_status.doc_selfie.status ===
                          'Recusado'
                        "
                        color="error"
                        small
                        ><v-icon left>mdi-check-circle</v-icon> {{ $t("components.preCadastro.template.form.REJECTED") }}</v-chip
                      >
                      <v-chip
                        v-if="
                          dadosDocs.documentos_status.doc_selfie.status ===
                          'Enviado'
                        "
                        color="info"
                        small
                        ><v-icon left>mdi-clock-time-three-outline</v-icon>{{ $t("components.preCadastro.template.form.Underreview") }}</v-chip
                      >
                    </span></v-col
                  >
                </v-row>
              </v-list-item-content>
              <v-list-item-action
                v-if="
                  dadosDocs.documentos_status.doc_selfie.status ===
                    'Recusado' ||
                  dadosDocs.documentos_status.doc_selfie.status === 'Pendente'
                "
              >
                <v-icon> mdi-chevron-right </v-icon>
              </v-list-item-action>
            </v-list-item>
          </v-list>
          <v-col
            cols="12"
            v-if="dadosDocs.documentos_status.doc_selfie.status === 'Recusado'"
            ><v-alert outlined dense type="error"
              ><small
                v-html="dadosDocs.documentos_status.doc_selfie.reacao"
              ></small></v-alert
          ></v-col>
        </v-card>

        <v-card class="mb-4" v-if="dadosDocs.tipo_pessoa === 'PJ'">
          <v-list>
            <v-list-item
              @click="
                openContratoInstructions(
                  dadosDocs.documentos_status.doc_contrato.status
                )
              "
            >
              <v-list-item-avatar rounded size="48">
                <v-img src="../../assets/images/documento-contrato.png"></v-img>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-row>
                  <v-col> <h4>{{ $t("components.preCadastro.template.form.CorporateBylaws") }}</h4></v-col>
                  <v-col class="text-right"
                    ><span>
                      <v-chip
                        v-if="
                          dadosDocs.documentos_status.doc_contrato.status ===
                          'Pendente'
                        "
                        color="warning"
                        small
                        ><v-icon left>mdi-alert</v-icon> {{ $t("components.preCadastro.template.form.PENDING") }}</v-chip
                      >
                      <v-chip
                        v-if="
                          dadosDocs.documentos_status.doc_contrato.status ===
                          'Aprovado'
                        "
                        color="success"
                        small
                        ><v-icon left>mdi-check-circle</v-icon> {{ $t("components.preCadastro.template.form.APROVED") }}</v-chip
                      >
                      <v-chip
                        v-if="
                          dadosDocs.documentos_status.doc_contrato.status ===
                          'Recusado'
                        "
                        color="error"
                        small
                        ><v-icon left>mdi-check-circle</v-icon> {{ $t("components.preCadastro.template.form.REJECTED") }}</v-chip
                      >
                      <v-chip
                        v-if="
                          dadosDocs.documentos_status.doc_contrato.status ===
                          'Enviado'
                        "
                        color="info"
                        small
                        ><v-icon left>mdi-clock-time-three-outline</v-icon> {{ $t("components.preCadastro.template.form.Underreview") }}</v-chip
                      >
                    </span></v-col
                  >
                </v-row>
              </v-list-item-content>
              <v-list-item-action
                v-if="
                  dadosDocs.documentos_status.doc_selfie.status ===
                    'Recusado' ||
                  dadosDocs.documentos_status.doc_selfie.status === 'Pendente'
                "
              >
                <v-icon> mdi-chevron-right </v-icon>
              </v-list-item-action>
            </v-list-item>
          </v-list>
          <v-col
            cols="12"
            v-if="dadosDocs.documentos_status.doc_selfie.status === 'Recusado'"
            ><v-alert outlined dense type="error"
              ><small
                v-html="dadosDocs.documentos_status.doc_selfie.reacao"
              ></small></v-alert
          ></v-col>
        </v-card>
      </div>
    </v-col>
    <v-dialog
      v-model="dialogSelfie"
      :fullscreen="isMobile"
      :max-width="isMobile ? 0 : 450"
      persistent
    >
      <v-card>
        <v-card-title class="purple darken-3 white--text mb-2">
          {{ $t("components.preCadastro.template.form.Selfie") }}
        </v-card-title>

        <v-card-text class="text-center"
          ><h4>
            {{ $t("components.preCadastro.template.form.Takeaccessory") }}
          </h4></v-card-text
        >
        <v-card-text class="m1-3">
          <div class="cropper-wrapper">
            <div
              :style="{ backgroundImage: 'url(' + imageSelfie.src + ')' }"
              class="image-background"
            ></div>
            <cropper
              ref="cropper"
              class="twitter-cropper"
              background-class="cropper-background"
              image-restriction="stencil"
              :stencil-size="stencilSizeDefault"
              :stencil-props="{
                lines: {},
                handlers: {},
                movable: false,
                scalable: false,
                aspectRatio: 1,
              }"
              :resize-image="{
                adjustStencil: false,
              }"
              :autoZoom="false"
              :transitions="false"
              :debounce="false"
              :default-size="defaultSize"
              :min-width="350"
              :min-height="350"
              :src="imageSelfie.src"
              @change="onChange"
            />
          </div>
          <p class="text-center red--text mb-0">
            {{ $t("components.preCadastro.template.form.Adjustframe") }}
          </p>
          <navigation :zoom="zoom" @change="onZoom" />

          <div class="button-wrapper">
            <v-btn
              color="purple darken-3"
              class="text-none mb-5"
              block
              dark
              @click="$refs.uploader.click()"
            >
              <v-icon left> mdi-cloud-upload-outline </v-icon>
              {{ $t("components.preCadastro.template.form.Selectimage") }}
            </v-btn>
            <input
              ref="uploader"
              class="d-none"
              accept="image/*"
              type="file"
              @change="loadImageSelfie($event)"
            />
          </div>
        </v-card-text>
        <v-divider></v-divider>

        <v-card-actions>
          <v-btn large text @click="cancelaSelfie()"> {{ $t("components.preCadastro.template.form.Cancel") }}</v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="purple darken-3"
            dark
            :disabled="!hasAttachedSelfie"
            @click="crop()"
          >
          {{ $t("components.preCadastro.template.form.Send") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="dialogSelfieInstrucoes"
      :fullscreen="isMobile"
      :max-width="isMobile ? 0 : 450"
    >
      <v-card elevation="0">
        <v-toolbar dark flat color="purple darken-3">
          <v-toolbar-title>{{ $t("components.preCadastro.template.form.Instructions") }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn icon dark @click="dialogSelfieInstrucoes = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-container>
          <v-stepper v-model="e1" elevation="0" flat>
            <v-stepper-items>
              <v-stepper-content step="1">
                <h3 class="success--text">
                  <v-icon color="success">mdi-check-circle-outline</v-icon> {{ $t("components.preCadastro.template.form.Howto") }}
                </h3>
                <v-img
                  src="../../assets/images/instrucoes-como-fazer-selfie.png"
                  class="mb-4 mt-4"
                ></v-img>
                <p class="success--text">
                  <v-icon left color="success">mdi-check</v-icon>{{ $t("components.preCadastro.template.form.Takeenvironment") }}
                </p>
                <p class="success--text">
                  <v-icon left color="success">mdi-check</v-icon>{{ $t("components.preCadastro.template.form.Holdface") }}
                </p>
                <p class="success--text">
                  <v-icon left color="success">mdi-check</v-icon>{{ $t("components.preCadastro.template.form.Removeaccessories") }}
                </p>
                <v-divider></v-divider>
                <v-card-actions class="mt-10">
                  <v-spacer></v-spacer>
                  <v-btn
                    color="purple darken-3"
                    dark
                    @click="e1 = e1 + 1"
                    elevation="0"
                  >
                  {{ $t("components.preCadastro.template.form.Continue") }} <v-icon right>mdi-arrow-right</v-icon>
                  </v-btn>
                </v-card-actions>
              </v-stepper-content>

              <v-stepper-content step="2">
                <h3 class="error--text">
                  <v-icon color="error">mdi-close-circle-outline</v-icon> {{ $t("components.preCadastro.template.form.Howit") }}
                </h3>
                <v-img
                  src="../../assets/images/instrucoes-como-nao-fazer-selfie.png"
                  class="mb-4 mt-4"
                ></v-img>
                <p class="error--text">
                  <v-icon left color="error">mdi-close</v-icon>{{ $t("components.preCadastro.template.form.Dodocument") }}
                </p>
                <p class="error--text">
                  <v-icon left color="error">mdi-close</v-icon>{{ $t("components.preCadastro.template.form.Dowithoutyourdocument") }}
                </p>
                <p class="error--text">
                  <v-icon left color="error">mdi-close</v-icon>{{ $t("components.preCadastro.template.form.Dothepicture") }}
                </p>
                <v-divider></v-divider>
                <v-card-actions class="mt-10">
                  <v-btn color="purple darken-3" text @click="e1 = e1 - 1">
                    <v-icon left>mdi-arrow-left</v-icon>{{ $t("components.preCadastro.template.form.Goback") }}
                  </v-btn>
                  <v-spacer></v-spacer>
                  <v-btn
                    elevation="0"
                    dark
                    color="purple darken-3"
                    @click="openDialogSelfie()"
                  >
                  {{ $t("components.preCadastro.template.form.Start") }} <v-icon right>mdi-arrow-right</v-icon>
                  </v-btn>
                </v-card-actions>
              </v-stepper-content>
            </v-stepper-items>
          </v-stepper>
        </v-container>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="dialogFrenteDoc"
      :fullscreen="isMobile"
      :max-width="isMobile ? 0 : 450"
      persistent
    >
      <v-card>
        <!-- aqui -->
        <v-card-title class="purple darken-3 white--text mb-2">
          {{ $t("components.preCadastro.template.form.DocumentFront") }}
        </v-card-title>

        <v-card-text class="text-center"
          ><h4>
            {{ $t("components.preCadastro.template.form.Takefrontdocumentenvironment") }}
          </h4></v-card-text
        >
        <v-card-text class="m1-3">
          <v-img :src="imageDocFrente.src" max-height="350" contain></v-img>
          <p class="text-center red--text mb-5 mt-5">
            <v-icon left>mdi-information-outline</v-icon>{{ $t("components.preCadastro.template.form.AcceptedIdLicense") }}
          </p>

          <div class="button-wrapper">
            <v-btn
              color="purple darken-3"
              class="text-none mb-5"
              block
              dark
              @click="$refs.uploaderDocFrente.click()"
            >
              <v-icon left> mdi-cloud-upload-outline </v-icon>
              {{ $t("components.preCadastro.template.form.Selectfile") }}
            </v-btn>
            <input
              ref="uploaderDocFrente"
              class="d-none"
              accept="image/*"
              type="file"
              @change="uploadDocFrente"
            />
          </div>
        </v-card-text>
        <v-divider></v-divider>

        <v-card-actions>
          <v-btn large text @click="dialogFrenteDoc = false"> {{ $t("components.preCadastro.template.form.Cancel") }}</v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="purple darken-3"
            dark
            :disabled="!hasDocFrente"
            @click="sendDoc('doc_imagem_frente')"
          >
          {{ $t("components.preCadastro.template.form.Send") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="dialogVersoDoc"
      :fullscreen="isMobile"
      :max-width="isMobile ? 0 : 450"
      persistent
    >
      <v-card>
        <v-card-title class="purple darken-3 white--text mb-2">
          {{ $t("components.preCadastro.template.form.DocumentBack") }}
        </v-card-title>

        <v-card-text class="text-center"
          ><h4>
            {{ $t("components.preCadastro.template.form.TakePhotoBright") }}
          </h4></v-card-text
        >
        <v-card-text class="m1-3">
          <v-img :src="imageDocVerso.src" max-height="350" contain></v-img>
          <p class="text-center red--text mb-5 mt-5">
            <v-icon left>mdi-information-outline</v-icon>
            {{ $t("components.preCadastro.template.form.AcceptedIdLicense") }}
          </p>

          <div class="button-wrapper">
            <v-btn
              color="purple darken-3"
              class="text-none mb-5"
              block
              dark
              @click="$refs.uploaderDocVerso.click()"
            >
              <v-icon left> mdi-cloud-upload-outline </v-icon>
              {{ $t("components.preCadastro.template.form.Selectfile") }}
            </v-btn>
            <input
              ref="uploaderDocVerso"
              class="d-none"
              accept="image/*"
              type="file"
              @change="uploadDocVerso"
            />
          </div>
        </v-card-text>
        <v-divider></v-divider>

        <v-card-actions>
          <v-btn large text @click="dialogVersoDoc = false"> {{ $t("components.preCadastro.template.form.Cancel") }}</v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="purple darken-3"
            dark
            :disabled="!hasDocVerso"
            @click="sendDoc('doc_imagem_verso')"
          >
          {{ $t("components.preCadastro.template.form.Send") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="dialogContrato"
      :fullscreen="isMobile"
      :max-width="isMobile ? 0 : 450"
      persistent
    >
      <v-card>
        <v-card-title class="purple darken-3 white--text mb-2">
          {{ $t("components.preCadastro.template.form.CorporateBylaws") }}
        </v-card-title>

        <v-card-text class="m1-3">
          <p class="text-center red--text mb-5 mt-5">
            <v-icon left>mdi-information-outline</v-icon>
            <strong>  {{ $t("components.preCadastro.template.form.CorporateBylawsProof") }}</strong>
          </p>

          <div class="button-wrapper">
            <v-btn
              color="purple darken-3"
              class="text-none mb-5"
              block
              dark
              @click="$refs.uploaderContrato.click()"
            >
              <v-icon left> mdi-cloud-upload-outline </v-icon>
              {{ $t("components.preCadastro.template.form.Selectfile") }}
            </v-btn>
            <input
              ref="uploaderContrato"
              class="d-none"
              accept="image/*, application/pdf"
              type="file"
              @change="uploadContrato"
            />
          </div>
        </v-card-text>
        <v-divider></v-divider>

        <v-card-actions>
          <v-btn large text @click="dialogContrato = false"> {{ $t("components.preCadastro.template.form.Cancel") }}</v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="purple darken-3"
            dark
            :disabled="!hasContrato"
            @click="sendDoc('doc_contrato')"
          >
          {{ $t("components.preCadastro.template.form.Send") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import Vue from "vue";
import Api from "@/services/Api";
import { Overlay } from "@/lib/Overlay";
import moment from "moment";
import { Snackbar } from "@/lib/Snackbar";
import Swal from "sweetalert2";
import { Cropper } from "vue-advanced-cropper";
import Navigation from "./AvatarNavigation.vue";
import "vue-advanced-cropper/dist/style.css";
export default Vue.extend({
  name: "Documetos",

  data() {
    return {
      e1: 1,
      zoom: 2,
      isMobile: false,
      dialogSelfie: false,
      dialogSelfieInstrucoes: false,
      dialogFrenteDoc: false,
      dialogVersoDoc: false,
      dialogContrato: false,
      dadosDocs: [],
      loadingData: true,
      docFrente: "",
      docVerso: "",
      docContrato: "",
      hasDocFrente: false,
      hasDocVerso: false,
      hasContrato: false,
      lang: navigator.language,
      errors: [],
      hasAttachedSelfie: false,
      stencilSizeDefault: {
        width: 300,
        height: 300,
      },

      moment: moment,
      imageSelfie: {
        src: require("../../assets/images/default-selfie.png"),
        type: "",
      },
      imageDocFrente: {
        src: require("../../assets/images/identidade-frente-sample.png"),
        type: "",
      },
      imageDocVerso: {
        src: require("../../assets/images/identidade-verso-sample.png"),
        type: "",
      },
    };
  },
  created() {
    this.isMobile = this.detectMobile();
    this.moduloInfo();
  },
  components: {
    Cropper,
    Navigation,
  },

  methods: {
    cancelaSelfie() {
      this.dialogSelfie = false;

      this.e1 = 1;
    },
    openContratoInstructions(st) {
      if (st === "Pendente" || st === "Recusado") {
        this.e1 = 1;
        this.dialogContrato = true;
      }
    },
    openSelfieInstructions(st) {
      if (st === "Pendente" || st === "Recusado") {
        this.e1 = 1;
        this.dialogSelfieInstrucoes = true;
      }
    },
    openDialogSelfie() {
      this.e1 = 1;
      this.dialogSelfieInstrucoes = false;
      this.dialogSelfie = true;
    },
    openDialogDocFrente(st) {
      if (st === "Pendente" || st === "Recusado") {
        this.e1 = 1;
        this.dialogFrenteDoc = true;
      }
    },
    openDialogDocVerso(st) {
      if (st === "Pendente" || st === "Recusado") {
        this.e1 = 1;
        this.dialogVersoDoc = true;
      }
    },

    detectMobile() {
      if (screen.width <= 760) {
        return true;
      } else {
        return false;
      }
    },
    sendDoc(tipo) {
      this.dialogFrenteDoc = false;
      this.dialogVersoDoc = false;
      this.dialogContrato = false;

      let dataSend = { doc_imagem_frente: this.docFrente };
      if (tipo === "doc_imagem_verso") {
        dataSend = { doc_imagem_verso: this.docVerso };
      }
      if (tipo === "doc_contrato") {
        dataSend = { doc_contrato: this.docContrato };
      }
      this.btnLoading = true;
      Overlay.show();
      Api.post("/v2/client/profile/docs/send", dataSend)
        .then((response) => {
          Snackbar.show("Documento enviado com sucesso!");
          this.moduloInfo();
        })
        .catch((error) => {
          const response = error.response;
          const status = response.status;
          const data = response.data;

          Swal.fire({
            text: data.body.error,
            icon: "error",
            confirmButtonText: "Ok",
            showConfirmButton: true,
            customClass: {
              confirmButton: "d-block",
            },
          });
          if (tipo === "doc_imagem_verso") {
            this.dialogVersoDoc = true;
          }
          if (tipo === "doc_imagem_frente") {
            this.dialogFrenteDoc = true;
          }
        })
        .finally(() => {
          this.btnLoading = false;

          Overlay.hide();
        });
    },
    sendSelfie(tipo, img) {
      this.dialogSelfie = false;
      let dataSend = { doc_selfie: img };

      this.btnLoading = true;
      Overlay.show();
      Api.post("/v2/client/profile/docs/send", dataSend)
        .then((response) => {
          //

          this.dialogSelfie = false;
          Snackbar.show("Documento enviado com sucesso!");
          this.moduloInfo();
        })
        .catch((error) => {
          const response = error.response;
          const status = response.status;
          const data = response.data;

          Swal.fire({
            text: data.body.error,
            icon: "error",
            confirmButtonText: "Ok",
            showConfirmButton: true,
            customClass: {
              confirmButton: "d-block",
            },
          });
          this.dialogChangeFoto = true;
        })
        .finally(() => {
          this.btnLoading = false;

          Overlay.hide();
        });
    },
    moduloInfo() {
      Overlay.show();

      this.loadingData = true;
      Api.get("/v2/client/profile/docs/info")
        .then((response) => {
          this.dadosDocs = response.data.body;
          this.loadingData = false;
        })
        .catch((error) => {
          const response = error.response;
          const status = response.status;
          const data = response.data;

          if (status != 200) {
            //exe
          }
          // this.resetRecaptcha();
        })
        .finally(() => {
          Overlay.hide();
        });
    },
    uploadContrato(e) {
      this.selectedFile = e.target.files[0];

      const reader = new FileReader();

      (reader.onload = () => {
        this.docContrato = reader.result;
        this.hasContrato = true;
      }),
        reader.readAsDataURL(e.target.files[0]);
    },
    uploadDocFrente(e) {
      this.selectedFile = e.target.files[0];

      const reader = new FileReader();

      (reader.onload = () => {
        this.docFrente = reader.result;
        this.hasDocFrente = true;
        this.imageDocFrente.src = this.docFrente;
      }),
        reader.readAsDataURL(e.target.files[0]);
    },
    uploadDocVerso(e) {
      this.selectedFile = e.target.files[0];

      const reader = new FileReader();

      (reader.onload = () => {
        this.docVerso = reader.result;
        this.hasDocVerso = true;
        this.imageDocVerso.src = this.docVerso;
      }),
        reader.readAsDataURL(e.target.files[0]);
    },
    loadImageSelfie(event) {
      // Reference to the DOM input element
      const { files } = event.target;
      // Ensure that you have a file before attempting to read it
      if (files && files[0]) {
        if (this.imageSelfie.src) {
          URL.revokeObjectURL(this.imageSelfie.src);
        }

        const blob = URL.createObjectURL(files[0]);

        // Create a new FileReader to read this image binary data
        const reader = new FileReader();
        // Define a callback function to run, when FileReader finishes its job
        reader.onload = () => {
          // Note: arrow function used here, so that "this.image" refers to the image of Vue component
          this.imageSelfie = {
            // Set the image source (it will look like blob:http://example.com/2c5270a5-18b5-406e-a4fb-07427f5e7b94)
            src: blob,
            // Determine the image type to preserve it during the extracting the image from canvas:
            type: "",
          };
        };
        // Start the reader job - read file as a data url (base64 format)
        reader.readAsArrayBuffer(files[0]);
        this.hasAttachedSelfie = true;
      }
    },
    destroyed() {
      // Revoke the object URL, to allow the garbage collector to destroy the uploaded before file
      if (this.imageSelfie.src) {
        URL.revokeObjectURL(this.imageSelfie.src);
      }
    },
    defaultSize({ imageSize }) {
      return {
        width: Math.min(imageSize.height, imageSize.width),
        height: Math.min(imageSize.height, imageSize.width),
      };
    },
    stencilSize({ boundaries }) {
      return {
        width: Math.min(boundaries.height, boundaries.width) - 48,
        height: Math.min(boundaries.height, boundaries.width) - 48,
      };
    },
    onChange(result) {
      const cropper = this.$refs.cropper;
      if (cropper) {
        const { coordinates, imageSize } = cropper;
        if (
          imageSize.width / imageSize.height >
          coordinates.width / coordinates.height
        ) {
          // Determine the position of slider bullet
          // It's 0 if the stencil has the maximum size and it's 1 if the has the minimum size
          this.zoom =
            (cropper.imageSize.height - cropper.coordinates.height) /
            (cropper.imageSize.height - cropper.sizeRestrictions.minHeight);
        } else {
          this.zoom =
            (cropper.imageSize.width - cropper.coordinates.width) /
            (cropper.imageSize.width - cropper.sizeRestrictions.minWidth);
        }
      }
    },
    onZoom(value) {
      const cropper = this.$refs.cropper;
      if (cropper) {
        if (cropper.imageSize.height < cropper.imageSize.width) {
          const minHeight = cropper.sizeRestrictions.minHeight;
          const imageHeight = cropper.imageSize.height;
          // Determine the current absolute zoom and the new absolute zoom
          // to calculate the sought relative zoom value
          cropper.zoom(
            (imageHeight - this.zoom * (imageHeight - minHeight)) /
              (imageHeight - value * (imageHeight - minHeight))
          );
        } else {
          const minWidth = cropper.sizeRestrictions.minWidth;
          const imageWidth = cropper.imageSize.width;
          cropper.zoom(
            (imageWidth - this.zoom * (imageWidth - minWidth)) /
              (imageWidth - value * (imageWidth - minWidth))
          );
        }
      }
    },
    crop(tipo) {
      const { coordinates, canvas } = this.$refs.cropper.getResult();
      this.coordinates = coordinates;
      // You able to do different manipulations at a canvas
      // but there we just get a cropped image
      //console.log(canvas.toDataURL());

      this.sendSelfie(tipo, canvas.toDataURL());
      //this.image = canvas.toDataURL();
    },
  },
});
</script>
<style lang="scss">
.twitter-cropper {
  height: 350px;

  &__stencil {
    border: solid 5px rgb(29, 161, 242);
  }
}
.cropper-wrapper {
  overflow: hidden;
  position: relative;
  height: 350px;
  background: white;
}
.cropper-background {
  background: none;
}
.image-background {
  position: absolute;
  width: calc(100% + 10px);
  height: calc(100% + 10px);
  left: -10px;
  top: -10px;
  background-size: cover;
  background-position: 50%;
  filter: blur(15px);
}
</style>
