
import Vue from "vue";
import Api from "@/services/Api";
import { Overlay } from "@/lib/Overlay";
import { AxiosResponse } from "axios";
import { VMoney } from "v-money";
import moment from "moment";
import { TableOptions } from "@/models/TableOptions";
import { SwapQuote } from "@/models/SwapQuote";
import Swal from "sweetalert2";
import jwtDecode from "jwt-decode";

const __DIALOG_COUNTDOWN_TIMEOUT_SEC = 45;

export interface Swaps {
  id: string;
  swap: any;
}
export default Vue.extend({
  name: "NewSwap",

  data() {
    return {
      amountInMin: 0,  // Valor inicial antes de carregar da API
    amountInMax: 0,  // Valor inicial antes de carregar da API
    netAmountOutMax: 0,  // Valor inicial antes de carregar da API
    netAmountOutMaxFormatado: '0,00',  // Valor formatado para exibição
      loadingBalance: false,
      saldoDisponivel: "0,00",
      showSaldo: false,
      transactionPin: "",
      btnloading: false,
      OkCreateSwap: false,
      dialogConfirmSwap: false,
      QuoteRespJWT: [],
      showAmountToPayUSDT: true,
      showNetInverseRateAmountOut: false,
      netInverseRateAmountOut: "R$ 5,54 = 1 USDT",
      netRateAmountOut: "1 USDT = R$ 5,54",
      amountIn: {
        amountIn: "0.0000",
        amountInFormatado: "0,00",
        amountInUSD: "0,00",
        amountInEUR: "0,00",
      },
      ticketPrice: "1 USDT = R$ 5,54",
      netAmountOut: {
        netAmountOut: "0.000000",
        netAmountOutFormatado: "0,00",
        netAmountOutEURFormatado: "0,00",
      },
    valoresCarregados: false, 
      dialogSwap: false,
      serviceTax: "0,00",
      serviceTaxPerc: "0",
      showAlert: false,
      withdrawTax: "0,00",
      blockchainTax: "0",
      addressOut: "p2p-mainnet",
      addressOutPlaceholder: "0x48d17...",
      defaultBtnCurrencyIn: {
        icon: require("@/assets/images/brl.svg"),
        namePt: "Real",
        sigla: "BRL",
      },
      defaultBtnCurrencyOut: {
        icon: require("@/assets/images/usdt.svg"),
        namePt: "Tether",
        sigla: "USDT",
      },
      defaultBtnNetWorkOut: {
        icon: require("@/assets/images/eth.svg"),
        namePt: "Ethereum (ERC20)",
      },
      errors: {} as Record < string,string > ,
      swapExactFiatForCrypto: false,
      swapFiatForExactCrypto: false,
      formQuote: {} as SwapQuote,

      loadingDesserts: true,
      totalDesserts: 0,
      options: {} as TableOptions,
      search: "",
      moment: moment,
      lang: navigator.language,
      page: 1,
      pageCount: 0,
      itemsPerPage: 50,
      timeout: 0,
      markets: [], //JSON.parse(localStorage.getItem("SwapMarkets")),
      isMobile: false,
      isBtc: false,
      money: {
        decimal: ",",
        thousands: ".",
        prefix: "",
        suffix: "",
        precision: 2,
        masked: false /* doesn't work with directive */ ,
      },
      moneycoin: {
        decimal: ",",
        thousands: ".",
        prefix: "",
        suffix: "",
        precision: 6,
        masked: false /* doesn't work with directive */ ,
      },
      moneyNoMask: {
        decimal: "",
        thousands: "",
        prefix: "",
        suffix: "",
        precision: 0,  // Precisão alta para moedas como BTC e ETH
        masked: false,
      },
      moneyBTC: {
        decimal: ",",
        thousands: "",
        prefix: "",
        suffix: "",
        precision: 8,
        masked: false /* doesn't work with directive */ ,
      },

      currencies: [] as any,
      marketsBTC: [] as any,
      marketsUSDT: [] as any,
      defaultMarket: [] as any,
      user: "",
      amountInBrl: "",
      rules: {
        required: [
    (value: string) => !!value || "Campo obrigatório.",  // Validação de valor negativo
  ]
      },
      netAmountOutBTC: "",
      okToQuote: true,
      countDown: __DIALOG_COUNTDOWN_TIMEOUT_SEC, //contador de tempo
      countDownCount: 0,
      countDownStart: true,
    };
  },

  created() {
    this.isMobile = this.detectMobile();

    this.getCurrencies();
    this.getBalanceSwap();
    this.formQuote.currencyIn = "BRL";
    this.formQuote.currencyOut = "USDT";
    this.formQuote.networkOut = "eth-mainnet";
    this.formQuote.netAmountOut = this.netAmountOut.netAmountOut;
    // this.countDownTimer();
    this.countDownStart = false;
  },

  methods: {
    validateKeyPress(event: KeyboardEvent) {
    const charCode = event.charCode;
    // Permitir números (0-9) e o ponto (.)
    if ((charCode >= 48 && charCode <= 57) || charCode === 46) {
      return true;
    }
    // Bloquear outros caracteres
    event.preventDefault();
    return false;
  },
    onAmountInInput(value: string) {
  // Remover todos os caracteres que não sejam números ou pontos
  const cleanedValue = value.replace(/[^0-9.]/g, '');

  // Substituir o valor original no campo pelo valor limpo
  this.amountInBrl = cleanedValue;

  // Validação de moedas
  const currencyWithoutMask = ['USDT', 'TRX', 'USDC'];

  if (['BTC', 'ETH'].includes(this.defaultBtnCurrencyOut.sigla) || currencyWithoutMask.includes(this.defaultBtnCurrencyOut.sigla)) {
    // Para BTC e ETH, permite o valor livre com ponto
    this.amountInBrl = cleanedValue;
  } else {
    // Para outras moedas, formata o valor como BRL
    let formattedValue = cleanedValue.replace('.', ',');
    formattedValue = formattedValue.replace(/[^0-9,]/g, '');

    const finalValue = formattedValue.replace(',', '.');
    const numberValue = parseFloat(finalValue);

    if (!isNaN(numberValue)) {
      this.amountInBrl = numberValue.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).replace('.', ',');
    } else {
      this.amountInBrl = formattedValue;
    }
  }

  // Chama a validação
  this.validateAmountIn();
}


,
    updateAmountInFormatted() {
      const valor = this.removeFormatacaoMoeda(this.amountInBrl);
  if (['BTC', 'ETH'].includes(this.defaultBtnCurrencyOut.sigla)) {
    // Para BTC ou ETH, usa 8 casas decimais
    this.amountIn.amountInFormatado = parseFloat(valor).toFixed(8);
  } else {
    // Para outras moedas, usa 2 casas decimais
    this.amountIn.amountInFormatado = (parseFloat(valor) / 100).toFixed(2);
  }
  },
  validateAmountIn() {
  const valorAmountIn = this.removeFormatacaoMoeda(this.amountInBrl);
  const valorAmountInFloat = parseFloat(valorAmountIn.replace(',', '.'));

  // Verifica se o valor é menor que o mínimo dinâmico
  if (valorAmountInFloat < this.amountInMin) {
    this.errors['amountIn'] = this.$t('components.preCadastro.template.form.MinimumAmountExceeded', {
      minValue: this.amountInMin.toFixed(8)  // Mostra até 8 casas para moedas cripto
    }).toString();
    this.OkCreateSwap = false; // Desabilita o botão
    return false;
  }

  // Verifica se o valor é maior que o máximo dinâmico
  if (valorAmountInFloat > this.amountInMax) {
    this.errors['amountIn'] = this.$t('components.preCadastro.template.form.AmountExceeded', {
      maxValue: this.amountInMax.toFixed(8)  // Mostra até 8 casas para moedas cripto
    }).toString();
    this.OkCreateSwap = false; // Desabilita o botão
    return false;
  }

  // Se tudo estiver correto, limpa os erros e ativa o botão
  this.errors['amountIn'] = '';
  this.OkCreateSwap = true; // Ativa o botão
  return true;
},






    recarregarGrid() {
      this.getMarkets(); // Chama o método que carrega os dados da grid
    },
    openDialoQuote() {
      this.resetFieldsQuote();
      this.getBalanceSwap();

      this.dialogSwap = true;
      this.countDownStart = false;
    },
    resetFieldsQuote() {
      this.dialogConfirmSwap = false;
      this.OkCreateSwap = false;
      this.btnloading = false;
      this.amountInBrl = "";
      this.netAmountOutBTC = "";
      let inputNetAmountOut =
        (document.getElementById("BTC") as HTMLInputElement) || {};
      inputNetAmountOut.value = "0";
      let inputAmountIn =
        (document.getElementById("BRL") as HTMLInputElement) || {};
      inputAmountIn.value = "0";
      this.formQuote.currencyIn = "BRL";
      this.formQuote.currencyOut = "USDT";
      this.formQuote.networkOut = "eth-mainnet";
      this.formQuote.amountIn = "";
      this.formQuote.netAmountOut = "";

      this.netInverseRateAmountOut = "R$ 5,54 = 1 USDT";
      this.netRateAmountOut = "1 USDT = R$ 5,54";
      (this.amountIn = {
  amountIn: "0.00086000", // Valor original de BTC ou outra moeda
  // Aplicar a formatação condicionalmente para BTC (com 8 casas decimais) ou outras moedas (com 2 casas decimais)
  amountInFormatado: this.defaultBtnCurrencyOut.sigla === 'BTC'
    ? parseFloat("0.00086000").toFixed(8) // Formatação específica para BTC com 8 casas decimais
    : parseFloat(this.amountIn.amountIn).toFixed(2), // Para outras moedas com 2 casas decimais
  amountInUSD: "50.00",  // Valor padrão ou real para USD, pode ser atualizado com o valor correto
  amountInEUR: "45.00",  // Valor padrão ou real para EUR, pode ser atualizado com o valor correto
}),

      (this.ticketPrice = "1 USDT = R$ 5,54");
      this.netAmountOut = {
        netAmountOut: "0.00000000",
        netAmountOutFormatado: "0,00",
        netAmountOutEURFormatado: "0,00",
      };
      this.transactionPin = "";
      this.QuoteRespJWT = [];
      this.serviceTax = "0,00";
      this.serviceTaxPerc = "0";
      this.withdrawTax = "0,00";
      this.blockchainTax = "0";
      this.addressOut = "p2p-mainnet";
      this.countDownStart = false;
      this.countDownCount = 0;
    },
    openDialogConfirmSwap() {
      if (!this.OkCreateSwap) {
        return; // Se OkCreateSwap for falso, impede de abrir o diálogo
      }
      this.dialogConfirmSwap = true;
    },
    cancelConfirmSwap() {
      this.resetFieldsQuote();
      this.transactionPin = "";
      this.dialogConfirmSwap = false;
      this.dialogSwap = true;
    },
    cancelSwap() {
      this.resetFieldsQuote();
      this.OkCreateSwap = false;
      this.dialogSwap = false;
    },
    createSwap() {
      this.btnloading = true;

      this.errors = {} as Record < string, string > ;

      if (this.transactionPin.length < 6) {
        const errorMessage = this.$t('components.preCadastro.template.form.pinInvalido') as string;
        this.errors["transactionPin"] = errorMessage;
        this.btnloading = false;
        return false;
      }

      this.OkCreateSwap = false;
      this.countDownStart = false;

      Overlay.show();
      this.btnloading = true;
      this.dialogConfirmSwap = false;
      Api.post("/v2/client/cripto/swaps/swap-reverse", {
          transactionPin: this.transactionPin,
          addressOut: this.addressOut,
          userId: sessionStorage.getItem("userCriptoId"),
          quoteData: this.QuoteRespJWT,
        })
        .then((response) => {
          this.dialogConfirmSwap = false;
          this.dialogSwap = false;
          this.addressOut = "p2p-mainnet";
          Swal.fire({
            title: this.$t('components.preCadastro.template.form.conversaoSucesso') as string,
            text: this.$t('components.preCadastro.template.form.aguardeProcessamento') as string,
            icon: "success",
          }).then(() => {
            // Emite um evento para informar que o diálogo Pix deve ser aberto
            this.$emit('open-dialog-pix');
          });
          this.$emit("callmethod", 0);
          this.resetFieldsQuote();
          this.countDownStart = false;
          this.dialogSwap = false;
          this.dialogConfirmSwap = false;
        })
        .catch((error: any) => {
          const response = error.response as AxiosResponse;
          const status = response.status;
          const data = response.data;

          if (status != 200) {
            Swal.fire({
              title: "Opps",
              text: data.body.error,
              icon: "error",
            });
          }
          this.dialogConfirmSwap = false;
          this.resetFieldsQuote();
          this.OkCreateSwap = false;
          // this.resetRecaptcha();
        })
        .finally(() => {
          Overlay.hide();
          this.btnloading = false;
          this.OkCreateSwap = true;
          this.okToQuote = true;
          this.dialogConfirmSwap = false;
        });
    },
    //crypto para real
    fnSwapFiatForExactCrypto() {
      this.errors = {} as Record < string, string > ;
      this.swapExactFiatForCrypto = false;
      this.swapFiatForExactCrypto = true;

      this.formQuote.type = "swapFiatForExactCrypto";
    },
    fnSwapExactFiatForCrypto() {
      this.errors = {} as Record < string, string > ;
      this.swapExactFiatForCrypto = true;
      this.swapFiatForExactCrypto = false;
      this.formQuote.type = "swapExactFiatForCrypto";
    },
    makeBtnCurrencyIn(icon: string, namePt: string, sigla: string) {
      this.defaultBtnCurrencyIn.icon = require("@/assets/images/" + icon.toLocaleLowerCase() + ".svg");
      this.defaultBtnCurrencyIn.namePt = namePt;
      this.defaultBtnCurrencyIn.sigla = sigla;

      // Atualizar a moeda de entrada
      this.formQuote.currencyIn = sigla;

      // Definir a rede correspondente à moeda de entrada, se aplicável
      if (this.defaultMarket.length > 0) {
        this.formQuote.networkIn = this.defaultMarket[0].nameId; // Atualizar a networkIn conforme necessário
      }
    },

    makeBtnCurrencyOut(item: any) {
  // Limpar erros ao trocar de moeda
  this.errors['amountIn'] = ''; 

  // Atualizar ícone e nome da moeda de saída
  this.defaultBtnCurrencyOut.icon = require("@/assets/images/" + item.currency.toLocaleLowerCase() + ".svg");
  this.defaultBtnCurrencyOut.namePt = item.name;
  this.defaultBtnCurrencyOut.sigla = item.currency;

  // Lógica de formatação para a moeda selecionada
  const currencyWithoutMask = ['USDT', 'TRX', 'USDC'];

  if (item.currency === "BTC" || item.currency === "ETH" || currencyWithoutMask.includes(item.currency)) {
    // Para BTC, ETH e moedas sem máscara (USDT, TRX, USDC)
    this.amountInBrl = '';
    this.money = {
      decimal: ".",
      thousands: ",",
      prefix: "",
      suffix: "",
      precision: 8, // Moedas digitais usam até 8 casas decimais
      masked: false
    };
  } else {
    // Para outras moedas
    this.amountInBrl = '';
    this.money = {
      decimal: ",",
      thousands: ".",
      prefix: "R$ ",
      suffix: "",
      precision: 2, 
      masked: false
    };
  }

  // Definir redes disponíveis para a moeda selecionada
  this.defaultMarket = item.deposit.networks;

  // Atualizar a primeira rede válida para a moeda selecionada
  if (this.defaultMarket.length > 0) {
    this.formQuote.networkOut = this.defaultMarket[0].nameId; // Atualiza a networkOut
    this.formQuote.networkIn = this.defaultMarket[0].nameId;  // Atualiza também a networkIn aqui
    this.defaultBtnNetWorkOut.icon = require("@/assets/images/" + this.defaultMarket[0].currencySymbol.toLocaleLowerCase() + ".svg");
    this.defaultBtnNetWorkOut.namePt = this.defaultMarket[0].description;
    this.addressOutPlaceholder = this.defaultMarket[0].placeholder;
  }

  // Atualizar a moeda de saída
  this.formQuote.currencyOut = item.currency;

  // Obter nova cotação
  this.getQuote();
},



makeBtnNetworkOut(item: any) {
  this.formQuote.networkOut = item.nameId;
  this.formQuote.networkIn = item.nameId;  // Atualiza a networkIn
  this.addressOutPlaceholder = item.placeholder;
  this.defaultBtnNetWorkOut.icon = require("@/assets/images/" + item.currencySymbol.toLocaleLowerCase() + ".svg");
  this.defaultBtnNetWorkOut.namePt = item.description;
},


    detectMobile() {
      if (screen.width <= 760) {
        return true;
      } else {
        return false;
      }
    },

    getMarkets() {
      this.loadingDesserts = true;
      Overlay.show();
      Api.get("/v2/client/cripto/swaps/markets")
        .then((response) => {
          this.markets = response.data.data;

          const markets = response.data.data;
          for (let index = 0; index < markets.length; index++) {
            const element = markets[index];

            if (element.baseCurrency.currency === "USDT") {
              this.marketsUSDT = markets[index].baseCurrency.withdraw
                .networks as any;
              this.defaultMarket = this.marketsUSDT;
            }
            if (element.baseCurrency.currency === "BTC") {
              this.marketsBTC = markets[index].baseCurrency.withdraw.networks;
            }
          }
        })
        .finally(() => {
          Overlay.hide();
          this.loadingDesserts = false;
          this.dialogSwap = false;
        });
    },

    getCurrencies() {
      this.loadingDesserts = true;
      Overlay.show();
      Api.get("/v2/client/cripto/swaps/currencies")
        .then((response) => {
          this.currencies = response.data.data;

          localStorage.removeItem("SwapCurrencies");
          localStorage.setItem(
            "SwapCurrencies",
            JSON.stringify(response.data.data)
          );
        })
        .finally(() => {
          this.getMarkets();
          Overlay.hide();
          this.loadingDesserts = false;
        });
    },

    removeFormatacaoMoeda($str: string) {
      return $str.replace(/\D/g, "");
    },

    validaAmountIn() {
  // Mantém o valor original como string sem conversão para número
  const valorAmountInOriginal = this.amountInBrl.trim();

  // Exibe os valores brutos para debug
  console.log('Valor original (amountInBrl):', valorAmountInOriginal);

  // Verifica se o valor é zero ou vazio
  if (valorAmountInOriginal === '0' || valorAmountInOriginal === '0.00000000' || valorAmountInOriginal === '' || parseFloat(valorAmountInOriginal) === 0) {
    this.errors['amountIn'] = this.$t('components.preCadastro.template.form.AmountCannotBeZero').toString();
    this.OkCreateSwap = false;
    console.log('Erro: Valor não pode ser zero.');
    return false;
  }

  // Validação do valor mínimo (usa comparação entre strings)
  if (parseFloat(valorAmountInOriginal) < this.amountInMin) {
    this.errors['amountIn'] = this.$t('components.preCadastro.template.form.MinimumAmountExceeded', {
      minValue: this.amountInMin.toFixed(8)
    }).toString();
    this.OkCreateSwap = false;
    console.log('Erro: Valor abaixo do mínimo permitido.');
    return false;
  }

  // Validação do valor máximo (usa comparação entre strings)
  if (parseFloat(valorAmountInOriginal) > this.amountInMax) {
    this.errors['amountIn'] = this.$t('components.preCadastro.template.form.AmountExceeded', {
      maxValue: this.amountInMax.toFixed(8)
    }).toString();
    this.OkCreateSwap = false;
    console.log('Erro: Valor acima do máximo permitido.');
    return false;
  }

  // Se tudo estiver correto, limpa os erros e habilita o botão
  this.errors['amountIn'] = ''; // Limpa os erros
  this.OkCreateSwap = true; // Ativa o botão
  console.log('Valor válido, botão habilitado.');
  return true;
},
    validaAmountOut() {
      const valorNetAmountOut = this.netAmountOutBTC;

      // Verifica se o valor é "0.00000", "0", vazio ou nulo
      if (valorNetAmountOut === "0.00000" || valorNetAmountOut === "0" || valorNetAmountOut === "" || valorNetAmountOut === null) {
        this.errors["netAmountOut"] = String(this.$t('components.preCadastro.template.form.MinimumAmountExceeded', {
          minValue: "0.000001"
        }));
        this.OkCreateSwap = false; // Bloqueia o botão de avançar
        return false;
      }

      // Se o valor for válido, limpa o erro e permite avançar
      this.errors["netAmountOut"] = ''; // Limpa qualquer erro anterior
      this.OkCreateSwap = true; // Libera o botão de avançar
      return true;
    },

    countDownTimer() {
      if (this.countDownStart && this.countDownCount <= 5) {
        setTimeout(() => {
          if (this.countDown > 0 && this.countDownStart) {
            this.countDown -= 1;
            this.countDownTimer();
          }

        }, 1000);

        if (this.countDown == 0) {
          this.countDownCount += 1;
          this.countDown = __DIALOG_COUNTDOWN_TIMEOUT_SEC;

          this.countDownStart = false;
          this.getQuote();
        }
        if (this.countDownCount == 5) {

          this.resetFieldsQuote();
          this.countDownStart = false;
        }

      }
    },
    getQuote() {
  this.errors = {};
  this.QuoteRespJWT = [];
  this.btnloading = true;
  this.OkCreateSwap = false;

  // Validar o valor inserido antes de prosseguir
  const valorAmountIn = parseFloat(this.amountInBrl.replace(',', '.'));

   // Verificar se o valor inserido é zero
   if (valorAmountIn === 0 || isNaN(valorAmountIn)) {
    this.btnloading = false;
    this.OkCreateSwap = false;
    return false; // Interromper a execução silenciosamente se o valor for zero
  }

  // Verificar se o valor inserido é menor que o mínimo permitido (1 TRX)
  if (this.defaultBtnCurrencyOut.sigla === 'TRX' && valorAmountIn < 1) {
    Swal.fire({
      title: "Erro",
      text: "Você digitou um valor abaixo do mínimo.",
      icon: "error",
    });
    this.btnloading = false;
    this.OkCreateSwap = false;
    return false; // Interromper a execução se o valor for inválido
  }

  setTimeout(() => {
    let postParams = {};
    const availableNetworks = this.defaultMarket;
    const networkIn = this.formQuote.networkIn || (availableNetworks.length === 1 
      ? availableNetworks[0].nameId 
      : (this.formQuote.networkOut === 'p2p-mainnet' ? 'eth-mainnet' : this.formQuote.networkOut));

    const networkOut = this.formQuote.networkOut === 'p2p-mainnet' ? 'eth-mainnet' : this.formQuote.networkOut;

    // Verifica se é uma troca exata de cripto para BRL (swapFiatForExactCrypto)
    if (this.swapExactFiatForCrypto) {
      // Validação para troca exata de cripto
      if (!this.amountInBrl) {
        this.btnloading = false;
        this.OkCreateSwap = false;
        return false;
      }

      // Usar somente o parâmetro `amountIn`
      postParams = {
        amountIn: this.amountInBrl.trim(),
        currencyIn: this.defaultBtnCurrencyOut.sigla,
        currencyOut: 'BRL',
        networkOut: "p2p-mainnet",
        networkIn,
        user: sessionStorage.getItem("userCriptoId"),
        type: "swapCryptoForFiat",
        typeQuote: "sell",
      };

    // Verifica se é uma troca de fiat para cripto (swapFiatForExactCrypto)
    } else if (this.swapFiatForExactCrypto) {
      if (!this.validaAmountOut()) {
        Swal.fire({
          title: "Erro",
          text: "O valor inserido em 'Você vai receber' é inválido. Por favor, insira um valor válido.",
          icon: "error",
        });
        this.btnloading = false;
        this.OkCreateSwap = false;
        return false;
      }

      // Usar somente o parâmetro `netAmountOut`
      postParams = {
        netAmountOut: this.netAmountOutBTC,
        currencyIn: this.defaultBtnCurrencyOut.sigla,
        currencyOut: 'BRL',
        networkOut: "p2p-mainnet",
        networkIn,
        user: sessionStorage.getItem("userCriptoId"),
        type: "swapCryptoForFiat",
        typeQuote: "sell",
      };

    } else {
      // Caso não seja possível identificar a operação
      this.errors["operation"] = String("Erro ao identificar o tipo de operação. Verifique os valores inseridos.");
      Swal.fire({
        title: "Erro",
        text: "Tipo de operação não reconhecido.",
        icon: "error",
      });
      this.btnloading = false;
      this.OkCreateSwap = false;
      return;
    }

    // Iniciar o carregamento
    this.loadingDesserts = true;
    this.okToQuote = false;

    Api.get("/v2/client/cripto/swaps/quote-brl", {
        params: postParams,
      })
      .then((response) => {
        const dataResp = jwtDecode(response.data.body.quote) as any;
        this.QuoteRespJWT = response.data.body.quote;

        // Extração dos valores mínimos e máximos da resposta da API
        this.amountInMin = parseFloat(dataResp.data.data.quote.amountInMin);
        this.amountInMax = parseFloat(dataResp.data.data.quote.amountInMax);
        this.netAmountOutMax = parseFloat(dataResp.data.data.quote.netAmountOutMax);

        console.log("Valores da API: Min =", this.amountInMin, "Max =", this.amountInMax);

        // Agora que os valores mínimos e máximos foram carregados, podemos validar `amountIn`
        if (!this.validaAmountIn()) {
          this.btnloading = false;
          this.OkCreateSwap = false;
          return false;
        }

        this.$nextTick(() => {
          if (this.swapExactFiatForCrypto) {
            let inputNetAmountOut = document.getElementById("BTC") as HTMLInputElement;
            inputNetAmountOut.value = dataResp.data.data.quote.netAmountOut.netAmountOut;
          } else {
            let inputAmountIn = document.getElementById("BRL") as HTMLInputElement;
            inputAmountIn.value = dataResp.data.data.quote.amountIn.amountInFormatado;
          }
        });

        // Atualiza os valores mínimos e máximos da resposta
        this.formQuote = {
          ...dataResp.data.data.quote,
          netAmountOutMin: {
            netAmountOutMinFormatado: dataResp.data.data.quote.netAmountOutMinFormatado || null
          },
          netAmountOutMax: {
            netAmountOutMaxFormatado: dataResp.data.data.quote.netAmountOutMaxFormatado || null
          }
        };

        this.netAmountOut = dataResp.data.data.quote.netAmountOut;
        this.amountIn = dataResp.data.data.quote.amountIn;
        this.serviceTax = dataResp.data.data.quote.fees.service.amount;
        this.serviceTaxPerc = dataResp.data.data.quote.fees.service.percentage.toString();
        this.blockchainTax = dataResp.data.data.quote.fees.network.amount + " " + dataResp.data.data.quote.fees.network.currency;
        this.netRateAmountOut = "1 " + dataResp.data.data.quote.netRateCurrencyOut + " = " + dataResp.data.data.quote.currencyIn + " " + dataResp.data.data.quote.netRateAmountOut;
        this.netInverseRateAmountOut = "1 " + dataResp.data.data.quote.currencyIn + " = " + dataResp.data.data.quote.netInverseRateAmountOut + " " + dataResp.data.data.quote.netRateCurrencyOut;

        this.OkCreateSwap = true; // Libera o botão
        this.countDownStart = true;
        this.countDown = __DIALOG_COUNTDOWN_TIMEOUT_SEC;
        this.countDownTimer();
      })
      .catch((error: any) => {
    this.OkCreateSwap = false;
    const response = error.response as AxiosResponse;
    const status = response.status;
    const data = response.data;

    console.error('Erro na requisição:', error);

    if (status === 422) {
        this.errors = data.body.errors;
        Swal.fire({
            title: "Erro de Validação",
            text: "Ocorreu um erro nos dados enviados. Verifique os campos e tente novamente.",
            icon: "error",
        });
    } else if (status === 500) {
        if (data.body.error === "Amount_Negative_Error_50") {
            Swal.fire({
                title: "Erro",
                text: "Você digitou um valor abaixo do mínimo.",
                icon: "error",
            });
        } else if (data.body.error === "Only one parameter is required: 'amountIn' or 'netAmountOut'") {
            Swal.fire({
                title: "Erro",
                text: "Valor não aceito. Apenas um parâmetro é necessário: 'amountIn' ou 'netAmountOut'.",
                icon: "error",
            });
        } else {
            Swal.fire({
                title: "Erro",
                text: "Você digitou um valor abaixo do mínimo.",
                icon: "error",
            });
        }
    } else {
        Swal.fire({
            title: "Erro",
            text: data.body.error || "Você digitou um valor abaixo do mínimo.",
            icon: "error",
            confirmButtonText: "Ok",
            showConfirmButton: true,
            customClass: {
                confirmButton: "d-block",
            },
        });
    }
})


      .finally(() => {
        Overlay.hide();
        this.loadingDesserts = false;
        this.btnloading = false;
        setTimeout(() => {
          this.okToQuote = true;
        }, 3000);
      });
  }, 500);
}



    ,
    getCurrencySymbol(symbol: string) {

      return require(`@/assets/images/${symbol.toLocaleLowerCase()}.svg`);
    },

    getBalanceSwap() {
      this.loadingBalance = true;

      Api.get("/v2/client/cripto/swaps/balance")
        .then((response) => {
          this.saldoDisponivel = response.data.body.swapBalanceFormatado;
        })
        .finally(() => {
          this.loadingBalance = false;
        });
    },
    
    doDelayedQuote() {
      if (this.timeout) {
        clearTimeout(this.timeout);
      }
      this.timeout = setTimeout(() => {
        //this is your existing function
        this.getQuote();
        this.okToQuote = true;
      }, 0);
    },
  },
  watch: {
    amountInBrl: {
      handler() {
        this.getQuote();
        if (!this.validateAmountIn()) {
          this.OkCreateSwap = false; // Desativa o botão se a validação falhar
          return;
        }

        // Se passar na validação, ativa o botão e segue o fluxo de cotação
        this.OkCreateSwap = true;

        // Verifica se está permitido realizar a cotação
        if (this.okToQuote) {
          this.fnSwapExactFiatForCrypto();
          this.doDelayedQuote();
        }
      },
      immediate: true, // Executa o handler imediatamente na inicialização
    },
    netAmountOut: {
    handler(val) {
    // Remove "R$", pontos e vírgulas do valor para fazer a comparação
    const netAmount = parseFloat(this.netAmountOut.netAmountOutFormatado.replace(/[R$,.]/g, ''));

if (netAmount > 50000000) {  // 500.000,00 em centavos
  this.OkCreateSwap = false;
  this.errors['netAmountOut'] = this.$t('components.preCadastro.template.form.AmountExceededMil', {
    maxValue: '500,000.00'
  }).toString();
} else {
  this.OkCreateSwap = true;
  this.errors['netAmountOut'] = ''; // Limpa o erro
}
},
deep: true,
immediate: true
  },
    netAmountOutBTC: {
      handler() {
        if (!this.validaAmountOut()) {
          return false;
        }

        
        if (this.okToQuote) {
          this.fnSwapFiatForExactCrypto();
          this.doDelayedQuote();
        }
      },
    },
  },
  directives: {
    money: VMoney,
    moneyBTC: VMoney
  },
});
