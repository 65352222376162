
import Vue from 'vue';
import Api from "@/services/Api";
import { AxiosError, AxiosResponse } from "axios";
import Swal from "sweetalert2";
import { Snackbar } from "@/lib/Snackbar";

export default Vue.extend({
  name: "ImportarClientes",
  data() {
      return {
          btnLoading: false,
          file: "",
          errors: [],
          errorInputFile: ""
      }
  },
  methods: {
      submitFile() {
          this.btnLoading = true;
          this.errors = [];
          const fileForm = this.$refs.file as any;
          this.file = fileForm.$refs.input.files[0];
          let formData = new FormData();

          /*
              Add the form data we need to submit
          */
          formData.append('file', this.file);
          if(this.file){
              this.errorInputFile = "";
              Api.post("/v2/charges/client/import", formData,{
                  headers: {
                  'Content-Type': 'multipart/form-data',
                  },
              })
              .then((response: AxiosResponse<any>) => {
                  if (response.status == 200) {
                      Snackbar.show("Clientes Salvos com sucesso!");
                  }
                  this.$emit("callmethod", 0);
                  const data = response.data;
                  console.log(data);
              })
              .catch((e: AxiosError) => {
                  const response = e.response as AxiosResponse;
                  const status = response.status;
                  const data = response.data;

                  if (status == 422) {
                      console.log(data.error);
                      this.errors = data.error;
                      Swal.fire({
                      text: data.error,
                      icon: "error",
                      confirmButtonText: "Ok",
                      showConfirmButton: true,
                      customClass: {
                          confirmButton: "d-block",
                      },
                      });
                  } else {
                      Swal.fire({
                      text: data.error,
                      icon: "error",
                      confirmButtonText: "Ok",
                      showConfirmButton: true,
                      customClass: {
                          confirmButton: "d-block",
                      },
                      });
                  }
              })
              .finally(() => {
                  this.btnLoading = false;
              });
          } else {
              this.btnLoading = false;
              this.errorInputFile = '<span style="color: red;font-size: 11px;">Campo de arquivo .csv obrigatório.</span>'
          }
      },
      handleFileUpload() {
          this.errorInputFile = "";
      },

  },
});
