
import Vue from "vue";
import Api from "@/services/Api";
import { AxiosError, AxiosResponse } from "axios";
import { TableOptions } from "@/models/TableOptions";
import moment from "moment";
import { Snackbar } from "@/lib/Snackbar";
import Swal from "sweetalert2";
import VueMask from "v-mask";
import { Overlay } from "@/lib/Overlay";
import { config } from "@vue/test-utils";

Vue.use(VueMask);
export interface IbPessoasColaboraresLog {
  createdAt?: string;
  content?: string;
}
export interface IbPessoasColaboradores {
  id?: number;
  cpf?: string;
  createdAt?: string;
  nome?: string;
  email?: string;
  telefone?: string;
  senha?: string;
  status?: string;
  updatedAt?: string;
}
export interface IbPessoasColaboradoresNovo {
  id?: number;
  cpf?: string;
  createdAt?: string;
  nome?: string;
  email?: string;
  telefone?: string;
  senha?: string;
  status?: string;
  updatedAt?: string;
}
export interface IbPessoasColaboradoresChangePassword {
  newPassword?: string;
}
export interface IbPessoasColaboradoresChangePin {
  newPin?: string;
}
export interface IbPessoasColaboradoresEdit {
  id?: number;
  cpf?: string;
  createdAt?: string;
  telefone?: string;
  nome?: string;
  email?: string;
}
export interface IbPessoasColaboradoresPermissoes {
  id?: number;
  perm_pix_recebidos?: boolean;
  perm_pix_enviados?: boolean;
  perm_qrcode_static?: boolean;
  perm_pix_pendentes?: boolean;
  perm_extrato?: boolean;
  perm_saldo?: boolean;
  perm_rpt_resumo_anual?: boolean;
  perm_rpt_resumo_mov?: boolean;
  perm_pix_transfer?: boolean;
  perm_recarga_conta?: boolean;
}
export default Vue.extend({
  data() {
    return {
      dialogEditPermissoes: false,
      valid: true,
      isMobile: false,
      dialogNewPassword: false,
      dialogNewPin: false,
      dialogNovoColaborador: false,
      dialogEditColaborador: false,
      userFromChangePassword: 0,
      formNovo: {} as IbPessoasColaboradoresNovo,
      formEdit: {} as IbPessoasColaboradoresEdit,
      formPermissoes: {} as IbPessoasColaboradoresPermissoes,
      formChangePassword: {} as IbPessoasColaboradoresChangePassword,
      formChangePIN: {} as IbPessoasColaboradoresChangePin,
      btnLoading: false,
      userFromChangePermissoes: "",
      errors: [],
      errorsEdit: [],
      nomeNoModal: "",
      lang: navigator.language,
      moment: moment,
      showpass: false,
      search: "",
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
      options: {} as TableOptions,
      selectedEntryStatusColaboradores: {
        id: "TODOS",
        descricao: this.$t('components.preCadastro.template.form.statusColaboradotors.todos')
      },
      statusColaboradores: [
        { id: "TODOS", descricao: "todos" },
        { id: "1", descricao: "ativos" },
        { id: "2", descricao: "bloqueados" },
      ],
      translatedStatusColaboradores: [] as { id: string; descricao: string; }[],
      headers: [
        { text: this.$t('components.colaboradores.template.HeadersTableColaborators.id'), value: "id", sortable: false },
        { text: this.$t('components.colaboradores.template.HeadersTableColaborators.name'), value: "nome", sortable: false },
        { text: this.$t('components.colaboradores.template.HeadersTableColaborators.cpf'), value: "cpf", sortable: false },
        { text: this.$t('components.colaboradores.template.HeadersTableColaborators.email'), value: "email", sortable: false },
        { text: this.$t('components.colaboradores.template.HeadersTableColaborators.phone'), value: "telefone", sortable: false },
        { text: this.$t('components.colaboradores.template.HeadersTableColaborators.mfa'), value: "mfa_enabled", sortable: false },
      ],
      desserts: new Array<IbPessoasColaboradores>(),
      loadingDesserts: false,
      totalDesserts: 0,
      timeout: 0,
      startDate: moment().format("YYYY-MM-DD"),
      endDate: moment().format("YYYY-MM-DD"),
      defaultStartDate: moment().format("YYYY-MM-DD"),
      defaultEndDate: moment().format("YYYY-MM-DD"),
      maxDate: moment().format("YYYY-MM-DD"),
      dessertsLogs: new Array<IbPessoasColaboraresLog>(),
      moduloAtivo: {
        ativo: true,
        mensagem: "",
      },
      configPermissoes: { perm_pix_transfer: 0 },
    };
  },
  
  created() {
    this.isMobile = this.detectMobile();
    // this.moduloInfo();
    this.updateDocumentTitle();
    this.translateStatusColaboradores();
    this.updateHeaders();
  },

  methods: {
    translateStatusColaboradores() {
      this.translatedStatusColaboradores = this.statusColaboradores.map(status => {
        const translationKey = `components.colaboradores.template.statusColaboradotors.${status.descricao}`;
        return {
          id: status.id,
          descricao: this.$t(translationKey) as string
        };
      });
    },
    updateHeaders() {  
      this.headers = this.headers.map(header => {
        const textKey = header.value ? `components.colaboradores.template.HeadersTableColaborators.${header.value}` : '';
        return {
          ...header,
          text: textKey ? this.$t(textKey) : ''
        };
      });    
    },
    updateDocumentTitle() {
      document.title = String(this.$t("components.appBar.template.collaborators"));
    },
    detectMobile() {
      if (screen.width <= 760) {
        return true;
      } else {
        return false;
      }
    },
    doDelayedSearch() {
      if (this.timeout) {
        clearTimeout(this.timeout);
      }
      this.timeout = setTimeout(() => {
        //this is your existing function
        this.loadDatas();
      }, 1000);
    },
    changePwd(id: number, nome: string) {
      this.nomeNoModal = nome;
      this.userFromChangePassword = id;
      this.dialogNewPassword = true;
    },
    changePin(id: number, nome: string) {
      this.nomeNoModal = nome;
      this.userFromChangePassword = id;
      this.dialogNewPin = true;
    },
    updatePassword() {
      Swal.fire({
        title: this.$t('components.common.swals.ConfirmationTitle') as string,
        text: this.$t('components.colaboradores.template.ConfirmPasswordChangeSelectedCollaborator') as string,
        icon: "question",
        iconColor: "#F44336",
        showCancelButton: true,
        confirmButtonColor: "#F44336",
        cancelButtonColor: "#757575",
        confirmButtonText: this.$t('components.common.actions.Change') as string,
        cancelButtonText: this.$t('components.common.actions.Cancel') as string,
      }).then((result) => {
        if (result.isConfirmed) {
          Api.put(
            "/v2/client/colaboradores/changePwd/" + this.userFromChangePassword,
            this.formChangePassword
          )
            .then((response) => {
              Snackbar.show(this.$t('components.colaboradores.template.CollaboratorPasswordChangedSuccessfully') as string);
              this.loadDatas();
              this.resetPwd();
              this.dialogNewPassword = false;
            })
            .catch((e: AxiosError) => {
              const response = e.response as AxiosResponse;
              const status = response.status;
              const data = response.data;

              if (status == 422) {
                this.errors = data.body.errors;
              } else {
                Swal.fire({
                  text: data.body.error,
                  icon: "error",
                  confirmButtonText: "Ok",
                  showConfirmButton: true,
                  customClass: {
                    confirmButton: "d-block",
                  },
                });
              }
            })
            .finally(() => {
              this.btnLoading = false;
            });
        }
      });
    },
    updatePin() {
      Swal.fire({
        title: this.$t("components.common.swals.ConfirmationTitle") as string,
        text: this.$t("components.colaboradores.template.ConfirmPINChangeSelectedCollaborator") as string,
        icon: "question",
        iconColor: "#F44336",
        showCancelButton: true,
        confirmButtonColor: "#F44336",
        cancelButtonColor: "#757575",
        confirmButtonText: this.$t('components.common.actions.Change') as string,
        cancelButtonText: this.$t('components.common.actions.Cancel') as string,
      }).then((result) => {
        if (result.isConfirmed) {
          Api.put(
            "/v2/client/colaboradores/changePin/" + this.userFromChangePassword,
            this.formChangePIN
          )
            .then((response) => {
              Snackbar.show(this.$t('components.colaboradores.template.CollaboratorPINChangedSuccessfully') as string);
              this.loadDatas();
              this.resetPin();
              this.dialogNewPin = false;
            })
            .catch((e: AxiosError) => {
              const response = e.response as AxiosResponse;
              const status = response.status;
              const data = response.data;

              if (status == 422) {
                this.errors = data.body.errors;
              } else {
                Swal.fire({
                  text: data.body.error,
                  icon: "error",
                  confirmButtonText: "Ok",
                  showConfirmButton: true,
                  customClass: {
                    confirmButton: "d-block",
                  },
                });
              }
            })
            .finally(() => {
              this.btnLoading = false;
            });
        }
      });
    },
    closeDialogNovoColaborador() {
      this.reset();
      this.dialogNovoColaborador = false;
    },
    edit(item: any) {
      this.formEdit = item;
      this.dialogEditColaborador = true;
    },
    reset() {
      const form = this.$refs.form as HTMLFormElement;
      form.reset();
    },
    resetPwd() {
      const form = this.$refs.formNewPassord as HTMLFormElement;
      form.reset();
    },
    resetPin() {
      const form = this.$refs.formNewPin as HTMLFormElement;
      form.reset();
    },
    closeDialogChangePassword() {
      this.resetPwd();
      this.dialogNewPassword = false;
    },
    closeDialogChangePin() {
      this.resetPin();
      this.dialogNewPin = false;
    },
    showLog(id: number) {
      Overlay.show();
      Api.get("/v2/client/colaboradores/log/" + id, {
        params: {
          startDate: this.startDate,
          endDate: this.endDate,
        },
      })
        .then((response) => {
          console.log(response);
        })
        .catch((error: any) => {
          const response = error.response as AxiosResponse;
          const status = response.status;
          const data = response.data;

          if (status === 422) {
            this.errors = data.body.errors;
          }
          // this.resetRecaptcha();
        })
        .finally(() => {
          Overlay.hide();
        });
    },
    bloquear(id: number) {
      Swal.fire({
        title: this.$t("components.common.swals.ConfirmationTitle") as string,
        text: this.$t("components.preCadastro.template.form.ConfirmBlockingColaborator") as string,
        icon: "question",
        iconColor: "#F44336",
        showCancelButton: true,
        confirmButtonColor: "#F44336",
        cancelButtonColor: "#757575",
        confirmButtonText: this.$t("components.common.actions.Block") as string,
        cancelButtonText: this.$t("components.common.actions.Cancel") as string,
      }).then((result) => {
        if (result.isConfirmed) {
          Api.put("/v2/client/colaboradores/block/" + id).then((response) => {
            Snackbar.show(this.$t("components.colaboradores.template.ColaboratorSuccessfullyBlocked") as string);
            this.loadDatas();
          });
        }
      });
    },
    desbloquear(id: number) {
      Swal.fire({
        title: this.$t('components.common.swals.ConfirmationTitle') as string,
        text: this.$t('components.colaboradores.template.ConfirmUnlockingSelectedCollaborator') as string,
        icon: "question",
        iconColor: "#F44336",
        showCancelButton: true,
        confirmButtonColor: "#F44336",
        cancelButtonColor: "#757575",
        confirmButtonText: this.$t('components.common.actions.Unlock') as string,
        cancelButtonText: this.$t('components.common.actions.Cancel') as string,
      }).then((result) => {
        if (result.isConfirmed) {
          Api.put("/v2/client/colaboradores/unblock/" + id).then((response) => {
            Snackbar.show(this.$t('components.colaboradores.template.CollaboratorSuccessfullyUnlocked') as string);
            this.loadDatas();
          });
        }
      });
    },
    loadDatas() {
      const { sortBy, sortDesc, page, itemsPerPage } = this.options;
      this.loadingDesserts = true;
      Api.get("/v2/client/colaboradores/index", {
        params: {
          q: this.search,
          page: page,
          limit: itemsPerPage,
          sort: sortBy.toString(),
          desc: sortDesc.toString(),
          status: this.selectedEntryStatusColaboradores.id,
        },
      })
        .then((response) => {
          this.desserts = response.data.body.rows as IbPessoasColaboradores[];
          this.configPermissoes = response.data.body.config;
          this.totalDesserts = Number(response.data.body.count.__total);
        })
        .catch((error: any) => {
          const response = error.response as AxiosResponse;
          const status = response.status;
          const data = response.data;

          if (status != 200) {
            if (status == 406) {
              this.moduloAtivo.ativo = false;
              this.moduloAtivo.mensagem = data.body.error;
            } else {
              window.location.replace("/");
            }
            console.log(this.moduloAtivo);
          }
          // this.resetRecaptcha();
        })
        .finally(() => {
          this.loadingDesserts = false;
        });
    },
    novoColaborador() {
      this.dialogNovoColaborador = true;
      this.$nextTick(() => {
        this.reset();
      });
    },
    post() {
      this.btnLoading = true;
      this.errors = [];

      Api.post("/v2/client/colaboradores/store", this.formNovo)
        .then((response: AxiosResponse<IbPessoasColaboradores>) => {
          if (response.status == 201) {
            Snackbar.show(this.$t("components.preCadastro.template.form.CollaboratorSucess") as string);
            this.loadDatas();
            this.reset();
            this.closeDialogNovoColaborador();
          }
        })
        .catch((e: AxiosError) => {
          const response = e.response as AxiosResponse;
          const status = response.status;
          const data = response.data;

          if (status == 422) {
            this.errors = data.body.errors;
          } else {
            Swal.fire({
              text: data.body.error,
              icon: "error",
              confirmButtonText: "Ok",
              showConfirmButton: true,
              customClass: {
                confirmButton: "d-block",
              },
            });
          }
        })
        .finally(() => {
          this.btnLoading = false;
        });
    },
    put() {
      this.btnLoading = true;
      this.errorsEdit = [];

      Api.put(
        "/v2/client/colaboradores/update/" + this.formEdit.id,
        this.formEdit
      )
        .then((response: AxiosResponse<IbPessoasColaboradores>) => {
          if (response.status == 200) {
            Snackbar.show(this.$t('components.colaboradores.template.CollaboratorSuccessfullyChanged') as string);
            this.loadDatas();
            this.dialogEditColaborador = false;
          }
        })
        .catch((e: AxiosError) => {
          const response = e.response as AxiosResponse;
          const status = response.status;
          const data = response.data;

          if (status == 422) {
            this.errors = data.body.errors;
          } else {
            Swal.fire({
              text: data.body.error,
              icon: "error",
              confirmButtonText: "Ok",
              showConfirmButton: true,
              customClass: {
                confirmButton: "d-block",
              },
            });
          }
        })
        .finally(() => {
          this.btnLoading = false;
        });
    },
    putPermissoes() {
      this.btnLoading = true;
      this.errorsEdit = [];
      Overlay.show();
      Api.put("/v2/client/colaboradores/permissoes/update", this.formPermissoes)
        .then((response: AxiosResponse<IbPessoasColaboradoresPermissoes>) => {
          if (response.status == 200) {
            Snackbar.show(this.$t('components.colaboradores.template.PermissionsSuccessfullyModified') as string);
            // this.loadDatas();

            this.dialogEditPermissoes = false;
          }
        })
        .catch((e: AxiosError) => {
          const response = e.response as AxiosResponse;
          const status = response.status;
          const data = response.data;

          if (status == 422) {
            this.errors = data.body.errors;
          } else {
            Swal.fire({
              text: data.body.error,
              icon: "error",
              confirmButtonText: "Ok",
              showConfirmButton: true,
              customClass: {
                confirmButton: "d-block",
              },
            });
          }
        })
        .finally(() => {
          Overlay.hide();
          this.btnLoading = false;
        });
    },
    permissoes(id: number, nome: string) {
      Overlay.show();
      Api.get("/v2/client/colaboradores/permissoes/show/" + id, {})
        .then((response) => {
          this.formPermissoes = response.data.body;
          this.$nextTick(() => {
            this.userFromChangePermissoes = nome;
            this.dialogEditPermissoes = true;
          });
        })
        .finally(() => {
          Overlay.hide();
        });
    },
    rowClasses(item: any) {
      const ToDate = new Date();
      if (item.status === "1") {
        return "";
      }
      if (item.status === "2") {
        return "error--text";
      }
    },
    confirmRemove(id: number) {
      // Use sweetalert2
      Swal.fire({
        title: this.$t('components.common.swals.ConfirmationTitle') as string,
        text: this.$t('components.colaboradores.template.ConfirmDeletionSelectedCollaborator') as string,
        icon: "question",
        iconColor: "#F44336",
        showCancelButton: true,
        confirmButtonColor: "#F44336",
        cancelButtonColor: "#757575",
        confirmButtonText: this.$t('components.common.actions.Confirm') as string,
        cancelButtonText: this.$t('components.common.actions.Cancel') as string,
      }).then((result) => {
        if (result.isConfirmed) {
          Api.delete("/v2/client/colaboradores/destroy/" + id).then(
            (response) => {
              Snackbar.show(this.$t('components.colaboradores.template.CollaboratorSuccessfullyRemoved') as string);
              this.loadDatas();
            }
          );
        }
      });
    },
    removeMFA(id: number) {
      Swal.fire({
        title: this.$t('components.common.swals.ConfirmationTitle') as string,
        text: this.$t('components.colaboradores.template.ConfirmRemoval2FAuthenticationSelectedCollaborator') as string,
        icon: "question",
        iconColor: "#F44336",
        showCancelButton: true,
        confirmButtonColor: "#F44336",
        cancelButtonColor: "#757575",
        confirmButtonText: this.$t('components.common.actions.Remove') as string,
        cancelButtonText: this.$t('components.common.actions.Cancel') as string,
      }).then((result) => {
        if (result.isConfirmed) {
          Api.put("/v2/client/colaboradores/removeMFA/" + id)
            .then((response) => {
              Snackbar.show(this.$t('components.colaboradores.template.2FAuthSuccessfullyRemoved') as string);
              this.loadDatas();
              this.resetPin();
              this.dialogNewPin = false;
            })
            .catch((e: AxiosError) => {
              const response = e.response as AxiosResponse;
              const status = response.status;
              const data = response.data;

              if (status == 409) {
                Snackbar.show(data.body.error, "error");
              }
            })
            .finally(() => {
              this.btnLoading = false;
            });
        }
      });
    },
  },
  watch: {
    '$i18n.locale'() {
      this.updateDocumentTitle();
      this.translateStatusColaboradores();
      this.updateHeaders();

    },
    options: {
      handler() {
        this.loadDatas();
      },
    },
    search: {
      handler() {
        this.doDelayedSearch();
      },
    },
  },
});
