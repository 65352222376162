
import Vue from "vue";
import Api from "@/services/Api";
import { AxiosResponse } from "axios";
import { Overlay } from "@/lib/Overlay";
import moment from "moment";
import { Snackbar } from "@/lib/Snackbar";
import Swal from "sweetalert2";


interface Breadcrumb {
  text: string;
  disabled: boolean;
  href: string;
}
export default Vue.extend({
  name: "homePix",

  data() {
    return {
      ModuloInfo: [],
      loadingData: true,

      lang: navigator.language,
      errors: [],
      moment: moment,
      isMobile: false,
      breadcrumbs: [] as Breadcrumb[],
    };
  },
  created() {
    this.isMobile = this.detectMobile();
    this.updateBreadcrumbs();
  },

  methods: {
    detectMobile() {
      if (screen.width <= 760) {
        return true;
      } else {
        return false;
      }
    },
    updateBreadcrumbs() {
      this.breadcrumbs = [
        {
          text: this.$t("components.preCadastro.template.form.Home") as string,
          disabled: false,
          href: "/",
        },
        {
          text: this.$t("components.preCadastro.template.form.PixArea") as string,
          disabled: true,
          href: "/pix",
        },
      ];
    },
  },
  watch: {
  '$i18n.locale'(newLocale: string) {
    this.updateBreadcrumbs();
  }
},
});
