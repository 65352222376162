
import Vue from "vue";
import Api from "@/services/Api";
import { AxiosResponse } from "axios";
import { Overlay } from "@/lib/Overlay";
import "month-navigation-vue/dist/month-navigation-vue.css";

import moment from "moment";

import MonthNavigation from "month-navigation-vue";
export default Vue.extend({
  name: "ResumoPixHome",

  data() {
    return {
      ModuloInfo: [],
      loadingData: true,
      monthNavigation: {
        value: null,

        months: [
          this.$t("components.resumoPix.template.incomeResume.january"),
          this.$t("components.resumoPix.template.incomeResume.february"),
          this.$t("components.resumoPix.template.incomeResume.march"),
          this.$t("components.resumoPix.template.incomeResume.april"),
          this.$t("components.resumoPix.template.incomeResume.may"),
          this.$t("components.resumoPix.template.incomeResume.june"),
          this.$t("components.resumoPix.template.incomeResume.july"),
          this.$t("components.resumoPix.template.incomeResume.august"),
          this.$t("components.resumoPix.template.incomeResume.september"),
          this.$t("components.resumoPix.template.incomeResume.october"),
          this.$t("components.resumoPix.template.incomeResume.november"),
          this.$t("components.resumoPix.template.incomeResume.december")
        ],
      },
      lang: navigator.language,
      errors: [],
      moment: moment,

      isMobile: false,
      startOfMonth: moment().startOf("month").format("YYYY-MM-DD"),
      endOfMonth: moment().endOf("month").format("YYYY-MM-DD"),
      resumoPixData: {
        fatEsperado: "0,00",
        emitidas: 0,
        pagas: 0,
        fatReal: "0,00",
        conversao: "0",
        ticket: "0,00",
      },
    };
  },
  created() {
    this.isMobile = this.detectMobile();
    setTimeout(() => {
      this.getResumoPix();
    }, 1000);
  },
  components: {
    MonthNavigation,
  },

  methods: {
    onChangeMonthNavigation(value: any) {
      this.monthNavigation.value = value;
      this.startOfMonth = moment(value).startOf("month").format("YYYY-MM-DD");
      this.endOfMonth = moment(value).endOf("month").format("YYYY-MM-DD");
      this.getResumoPix();
    },
    detectMobile() {
      if (screen.width <= 760) {
        return true;
      } else {
        return false;
      }
    },
    getResumoPix() {
      Overlay.show();

      this.loadingData = true;
      Api.get("/v2/client/dashboard/resumoPix", {
        params: {
          startDate: this.startOfMonth,
          endDate: this.endOfMonth,
        },
      })
        .then((response) => {
          this.resumoPixData = response.data.body;
          this.loadingData = false;
        })
        .catch((error: any) => {
          const response = error.response as AxiosResponse;
          const status = response.status;
          const data = response.data;

          if (status != 200) {
            //exe
          }
          // this.resetRecaptcha();
        })
        .finally(() => {
          Overlay.hide();
        });
    },
  },
});
