
import Vue from "vue";
import Auth from "@/lib/Auth";
import Api from "@/services/Api";
import { AxiosResponse } from "axios";
import { Alert } from "@/lib/Alert";
import MenuAcessoRapido from "@/components/Dashboard/MenuAcessoRapido.vue";
//import MovimentoRecente from "@/components/Dashboard/MovimentoRecente.vue";
//import Charts from "@/components/Charts.vue";
import ResumoPixHome from "@/components/Dashboard/ResumoPix.vue";

import DialogNotification from "@/components/Notifications/dialogRequest.vue";
import CreatePin from "@/models/Pin.vue";
import moment from "moment";
import { Overlay } from "@/lib/Overlay";
import Swal from "sweetalert2";
import VueMask from "v-mask";
import { Snackbar } from "@/lib/Snackbar";
Vue.use(VueMask);

export default Vue.extend({
  name: "Dashboard",

  data: () => ({
    showSaldo: false,
    dialogPOPUPImage: false,
    hasInfoSituacaoConta: false,
    informativoData: {} as any,
    informativos: []as any,
    informativosCount: 0,
    dialogInformativos: false,
    editFone: true,
    showpass: false,
    btnCreatePinLoading: false,
    formpin: {} as CreatePin,
    dialogCreatePin: false,
    dialogValidaFone: false,
    lang: navigator.language,
    moment: moment,
    alert: {} as Alert,
    dashboardData: {} as any,
    loadedData: false,
    dadosUsuario: { telefone: "" },
    errors: [] as any,
    validCreatePin: true,
    valid: true,
    situacaoConta: {} as any,
    stepValidacaoFone: 1,
    isMobile: false,
    pinValidaFone: "",
    mfaEnabled: true,
  }),
  components: {
    MenuAcessoRapido,
    // MovimentoRecente,
    ResumoPixHome,
    DialogNotification,
    // Charts,
  },
  created() {
    this.isMobile = this.detectMobile();
    this.getDashdoard();
    this.dadosUsuario = Auth.getUserData();
  },
  methods: {
    detectMobile() {
      if (screen.width <= 760) {
        return true;
      } else {
        return false;
      }
    },
    validaEmail() {
      Swal.fire({
        title: "Validar e-mail",
        text: "Será enviado um e-mail contendo um link para validação.",
        icon: "question",
        confirmButtonText: "Continuar",
        cancelButtonText: "Cancelar",
        showCancelButton: true,
      }).then((result) => {
        Api.get("v2/client/validaremail/sendcode")
          .then((response) => {
            //
            Snackbar.show(response.data.body.message, "success");
          })
          .catch((error: any) => {
            const response = error.response as AxiosResponse;
            const status = response.status;
            const data = response.data;

            if (status === 422) {
              this.errors = data.body.errors;
            } else {
              Swal.fire({
                text: data.body.error,
                icon: "error",
                confirmButtonText: "Ok",
                showConfirmButton: true,
                customClass: {
                  confirmButton: "d-block",
                },
              });
            }
          })
          .finally(() => {
            Overlay.hide();
          });
      });
    },
    definirPIN() {
      this.btnCreatePinLoading = true;
      this.showAlert();
      Overlay.show();
      Api.post("/v2/client/profile/createPIN", this.formpin)
        .then((response) => {
          this.loadedData = true;
          this.getSituacaoConta();
          this.btnCreatePinLoading = false;

          this.dialogCreatePin = false;

          Swal.fire({
            text: response.data.body.message,
            icon: "success",
            confirmButtonText: "Ok",
            showConfirmButton: true,
            customClass: {
              confirmButton: "d-block",
            },
          });
        })
        .catch((error: any) => {
          const response = error.response as AxiosResponse;
          const status = response.status;
          const data = response.data;
          if (status === 422) {
            this.errors = data.body.errors;
          } else {
            this.showAlert(true, data.body.message);
          }

          this.btnCreatePinLoading = false;
        })
        .finally(() => {
          Overlay.hide();
          this.btnCreatePinLoading = false;
        });
    },
    validaFoneCodigo() {
      Overlay.show();
      Api.post("/v2/client/validartelefone/validate", {
        codigo: this.pinValidaFone,
      })
        .then((response) => {
          Swal.fire({
            text: response.data.body.message,
            icon: "success",
            confirmButtonText: "Ok",
            showConfirmButton: true,
            customClass: {
              confirmButton: "d-block",
            },
          });
          this.dialogValidaFone = false;
          this.getSituacaoConta();
        })
        .catch((error: any) => {
          const response = error.response as AxiosResponse;
          const status = response.status;
          const data = response.data;

          if (status === 422) {
            this.errors = data.body.errors;
          } else {
            Swal.fire({
              text: data.body.error,
              icon: "error",
              confirmButtonText: "Ok",
              showConfirmButton: true,
              customClass: {
                confirmButton: "d-block",
              },
            });

            if (status === 409) {
              this.stepValidacaoFone = 2;
            }
          }
        })
        .finally(() => {
          Overlay.hide();
        });
    },
    enviaCodigoValidaFone() {
      Overlay.show();
      Api.post("/v2/client/validartelefone/sendcode", {
        telefone: this.dadosUsuario.telefone,
      })
        .then((response) => {
          this.stepValidacaoFone = 2;
        })
        .catch((error: any) => {
          const response = error.response as AxiosResponse;
          const status = response.status;
          const data = response.data;

          if (status === 422) {
            this.errors = data.body.errors;
          } else {
            Swal.fire({
              text: data.body.error,
              icon: "error",
              confirmButtonText: "Ok",
              showConfirmButton: true,
              customClass: {
                confirmButton: "d-block",
              },
            });

            if (status === 409) {
              this.stepValidacaoFone = 2;
            }
          }
        })
        .finally(() => {
          Overlay.hide();
        });
    },
    getSituacaoConta() {
      Overlay.show();
      Api.get("/v2/client/dashboard/statusConta")
        .then((response) => {
          this.situacaoConta = response.data.body;
          this.hasInfoSituacaoConta = true;
          this.loadedData = true;
        })
        .catch((error: any) => {
          const response = error.response as AxiosResponse;
          const status = response.status;
          const data = response.data;
        })
        .finally(() => {
          Overlay.hide();
        });
    },
    openInformativo(idx: number) {
      this.informativoData = this.informativos[idx];
      this.dialogInformativos = true;
    },
    getDashdoard() {
      Overlay.show();
      Api.get("/v2/client/dashboard")
        .then((response) => {
          this.dashboardData = response.data.body;
          this.loadedData = true;
          this.informativos = response.data.body.informativos.rows;
          this.informativosCount = response.data.body.informativos.count;
          if (response.data.body.informativosDialogs.count > 0) {
            this.informativoData =
              response.data.body.informativosDialogs.rows[0];
            this.dialogInformativos = true;
          }
          this.getSituacaoConta();
        })
        .catch((error: any) => {
          const response = error.response as AxiosResponse;
          const status = response.status;
          const data = response.data;
        })
        .finally(() => {
          Overlay.hide();
        });
    },

    formataValor(data: number) {
      const valor = new Intl.NumberFormat("pt-BR", {
        style: "currency",
        currency: "BRL",
      }).format(data);

      return valor.toString().replace("R$", "").trim();
    },
    showAlert(show = false, message = "", type = "error"): void {
      this.alert = {
        show,
        type,
        message,
      };
    },
  },
});
