
import Vue from "vue";
import Api from "@/services/Api";
import Auth from "@/lib/Auth";
import { AxiosResponse } from "axios";
import { Overlay } from "@/lib/Overlay";
import moment from "moment";
import { Snackbar } from "@/lib/Snackbar";
import Swal from "sweetalert2";

export interface ChangePinCard {
  codeRequest?: string;
  oldPinblock?: string;
  pinblock?: number;
  confirmationPinblock?: string;
  transactionPin?: string;
}
export interface ResetPinCard {
  codeRequest?: string;

  transactionPin?: string;
}

export default Vue.extend({
  name: "Cartao",

  data() {
    return {
      valid: true,
      formChangePinCard: {} as ChangePinCard,
      formResetPinCard: {} as ResetPinCard,
      dialogCardSenha: false,
      dialogCardSenhaReset: false,
      dialogCardSenhaChange: false,
      codeRequest: "",
      dialogCardRequestPIN: false,
      pinCodeRequestCard: "",
      pinCodeTransactions: "",
      btnLoading: false,
      lastFourCardDigits: "",
      dialogComprovanteCartao: false,
      dialoagActivateCard: false,
      activateCardStep: 1,
      dialogCardInfo: false,
      dialogRastreio: false,
      dialogRequestCard: false,
      dialogBlockCard: false,
      dialogUnBlockCard: false,
      panel: 0,
      ModuloInfo: [],
      loadingData: true,
      lang: navigator.language,
      errors: [],
      moment: moment,
      isMobile: false,
      contaPermiteCartao: {},
      imgCartao: "",
      movimentacaoCartao: [],
      dadosPreRequestUpdate: [],
      dadosAusentesNecessarios: [],
      cartoes: [],
      dadosUsuario: {},
      tarifasLimites: [],
      ckTarifa: false,
      ckTermos: false,
      dadosComprovanteCartao: [],
      dadosRastreio: {
        correios: {
          hasARCorreios: false,
          ARCorreiosID: "",
        },
        ocorrencias: {
          rows: [],
          count: 0,
        },
      },

      headersMovimentacao: [
        {
          text: "",
          value: "icone",
          sortable: false,
          align: "center",
          width: "1%",
        },
        { text: "", value: "tipo", sortable: false, width: "55%" },
        {
          text: "Data",
          value: "data",
          sortable: false,
          align: "right",
          width: "30%",
        },

        {
          text: "Valor",
          value: "valorFormatado",
          sortable: false,
          align: "right",
          width: "10%",
        },
      ],

      motivos: [
        { id: 0, motivo: "Selecione" },
        { id: "03", motivo: "Perdi meu cartão" },
        { id: "04", motivo: "Roubaram meu cartão" },
      ],
      motivoBloqueio: { id: 0, motivo: "Selecione" },
    };
  },
  created() {
    this.dadosUsuario = Auth.getUserData();
    this.isMobile = this.detectMobile();

    this.moduloInfo();
  },

  methods: {
    resetPinCartao(id: string) {
      this.formResetPinCard.codeRequest = id;
      this.dialogCardSenhaReset = false;
      this.errors = [];
      this.btnLoading = true;
      Overlay.show();
      Api.post("/v2/client/cartao/resetPin", this.formResetPinCard)
        .then((response) => {
          Swal.fire({
            text: response.data.body.message,
            icon: "success",
            confirmButtonText: "Ok",
            showConfirmButton: true,
            customClass: {
              confirmButton: "d-block",
            },
          });

          this.moduloInfo();
        })
        .catch((error: any) => {
          const response = error.response as AxiosResponse;
          const status = response.status;
          const data = response.data;

          Swal.fire({
            text: data.body.error,
            icon: "error",
            confirmButtonText: "Ok",
            showConfirmButton: true,
            customClass: {
              confirmButton: "d-block",
            },
          });
        })
        .finally(() => {
          this.dialogCardSenhaChange = false;

          this.btnLoading = false;

          Overlay.hide();
        });
    },
    alterarSenhaCartao(id: string) {
      this.formChangePinCard.codeRequest = id;
      this.dialogCardSenhaChange = false;
      this.errors = [];
      this.btnLoading = true;
      Overlay.show();
      Api.post("/v2/client/cartao/changePin", this.formChangePinCard)
        .then((response) => {
          Swal.fire({
            text: response.data.body.message,
            icon: "success",
            confirmButtonText: "Ok",
            showConfirmButton: true,
            customClass: {
              confirmButton: "d-block",
            },
          });

          this.moduloInfo();
        })
        .catch((error: any) => {
          const response = error.response as AxiosResponse;
          const status = response.status;
          const data = response.data;

          Swal.fire({
            text: data.body.error,
            icon: "error",
            confirmButtonText: "Ok",
            showConfirmButton: true,
            customClass: {
              confirmButton: "d-block",
            },
          });
        })
        .finally(() => {
          this.dialogCardSenhaChange = false;

          this.btnLoading = false;

          Overlay.hide();
        });
    },
    initialChangeCardSenha() {
      this.dialogCardSenha = false;
      this.dialogCardSenhaChange = true;
    },
    initialCardReset() {
      this.dialogCardSenha = false;
      this.dialogCardSenhaReset = true;
    },
    closeDialogPinRequestCard() {
      this.pinCodeRequestCard = "";
      this.dialogCardRequestPIN = false;
      this.dialogRequestCard = true;
    },
    focusPinRequestCard() {
      this.pinCodeRequestCard = "";
      this.$nextTick(() => {
        const wrapper = this.$refs.pincodeCardRequest as any;
        const cells = wrapper.$el.getElementsByClassName(
          "vue-pincode-input"
        ) as any;

        window.setTimeout(() => cells[0]?.focus(), 0);
      });
    },
    detectMobile() {
      if (screen.width <= 760) {
        return true;
      } else {
        return false;
      }
    },
    moduloInfo() {
      Overlay.show();

      Api.get("/v2/client/cartao/info")
        .then((response) => {
          this.ModuloInfo = response.data.body.moduloInfo;
          this.imgCartao = response.data.body.imagemCartao;
          this.contaPermiteCartao = response.data.body.contaPermiteCartao;
          this.cartoes = response.data.body.cartoes;
          this.movimentacaoCartao = response.data.body.movimentacaoRecente;
          this.tarifasLimites = response.data.body.tarifasLimites;
          this.dadosPreRequestUpdate = response.data.body.dadosPreRequestUpdate;
          this.dadosAusentesNecessarios =
            response.data.body.dadosAusentesNecessarios;
        })
        .catch((error: any) => {
          const response = error.response as AxiosResponse;
          const status = response.status;
          const data = response.data;

          if (status != 200) {
            //exe
          }
          // this.resetRecaptcha();
        })
        .finally(() => {
          this.loadingData = false;
          Overlay.hide();
        });
    },
    Comprovante(id: string) {
      Overlay.show();
      Api.get("/v2/public/comprovantes/cartao/" + id)
        .then((response) => {
          this.dadosComprovanteCartao = response.data.body;
          this.dialogComprovanteCartao = true;
        })
        .catch((error: any) => {
          const response = error.response as AxiosResponse;
          const status = response.status;
          const data = response.data;
        })
        .finally(() => {
          Overlay.hide();
        });
    },

    RastreioEntrega(id: string) {
      const id2 = "0000641974";
      Overlay.show();
      Api.get("/v2/client/cartao/rastreio/" + id2)
        .then((response) => {
          this.dadosRastreio = response.data.body;
          this.dialogRastreio = true;
        })
        .catch((error: any) => {
          const response = error.response as AxiosResponse;
          const status = response.status;
          const data = response.data;
        })
        .finally(() => {
          Overlay.hide();
        });
    },

    OpenModalRequestCardPIN() {
      if (!this.ckTarifa) {
        Swal.fire({
          text: "Você precisa marcar a opção de aceitação de cobrança.",
          icon: "error",
          confirmButtonText: "Ok",
          showConfirmButton: true,
          customClass: {
            confirmButton: "d-block",
          },
        });
        return false;
      }
      if (!this.ckTermos) {
        Swal.fire({
          text: "Você precisa marcar a opção de aceitação dos termos de uso.",
          icon: "error",
          confirmButtonText: "Ok",
          showConfirmButton: true,
          customClass: {
            confirmButton: "d-block",
          },
        });
        return false;
      }
      this.pinCodeRequestCard = "";
      this.dialogRequestCard = false;
      this.dialogCardRequestPIN = true;
      this.$nextTick(() => {
        this.focusPinRequestCard();
      });
    },
    SolicitarCartao() {
      if (this.pinCodeRequestCard.length != 6) {
        this.focusPinRequestCard();
        Snackbar.show("Informe seu PIN", "error");
        return false;
      }

      Swal.fire({
        icon: "question",
        title: "Confirmação",
        text: "Confirma a solicitação de seu cartão EzzeBank?",
        showCancelButton: false,
        confirmButtonText: "ok",
        allowOutsideClick: true,
      }).then((result) => {
        if (result.isConfirmed) {
          this.dialogCardRequestPIN = false;
          this.errors = [];
          this.btnLoading = true;
          Overlay.show();
          Api.post("/v2/client/cartao/cardRequest", {
            transactionPin: this.pinCodeRequestCard,
          })
            .then((response) => {
              Swal.fire({
                text: response.data.body.message,
                icon: "success",
                confirmButtonText: "Ok",
                showConfirmButton: true,
                customClass: {
                  confirmButton: "d-block",
                },
              });

              this.moduloInfo();
            })
            .catch((error: any) => {
              const response = error.response as AxiosResponse;
              const status = response.status;
              const data = response.data;

              Swal.fire({
                text: data.body.error,
                icon: "error",
                confirmButtonText: "Ok",
                showConfirmButton: true,
                customClass: {
                  confirmButton: "d-block",
                },
              }).then((result) => {
                if (result.isConfirmed) {
                  this.focusPinRequestCard();
                }
              });
            })
            .finally(() => {
              this.dialogCardRequestPIN = false;
              this.dialogRequestCard = false;
              this.pinCodeRequestCard = "";
              this.ckTarifa = false;
              this.ckTermos = false;
              this.btnLoading = false;

              Overlay.hide();
            });
        }
      });
    },

    openModalActivateCard(id: string) {
      Swal.fire({
        icon: "question",
        title: "Já recebeu seu cartão?",
        text: "Só ative seu cartão, se estiver com ele em mãos!",
        showCancelButton: true,
        confirmButtonText: "Recebi meu Cartão",
        cancelButtonText: "Ainda não recebi",
      }).then((result) => {
        if (result.isConfirmed) {
          this.codeRequest = id;
          this.pinCodeTransactions = "";
          this.lastFourCardDigits = "";
          this.dialoagActivateCard = true;
          this.$nextTick(() => {
            const wrapper = this.$refs.lastFourCardDigitsTxt as any;

            window.setTimeout(() => wrapper?.focus(), 0);
          });
        }
      });
    },
    ativarCartao() {
      if (this.lastFourCardDigits.length != 4) {
        Snackbar.show("Informe os últimos 4 digitos do seu cartão.");
        return false;
      }

      if (this.pinCodeTransactions.length != 6) {
        Snackbar.show("Informe seu PIN de transações");
        return false;
      }

      this.dialoagActivateCard = false;
      Overlay.show();

      Api.post("/v2/client/cartao/activate", {
        transactionPin: this.pinCodeTransactions,
        codeRequest: this.codeRequest,
        lastFourCardDigits: this.lastFourCardDigits,
      })
        .then((response) => {
          this.pinCodeTransactions = "";

          Swal.fire({
            text: response.data.body.message,
            icon: "success",
            confirmButtonText: "Ok",
            showConfirmButton: true,
            customClass: {
              confirmButton: "d-block",
            },
          });

          this.moduloInfo();
        })
        .catch((error: any) => {
          const response = error.response as AxiosResponse;
          const status = response.status;
          const data = response.data;
          this.dialoagActivateCard = true;

          Swal.fire({
            text: data.body.error,
            icon: "error",
            confirmButtonText: "Ok",
            showConfirmButton: true,
            customClass: {
              confirmButton: "d-block",
            },
          });
        })
        .finally(() => {
          Overlay.hide();
        });
    },

    bloquearCartao(id: string) {
      if (this.motivoBloqueio.id == 0) {
        Snackbar.show("Selecione o motivo", "error");
        return false;
      }
      if (this.pinCodeTransactions.length != 6) {
        Snackbar.show("Informe seu PIN de transações");
        return false;
      }

      Swal.fire({
        icon: "question",
        title: "Confirmação",
        text: "Confirma o bloqueio de seu cartão EzzeBank?",
        showCancelButton: true,
        confirmButtonText: "Bloquear",
        cancelButtonText: "Cancelar",
      }).then((result) => {
        if (result.isConfirmed) {
          this.dialogBlockCard = false;
          this.errors = [];
          this.btnLoading = true;
          Overlay.show();
          Api.post("/v2/client/cartao/blockCard", {
            transactionPin: this.pinCodeTransactions,
            blockEvent: this.motivoBloqueio.id,
            codeRequest: id,
          })
            .then((response) => {
              this.pinCodeTransactions = "";
              Swal.fire({
                text: response.data.body.message,
                icon: "success",
                confirmButtonText: "Ok",
                showConfirmButton: true,
                customClass: {
                  confirmButton: "d-block",
                },
              });

              this.moduloInfo();
            })
            .catch((error: any) => {
              const response = error.response as AxiosResponse;
              const status = response.status;
              const data = response.data;
              this.dialogBlockCard = true;
              Swal.fire({
                text: data.body.error,
                icon: "error",
                confirmButtonText: "Ok",
                showConfirmButton: true,
                customClass: {
                  confirmButton: "d-block",
                },
              });
            })
            .finally(() => {
              this.btnLoading = false;

              Overlay.hide();
            });
        }
      });
    },

    desbloquearCartao(id: string) {
      if (this.pinCodeTransactions.length != 6) {
        Snackbar.show("Informe seu PIN de transações");
        return false;
      }
      Swal.fire({
        icon: "question",
        title: "Confirmação",
        text: "Confirma o desbloqueio de seu cartão EzzeBank?",
        showCancelButton: true,
        confirmButtonText: "Desloquear",
        cancelButtonText: "Cancelar",
      }).then((result) => {
        if (result.isConfirmed) {
          this.dialogUnBlockCard = false;
          this.errors = [];
          this.btnLoading = true;
          Overlay.show();
          Api.post("/v2/client/cartao/unlockCard", {
            transactionPin: this.pinCodeTransactions,
            codeRequest: id,
          })
            .then((response) => {
              this.pinCodeTransactions = "";
              Swal.fire({
                text: response.data.body.message,
                icon: "success",
                confirmButtonText: "Ok",
                showConfirmButton: true,
                customClass: {
                  confirmButton: "d-block",
                },
              });

              this.moduloInfo();
            })
            .catch((error: any) => {
              const response = error.response as AxiosResponse;
              const status = response.status;
              const data = response.data;
              this.dialogUnBlockCard = true;
              Swal.fire({
                text: data.body.error,
                icon: "error",
                confirmButtonText: "Ok",
                showConfirmButton: true,
                customClass: {
                  confirmButton: "d-block",
                },
              });
            })
            .finally(() => {
              this.btnLoading = false;

              Overlay.hide();
            });
        }
      });
    },

    cancelaAtivacaoCartao() {
      this.pinCodeTransactions = "";
      this.lastFourCardDigits = "";
      this.dialoagActivateCard = false;
    },
  },
});
