
import Vue from "vue";
import Api from "@/services/Api";
import { AxiosResponse } from "axios";
import { Overlay } from "@/lib/Overlay";
import { Login } from "@/models/Login";
import VueRecaptcha from "vue-recaptcha";
import moment from "moment";
import { Snackbar } from "@/lib/Snackbar";
import Swal from "sweetalert2";

export interface Alert {
  type?: "success" | "info" | "warning" | "error";
  message?: string;
  show: boolean;
}
export interface Transferencias {
  idSolicitacao: number;
  transactionPin: string;
}
export default Vue.extend({
  name: "CheckoutTransferApi",

  data() {
    return {
      pinCode: "",
      showpass: false,
      valid: true,
      dialog2FALogin: false,
      form: {} as Login,
      uriLogin: "/v2/client/auth",
      btnLoading: false,
      dialogLogin: false,
      dialogPin: false,
      loadingData: true,
      hastransacao: false,
      alert: {} as Alert,
      formTransferencia: {} as Transferencias,
      lang: navigator.language,
      errors: [],
      moment: moment,
      dataTransfer: {
        id: "0",
        token: "",
        referencia: "",
        valor: "",
        valorFormatado: "0,00",
        status: "",
        urlSuccess: "",
        urlFail: "",
        expires: "",
        creditParty: {
          id: "1",
          avatar: "",
          nome: "",
          documento: "",
        },
      },
      tokenUser: "",
      isMobile: false,
      sitekey: process.env.VUE_APP_RECAPTCHA_SITE_KEY,
      isPendente: true,
    };
  },
  components: {
    "vue-recaptcha": VueRecaptcha,
  },
  created() {
    this.isMobile = this.detectMobile();
    this.getTransferInfo();
  },

  methods: {
    cancelaTransfer() {
      this.dialogPin = false;
      this.form.login = "";
      this.form.password = "";
      this.pinCode = "";
      this.btnLoading = false;
      this.loadingData = false;
    },
    focusPin() {
      this.$nextTick(() => {
        const wrapper = this.$refs.pincode as any;
        const cells = wrapper.$el.getElementsByClassName(
          "vue-pincode-input"
        ) as any;

        window.setTimeout(() => wrapper?.focus(), 0);
      });
    },
    transfere() {
      if (this.pinCode.length != 6) {
        this.focusPin();
        Snackbar.show("Informe seu PIN", "error");
        return false;
      }
      this.dialogPin = false;
      this.btnLoading = true;
      Overlay.show();

      this.formTransferencia.transactionPin = this.pinCode;
      Api.post(
        "v2/public/checkout/transferencias/transferir/" + this.dataTransfer.id,
        this.formTransferencia
      )
        .then((response) => {
          sessionStorage.removeItem("token");
          Swal.fire({
            title: "Transferência realizada com sucesso!",
            text: "Clique em Ok para voltar ao site do parceiro. Não feche a janela.",
            icon: "success",
            showCancelButton: false,
            confirmButtonColor: "#3085d6",

            confirmButtonText: "Ok",
          }).then((result) => {
            window.location.replace(this.dataTransfer.urlSuccess);
          });
          this.pinCode = "";
          this.getTransferInfo();
        })
        .catch((error: any) => {
          const response = error.response as AxiosResponse;
          const status = response.status;
          const data = response.data;
          this.pinCode = "";

          Swal.fire({
            text: data.body.error,
            icon: "error",
            confirmButtonText: "Ok",
            showConfirmButton: true,
            customClass: {
              confirmButton: "d-block",
            },
          });
          this.$nextTick(() => {
            this.dialogPin = true;
          });
        })

        .finally(() => {
          this.btnLoading = false;

          Overlay.hide();
        });
    },
    detectMobile() {
      if (screen.width <= 760) {
        return true;
      } else {
        return false;
      }
    },
    getTransferInfo() {
      const id = this.$route.params.id;
      Overlay.show();
      this.errors = [];
      this.alert = {
        show: false,
      };
      this.loadingData = true;
      Api.get("/v2/public/checkout/transferencias/" + id)
        .then((response) => {
          this.dataTransfer = response.data.body;
          this.formTransferencia.idSolicitacao = Number(response.data.body.id);
          this.hastransacao = true;
        })
        .catch((error: any) => {
          const response = error.response as AxiosResponse;
          const status = response.status;
          const data = response.data;

          if (status == 406) {
            this.isPendente = false;
            this.dataTransfer.urlSuccess = response.data.body.urlSuccess;
          } else {
            this.alert = {
              show: true,
              type: "error",
              message: data.body.error,
            };
          }
          // this.resetRecaptcha();
        })
        .finally(() => {
          this.loadingData = false;
          Overlay.hide();
        });
    },
    doLogin(token: string): void {
      this.errors = [];
      this.btnLoading = true;

      Overlay.show();

      Api.post(this.uriLogin, this.form, {
        headers: {
          recaptcha: token,
        },
      })
        .then(
          (response) => {
            if (!response.data.body.device_info.hasDispositivoDB) {
              this.tokenUser = atob(response.data.body.token);
            } else {
              this.tokenUser = response.data.body.token;
            }
            this.dialogPin = true;
            this.dialogLogin = false;
            this.dialog2FALogin = false;
            sessionStorage.setItem("token", this.tokenUser);
          }
          //  Auth.login(response.data.token);
        )
        .catch((error: any) => {
          const response = error.response as AxiosResponse;
          const status = response.status;
          const data = response.data;
          this.form.code = "";
          if (status === 422) {
            this.errors = data.body.errors;
          } else if (status === 302) {
            this.$nextTick(() => {
              const wrapper = this.$refs.code as any;

              window.setTimeout(() => wrapper?.focus(), 0);
            });
            this.uriLogin = "/v2/client/auth/2fa";
            this.dialog2FALogin = true;
          } else {
            Swal.fire({
              text: data.body.error,
              icon: "error",
              confirmButtonText: "Ok",
              showConfirmButton: true,
              customClass: {
                confirmButton: "d-block",
              },
            });
          }
          // this.resetRecaptcha();
        })
        .finally(() => {
          this.btnLoading = false;
          //  console.log("finalizou");

          Overlay.hide();
          this.resetRecaptcha();
        });
    },
    onSubmit: function () {
      //this.windowOverlay = true;

      (this.$refs.invisibleRecaptcha as HTMLFormElement).execute();
    },
    onVerify: function (response: string) {
      this.doLogin(response);
    },
    onExpired: function () {
      this.resetRecaptcha();
    },
    resetRecaptcha() {
      (this.$refs.invisibleRecaptcha as HTMLFormElement).reset(); // Direct call reset method
    },
    cancela2FA() {
      this.uriLogin = "/v2/client/auth";
      this.form.code = "";
      this.dialog2FALogin = false;
      this.form.password = "";
    },
  },
});
