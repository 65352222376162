
import Vue from "vue";

import Api from "@/services/Api";
import { FiltrosCobrancas } from "@/models/FiltrosCobrancas";
import { TableOptions } from "@/models/TableOptions";
import { Snackbar } from "@/lib/Snackbar";
import Axios, { AxiosResponse } from "axios";
import moment from "moment";
import Swal from "sweetalert2";
import { Overlay } from "@/lib/Overlay";

export interface IbCobrancas {
  createdAt?: string;
  transactionId?: string;
  externalReference?: string;
  billingType?: string;
  value?: number;
  dueDate?: string;
  description?: string;
  installmentCount?: number;
  installmentValue?: number;
  installmentNumber?: number;
  paymentDate?: string;
  status?: string;
}

export interface TableHeader {
  text: string;
  value: string;
  sortable: boolean;
  align?: string;
}

export default Vue.extend({
  data() {
    return {
      valid: true,
      dialogFiltros: false,
      lang: navigator.language,
      moment: moment,
      search: "",
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
      options: {} as TableOptions,
      filtrosCobrancas: {} as FiltrosCobrancas,
      chargeTypeList: "",
      menucreatedOnStart: false,
      menucreatedOnEnd: false,
      menudueDateStart: false,
      menudueDateEnd: false,
      menupaymentDateStart: false,
      menupaymentDateEnd: false,
      headers: [] as TableHeader[],
      desserts: new Array<IbCobrancas>(),
      loadingDesserts: false,
      totalDesserts: 0,
      timeout: 0,
      dueDateNew: "",
    };
  },
  computed: {
    translatedHeaders() {
      return [
        { text: this.$t('components.preCadastro.template.form.Status') as string, value: 'status', sortable: false, align: "center" },
        { text: this.$t('components.preCadastro.template.form.Form') as string, value: 'billingType', sortable: false, align: "center" },
        { text: this.$t('components.preCadastro.template.form.Description') as string, value: 'description', sortable: false },
        { text: this.$t('components.preCadastro.template.form.Value') as string, value: 'value', sortable: true, align: "right" },
        { text: this.$t('components.preCadastro.template.form.Issue') as string, value: 'createdAt', sortable: true },
        { text: this.$t('components.preCadastro.template.form.DueDate') as string, value: 'dueDate', sortable: true },
        { text: this.$t('components.preCadastro.template.form.Paymentdate') as string, value: 'paymentDate', sortable: true },
        { text: this.$t('components.preCadastro.template.form.Actions') as string, value: 'id', sortable: false },
      ];
    },
    footerProps() {
      return {
        itemsPerPageOptions: [20, 30, 50, 100],
        itemsPerPageText: this.$t('components.preCadastro.template.form.ItemsPerPage'),
      };
    }
  },
  methods: {
    updateHeaders() {
      this.headers = [
        { text: this.$t("components.preCadastro.template.form.Status") as string, value: "status", sortable: false, align: "center" },
        { text: this.$t("components.preCadastro.template.form.Form") as string, value: "billingType", sortable: false, align: "center" },
        { text: this.$t("components.preCadastro.template.form.Description") as string, value: "description", sortable: false },
        { text: this.$t("components.preCadastro.template.form.Value") as string, value: "value", sortable: true, align: "right" },
        { text: this.$t("components.preCadastro.template.form.Issue") as string, value: "createdAt", sortable: true },
        { text: this.$t("components.preCadastro.template.form.DueDate") as string, value: "dueDate", sortable: true },
        { text: this.$t("components.preCadastro.template.form.Paymentdate") as string, value: "paymentDate", sortable: true },
        { text: this.$t("components.preCadastro.template.form.Actions") as string, value: "id", sortable: false }
      ];
    },
    resetFormFilters() {
      const refForm = this.$refs.formFilters as any;
      refForm?.reset();

      this.loadDatas();
    },
    FilterBasic(param: string) {
      switch (param) {
        case "ARCHIVED":
          this.filtrosCobrancas.showArchived = true;
          this.filtrosCobrancas.chargeType = "";
          this.filtrosCobrancas.showRecurrent = false;
          break;
        case "ALL":
          this.filtrosCobrancas.showArchived = false;
          this.filtrosCobrancas.showPaid = false;
          this.filtrosCobrancas.chargeType = "";
          this.filtrosCobrancas.showRecurrent = false;

          break;
        case "HYBRID":
          this.filtrosCobrancas.chargeType = "HYBRID";
          this.filtrosCobrancas.showArchived = false;
          this.filtrosCobrancas.showRecurrent = false;

          break;
        case "UNIQUE":
          this.filtrosCobrancas.chargeType = "BANKSPLIP";
          this.filtrosCobrancas.showArchived = false;
          this.filtrosCobrancas.showRecurrent = false;

          break;
        case "CARNET":
          this.filtrosCobrancas.chargeType = "CARNET";
          this.filtrosCobrancas.showArchived = false;
          this.filtrosCobrancas.showRecurrent = true;

          break;
      }
      if(param === "ALL"){
        this.search = "";
      }
      this.loadDatas();
    },
    formataValor(data: number) {
      const valor = new Intl.NumberFormat("pt-BR", {
        style: "currency",
        currency: "BRL",
      }).format(data);

      return valor.toString().replace("R$", "").trim();
    },
    showIconStatusPagto(item: any) {
      // "ACTIVE" "CANCELLED" "MANUAL_RECONCILIATION" "FAILED" "PAID", "ARCHIVED"
      if (item.status === "CANCELED" || item.status === "CANCELLED") {
        return "cancelada";
      }
      if (item.status === "ARCHIVED") {
        return "arquivada";
      }
      if (item.status === "PAID") {
        return "paga";
      }
      const ToDate = new Date();
      if (
        !item.paymentDate &&
        new Date(item.dueDate).getTime() > ToDate.getTime()
      ) {
        return "ativa";
      }
      if (item.paymentDate) {
        return "paga";
      }
      if (
        !item.paymentDate &&
        new Date(item.dueDate).getTime() <= ToDate.getTime()
      ) {
        return "vencida";
      }

      if (
        !item.paymentDate &&
        new Date(item.dueDate).getTime() == ToDate.getTime()
      ) {
        return "ativa";
      }
    },
    cobrancaStatus(item: any) {
      const ToDate = new Date();
      if (item.status === "ACTIVE") {
        return '<v-icon color="orange darken-1">mdi-clock-time-three-outline</v-icon>';
      }
    },
    cancela(item: string) {
      let html = "";
      html += '<div class="text-center pt-7">';
      html +=
        '<svg style="width:64px;height:64px" viewBox="0 0 24 24">    <path fill="#6A1B9A" d="M8.27,3L3,8.27V15.73L8.27,21H15.73C17.5,19.24 21,15.73 21,15.73V8.27L15.73,3M9.1,5H14.9L19,9.1V14.9L14.9,19H9.1L5,14.9V9.1M9.12,7.71L7.71,9.12L10.59,12L7.71,14.88L9.12,16.29L12,13.41L14.88,16.29L16.29,14.88L13.41,12L16.29,9.12L14.88,7.71L12,10.59" /></svg>';
      html += '<h3 class=" mt-5 mb-5">Cancelar cobrança selecionada</h3>';
      html += "<br>";
      html +=
        '<p class="mt-5 mb=5">Uma vez que o cliente tenha recebido o boleto, nada o impede de realizar o pagamento. Neste caso entre em contato com o cliente solicitando desconsiderar a cobrança.</p>';
      html += "<br>";
      html += "</div>";
      Swal.fire({
        html: html,

        showCancelButton: true,
        confirmButtonText: "Confirmar",
        cancelButtonText: "Cancelar",
      }).then((result) => {
        if (result.isConfirmed) {
          Overlay.show();
          //Api.get("/v2/client/cobrancas/" + item + "/cancel")
          Api.delete("/v2/client/charges/" + item)
            .then((response) => {
              Snackbar.show(response.data.body.message);
              this.loadDatas();
            })
            .catch((error: any) => {
              const response = error.response as AxiosResponse;
              const status = response.status;
              const data = response.data;
              Snackbar.show(data.body.error, "error");
            })
            .finally(() => {
              Overlay.hide();
            });
        }
      });
    },
    unarchive(item: string) {
      let html = "";
      html += '<div class="text-center">';
      html +=
        '<svg style="width:64px;height:64px" viewBox="0 0 24 24">    <path fill="#6A1B9A" d="M20 21H4V10H6V19H18V10H20V21M3 3H21V9H3V3M5 5V7H19V5M10.5 17V14H8L12 10L16 14H13.5V17" /></svg>';
      html += '<h3 class=" mt-5 mb-5">Desarquivar cobrança selecionada</h3>';
      html += "<br>";
      html +=
        '<p class="mt-5 mb=5">Lembrando que ao arquivar uma cobrança ela continuará com seu status atual.<br> <span class="fw-bolder">(Exemplo: Se a cobrança está ativa ela continuará ativa).</span></p>';
      html += "<br>";
      html += "</div>";
      Swal.fire({
        html: html,

        showCancelButton: true,
        confirmButtonText: "Confirmar",
        cancelButtonText: "Cancelar",
      }).then((result) => {
        if (result.isConfirmed) {
          Overlay.show();
          Api.get("/v2/client/cobrancas/" + item + "/unarchive")
            .then((response) => {
              Snackbar.show(response.data.body.message);
              this.loadDatas();
            })
            .catch((error: any) => {
              const response = error.response as AxiosResponse;
              const status = response.status;
              const data = response.data;
              Snackbar.show(data.body.error, "error");
            })
            .finally(() => {
              Overlay.hide();
            });
        }
      });
    },
    archive(item: string) {
      let html = "";
      html += '<div class="text-center">';
      html +=
        '<svg style="width:64px;height:64px" viewBox="0 0 24 24">    <path fill="#6A1B9A" d="M20 21H4V10H6V19H18V10H20V21M3 3H21V9H3V3M5 5V7H19V5M10.5 11V14H8L12 18L16 14H13.5V11" /></svg>';
      html += '<h3 class=" mt-5 mb-5">Arquivar cobrança selecionada</h3>';
      html += "<br>";
      html +=
        '<p class="mt-5 mb=5">Lembrando que ao arquivar uma cobrança ela continuará com seu status atual.<br> <span class="fw-bolder">(Exemplo: Se a cobrança está ativa ela continuará ativa).</span></p>';
      html += "<br>";
      html += "</div>";
      Swal.fire({
        html: html,

        showCancelButton: true,
        confirmButtonText: "Confirmar",
        cancelButtonText: "Cancelar",
      }).then((result) => {
        if (result.isConfirmed) {
          Overlay.show();
          Api.get("/v2/client/cobrancas/" + item + "/archive")
            .then((response) => {
              Snackbar.show(response.data.body.message);
              this.loadDatas();
            })
            .catch((error: any) => {
              const response = error.response as AxiosResponse;
              const status = response.status;
              const data = response.data;
              Snackbar.show(data.body.error, "error");
            })
            .finally(() => {
              Overlay.hide();
            });
        }
      });
    },
    resendEmail(item: string) {
      let html = "";
      html += '<div class="text-center">';

      html += "<h3>Reenviar cobrança por e-mail</h3>";
      html +=
        "<p><small>As cobranças são sempre enviadas por e-mail no momento da criação (caso o cliente possua endereço de e-mail cadastrado). Deseja reenviar esta cobrança para o(s) endereço(s) de email do cliente?</small></p>";
      html += "</div>";
      Swal.fire({
        html: html,
        icon: "question",
        showCancelButton: true,
        confirmButtonText: "Enviar",
        cancelButtonText: "Não enviar",
      }).then((result) => {
        if (result.isConfirmed) {
          Overlay.show();
          Api.get("/v2/client/cobrancas/" + item + "/resend")
            .then((response) => {
              Snackbar.show(response.data.body.message);
            })
            .catch((error: any) => {
              const response = error.response as AxiosResponse;
              const status = response.status;
              const data = response.data;
              Snackbar.show(data.body.error, "error");
            })
            .finally(() => {
              Overlay.hide();
            });
        }
      });
    },
    doDelayedSearch() {
      if (this.timeout) {
        clearTimeout(this.timeout);
      }
      this.timeout = setTimeout(() => {
        //this is your existing function
        this.loadDatas();
      }, 1000);
    },
    loadDatas() {
      this.dialogFiltros = false;
      Overlay.show();
      const { sortBy, sortDesc, page, itemsPerPage } = this.options;
      this.loadingDesserts = true;
      Api.get("/v2/client/charges", {
        params: {
          query: this.search,
          page: page, // Certifique-se de que a página está correta aqui
          pageSize: itemsPerPage, // Certifique-se de que o tamanho da página está correto aqui
          orderBy: sortBy ? sortBy.toString() : undefined,
          sortDesc: sortDesc ? sortDesc.toString() : undefined,
          dueDateStart: this.filtrosCobrancas.dueDateStart,
          dueDateEnd: this.filtrosCobrancas.dueDateEnd,
          /*
          showArchived: this.filtrosCobrancas.showArchived,
          */
          chargeType: this.filtrosCobrancas.chargeType,
          createdOnStart: this.filtrosCobrancas.createdOnStart,
          createdOnEnd: this.filtrosCobrancas.createdOnEnd,
          paymentDateStart: this.filtrosCobrancas.paymentDateStart,
          paymentDateEnd: this.filtrosCobrancas.paymentDateEnd,
          showDue: this.filtrosCobrancas.showDue,
          showPaid: this.filtrosCobrancas.showPaid,
          /*
          showNotDue: this.filtrosCobrancas.showNotDue,
          showNotPaid: this.filtrosCobrancas.showNotPaid,
          */
          showCancelled: this.filtrosCobrancas.showCancelled,
          showRecurrent: this.filtrosCobrancas.showRecurrent,
        },
      })
        .then((response) => {
          
          this.desserts = response.data.body.rows as IbCobrancas[];
          this.totalDesserts = response.data.body.count.__total as number;
        })
        .finally(() => {
          Overlay.hide();
          this.loadingDesserts = false;
        });
    },
    viewChargeBankslip(transactionId: string) {
      return process.env.VUE_APP_API + "/charges/b/" + transactionId;
    },
    viewPublicChargeLink(transactionId: string) {
      return "/cob/f/" + transactionId;
    },
  },
  watch: {
    '$i18n.locale': {
      handler() {
        this.updateHeaders();
      },
      immediate: true
    },
    options: {
      handler() {
        this.loadDatas();
      },
    },
    search: {
      handler() {
        this.doDelayedSearch();
      },
    },
  },
});
