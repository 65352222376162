
import Vue from "vue";
import Api from "@/services/Api";
import { AxiosError, AxiosResponse } from "axios";
import Swal from "sweetalert2";
import { Snackbar } from "@/lib/Snackbar";

export interface cobrancasClientesNovo {
  id?: number;
  createdAt?: string;
  name?: string;
  taxId?: string;
  phone?: string;
  email?: string;
  street?: string;
  number?: string;
  complement?: string;
  neighborhood?: string;
  city?: string;
  state?: string;
  zipCode?: string;
  observations?: string;
}

export default Vue.extend({
  data: () => ({
    dialogNovoCliente: false,
    dialogImportCliente: false,
    formNovo: {} as cobrancasClientesNovo,
    btnLoading: false,

    id: Number,
    errors: [],
    isMobile: false,
    readonlyCidade: false,
    readonlyUF: false,
  }),
  created() {
    this.isMobile = this.detectMobile();
  },
  methods: {
    detectMobile() {
      if (screen.width <= 760) {
        return true;
      } else {
        return false;
      }
    },
    post() {
      this.btnLoading = true;
      this.errors = [];

      Api.post("/v2/client/create", this.formNovo)
        .then((response: AxiosResponse<cobrancasClientesNovo>) => {
          if (response.status == 200) {
            Snackbar.show("Cliente Salvo");
          }
          this.$emit("callmethod", 0);
          this.dialogNovoCliente = false;
          this.formNovo = {} as cobrancasClientesNovo;
        })
        .catch((e: AxiosError) => {
          const response = e.response as AxiosResponse;
          const status = response.status;
          const data = response.data;

          if (status == 422) {
            this.errors = data.body.errors;
          } else {
            Swal.fire({
              text: data.body.error,
              icon: "error",
              confirmButtonText: "Ok",
              showConfirmButton: true,
              customClass: {
                confirmButton: "d-block",
              },
            });
          }
        })
        .finally(() => {
          this.btnLoading = false;
        });
    },
    reset() {
      //this.$refs.form?.reset();
    },
    checkCEP: function (token: string) {
      this.errors = [];
      if (this.formNovo.zipCode?.length == 9) {
        this.btnLoading = true;
        const zipCode = this.formNovo.zipCode;
        const ulrConsult = process.env.VUE_APP_API + "/v2/client/address/" +zipCode;
        // Overlay.show();
        Api.get( ulrConsult )
          .then((response) => {
            this.formNovo.street = response.data.body.logradouro;
            this.formNovo.complement = response.data.body.complemento;
            this.formNovo.neighborhood = response.data.body.bairro;
            this.formNovo.city = response.data.body.localidade;
            this.formNovo.state = response.data.body.uf;

            if (this.formNovo.state?.length == 2) {
              this.readonlyUF = true;
            }
            this.$nextTick(() => {
              const wrapper = this.$refs.refLogradouro as any;

              window.setTimeout(() => wrapper?.focus(), 0);
            });

            // this.storage();
          })
          .catch((error: any) => {
            const response = error.response as AxiosResponse;
            const status = response.status;
            const data = response.data;

            if (status === 422) {
              this.errors = data.body.errors;
            } else {
              Swal.fire({
                text: data.body.error,
                icon: "error",
                confirmButtonText: "Ok",
                showConfirmButton: true,
                customClass: {
                  confirmButton: "d-block",
                },
              });
            }
          })
          .finally(() => {
            this.btnLoading = false;
            //  console.log("finalizou");
          });
      }
    },
    showFormImport() {
      this.dialogImportCliente = true;
    },
  },
});
