
import Vue from "vue";
import Api from "@/services/Api";
import { AxiosResponse } from "axios";
import { Overlay } from "@/lib/Overlay";
import moment from "moment";
import { Snackbar } from "@/lib/Snackbar";
import { TableOptions } from "@/models/TableOptions";

export interface AfiliadosGGRTable {
  nome?: string;
  documento?: string;
  email?: string;
  comissao?: string;
  status?: string;
}

export interface TableHeader {
  text: string | any;
  value: string;
  sortable: boolean;
  align?: string;
}

export default Vue.extend({
  name: "AfiliadosGGR",

  data() {
    return {
      hasLoadedData: false,
      loadingData: false,
      search: "",
      loadingDesserts: false,
      lang: navigator.language,
      stats: [],
      errors: [],
      moment: moment,
      isMobile: false,
      page: 1,
      pageCount: 0,
      itemsPerPage: 50,
      options: {} as TableOptions,
      headers: [] as TableHeader[],
      desserts: new Array<AfiliadosGGRTable>(),
      totalDesserts: 0,
      timeout: 0,
      breadcrumbs: [
        {
          text: this.$t("components.preCadastro.template.form.Home"),
          disabled: false,
          href: "/",
        },
        {
          text: "GGR",
          disabled: false,
          href: "/ggr",
        },
        {
          text: this.$t("components.preCadastro.template.form.Affiliates"),
          disabled: true,
          href: "/ggr/afiliados",
        },
      ],
    };
  },
  created() {
    this.isMobile = this.detectMobile();
    this.updateHeaders();
    this.updateBreadcrumbs();
  },
  watch: {
    '$i18n.locale': {
      handler() {
        this.updateHeaders();
        this.updateBreadcrumbs();
      },
      immediate: true,
    },
    options: {
      handler() {
        this.loadDatas();
      },
    },
    search: {
      handler() {
        this.doDelayedSearch();
      },
    },
  },
  methods: {
    translatedHeaders(): TableHeader[] {
      return [
        { text: this.$t('components.preCadastro.template.form.Data') as string, value: 'createdAt', sortable: false },
        { text: this.$t('components.preCadastro.template.form.Name') as string, value: 'nome', sortable: false },
        { text: this.$t('components.preCadastro.template.form.Document') as string, value: 'documento', sortable: false },
        { text: "E-mail", value: 'email', sortable: false },
        { text: this.$t('components.preCadastro.template.form.Comission') as string, value: 'comissao', align: 'right', sortable: false },
        { text: this.$t('components.preCadastro.template.form.Status') as string, value: 'status', sortable: false },
      ];
    },
    updateHeaders() {
      this.headers = this.translatedHeaders();
    },
    updateBreadcrumbs() {
      this.breadcrumbs = [
        {
          text: this.$t("components.preCadastro.template.form.Home"),
          disabled: false,
          href: "/",
        },
        {
          text: "GGR",
          disabled: false,
          href: "/ggr",
        },
        {
          text: this.$t("components.preCadastro.template.form.Affiliates"),
          disabled: true,
          href: "/ggr/afiliados",
        },
      ];
    },
    next(page: number) {
      this.page = page;
      this.loadDatas();
    },
    doDelayedSearch() {
      this.page = 1;
      if (this.timeout) {
        clearTimeout(this.timeout);
      }
      this.timeout = setTimeout(() => {
        this.loadDatas();
      }, 1000);
    },
    detectMobile() {
      return screen.width <= 760;
    },
    loadDatas() {
      this.hasLoadedData = true;
      Overlay.show();

      this.loadingData = true;

      this.loadingDesserts = true;
      Api.get("/v2/client/ggr/afiliados", {
        params: {
          query: this.search,
          page: this.page,
        },
      })
        .then((response) => {
          this.desserts = response.data.body.rows as AfiliadosGGRTable[];
          this.totalDesserts = Number(response.data.body.count.__total);
          this.loadingDesserts = false;
          this.loadingData = false;
        })
        .catch((error: any) => {
          const response = error.response as AxiosResponse;
          const status = response.status;

          if (status != 200) {
            Snackbar.show(response.data.body.error, "error");
          }
        })
        .finally(() => {
          Overlay.hide();
        });
    },
  },
});
