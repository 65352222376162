
import Vue from "vue";
import Api from "@/services/Api";
import { AxiosResponse } from "axios";
import { Overlay } from "@/lib/Overlay";
import moment from "moment";
import { Snackbar } from "@/lib/Snackbar";
import Swal from "sweetalert2";
export default Vue.extend({
  name: "AtividadesRecentes",

  data() {
    return {
      panel: 0,
      dialogAtividadesRecentes: false,
      atividadesRecentes: [],
      atividadesRecetesCount: 0,
      lang: navigator.language,
      errors: [],
      moment: moment,
    };
  },

  methods: {
    getIconDevice(channel: string) {
      let icon = "mdi-cellphone";
      switch (channel) {
        case "web":
          icon = "mdi-monitor";

          break;

        default:
          icon = "mdi-cellphone";
          break;
      }
      return icon;
    },
    getAtividadesRecentes() {
      Overlay.show();

      Api.get("/v2/client/profile/security/getActivity")
        .then((response) => {
          this.atividadesRecentes = response.data.body.rows;
          this.atividadesRecetesCount = response.data.body.count;
          this.dialogAtividadesRecentes = true;
        })

        .finally(() => {
          Overlay.hide();
        });
    },
  },
});
