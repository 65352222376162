
import Vue from "vue";
import Api from "@/services/Api";
import { AxiosResponse } from "axios";
import { Overlay } from "@/lib/Overlay";
import { Alert } from "@/lib/Alert";
import moment from "moment";
import { Snackbar } from "@/lib/Snackbar";
import Swal from "sweetalert2";
import VueApexCharts from "vue-apexcharts";
Vue.use(VueApexCharts);
export default Vue.extend({
  name: "AdicionarSaldoTED",

  data() {
    return {
      chartOptions: {
        series: [
          {
            name:  this.$t('components.ComparativoPeriodo.template.Inputs'),
            data: [0],
          },
          {
            name: this.$t('components.ComparativoPeriodo.template.Outputs'),
            data: [0],
          },
        ],
        colors: ["#42A5F5", "#E57373"],
        chart: {
          height: 250,
          type: "area",
          toolbar: { show: false },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "smooth",
        },
        xaxis: {
          type: "date",
          categories: [moment().format("YYYY-MM-DD")],
        },
        tooltip: {
          x: {
            format: "dd/MM/yyyy",
          },
        },
      },
      menuInitialDate: false,
      menuFinalDate: false,
      menuInitialDateMobile: false,
      ModuloInfo: [],
      loadingDesserts: true,

      breadcrumbs: [
        {
          text: "Home",
          disabled: false,
          href: "/",
        },
        {
          text: "Pix",
          disabled: false,
          href: "/pix",
        },
      ],
      lang: navigator.language,
      errors: [],
      translatedOrigemPix: [] as { id: string; descricao: string; }[],
      selectedEntryOrigemPix: { id: "PIX_API", descricao: "Mod API" },
      origemPix: [
        { id: "TODAS", descricao: "Todas" },
        { id: "PIX_API", descricao: "Mod API" },
        { id: "TRANSFER", descricao: "Mod Pagamentos" },
      ],
      data: {
        resultado: 0,
        entradas: {
          emitidos: 0,
          pagos: 0,
          vpagos: "0,00",
          ppagos: 0,
          expirados: 0,
          pexpirados: 0,
          pendentes: 0,
          ppendentes: 0,
          tarifas: "0,00",
          ticket: "0,00",
        },
        saidas: {
          emitidos: 0,
          pagos: 0,
          pdevolvidas: 0,
          vpagos: "0,00",
          ppagos: 0,
          pendentes: 0,
          ppendentes: 0,
          devolvidas: "0,00",
          tarifas: "0,00",
          tarifas_devolucoes: "0,00",
          ticket: "0,00",
          liquidoSaidas: "0,00",
          liquidoTarifas: "0,00",
        },
      },
      moment: moment,
      isMobile: false,
      startDate: moment().add(-1, "days").format("YYYY-MM-DD"),
      endDate: moment().format("YYYY-MM-DD"),

      defaultStartDate: moment().add(-1, "days").format("YYYY-MM-DD"),
      defaultEndDate: moment().endOf("month").format("YYYY-MM-DD"),

      maxDate: moment().format("YYYY-MM-DD"),
    };
  },
  components: {
    apexchart: VueApexCharts,
  },
  created() {
    this.translateOrigemPix();
    if (
      this.$route.query.startDate !== null ||
      this.$route.query.startDate !== undefined
    ) {
      this.startDate = String(this.$route.query.startDate);
    }
    if (
      this.$route.query.endDate !== null ||
      this.$route.query.endDate !== undefined
    ) {
      this.endDate = String(this.$route.query.endDate);
    }
    this.isMobile = this.detectMobile();
    this.getData();
  },

  methods: {
    detectMobile() {
      if (screen.width <= 760) {
        return true;
      } else {
        return false;
      }
    },
    getData() {
      const id = this.$route.query.id;

      Overlay.show();
      this.loadingDesserts = false;
      this.loadingDesserts = true;

      Api.get("/v2/client/financeiro/pix/relatorios/comparativoPeriodo", {
        params: {
          startDate: this.startDate,
          endDate: this.endDate,
          // pessoa: id,
          origemSaida: this.selectedEntryOrigemPix.id,
        },
      })
        .then((response) => {
          this.data.resultado = response.data.body.resultado;
          this.data.entradas = response.data.body.entradas;

          this.data.saidas = response.data.body.saidas;
          this.chartOptions.xaxis.categories =
            response.data.body.chartData.datas;
          this.chartOptions.series[0].data =
            response.data.body.chartData.series[0].data;
          this.chartOptions.series[1].data =
            response.data.body.chartData.series[1].data;
          this.loadingDesserts = false;
        })
        .catch((error: any) => {
          const response = error.response as AxiosResponse;
          const status = response.status;
          const data = response.data;

          if (status != 200) {
            //exe
          }
          // this.resetRecaptcha();
        })
        .finally(() => {
          this.loadingDesserts = false;
          Overlay.hide();
        });
    },
    translateOrigemPix() {
      this.translatedOrigemPix = this.origemPix.map(option => {
        const translationKey = `components.ComparativoPeriodo.template.OutputOriginSelect.${option.id}`;
        return {
          id: option.id,
          descricao: this.$t(translationKey) as string
        };
      });
    },
  },

  watch: {
    '$i18n.locale': function(newVal, oldVal) {
      this.chartOptions.series[0].name = this.$t('components.ComparativoPeriodo.template.Inputs');
      this.chartOptions.series[1].name = this.$t('components.ComparativoPeriodo.template.Outputs');

      this.chartOptions = Object.assign({}, this.chartOptions);
      this.translateOrigemPix();
    }
  },
});
