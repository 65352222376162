<template>
  <div>
    <v-list>
      <v-list-item-group>
        <v-list-item @click="openDialogChangeFoto()" two-line>
          <v-list-item-avatar size="80">
            <v-img :src="profileAvatar + '?v=' + rand"></v-img>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>{{ $t("components.preCadastro.template.form.Profilepicture") }}</v-list-item-title>
            <v-list-item-subtitle
              >{{ $t("components.preCadastro.template.form.Aphotohelpspersonalizeyouraccount") }}</v-list-item-subtitle
            >
          </v-list-item-content>
          <v-list-item-action>
            <v-icon> mdi-chevron-right </v-icon>
          </v-list-item-action>
        </v-list-item>
      </v-list-item-group>
    </v-list>

    <v-dialog
      v-model="dialogChangeFoto"
      persistent
      :fullscreen="isMobile"
      :max-width="isMobile ? 0 : 450"
    >
      <v-card>
        <v-toolbar flat>
          <v-btn icon @click="dialogChangeFoto = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>{{ $t("components.preCadastro.template.form.EditPhoto") }}</v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <div class="upload-example">
            <cropper
              ref="cropper"
              class="twitter-cropper"
              background-class="twitter-cropper__background"
              foreground-class="twitter-cropper__foreground"
              image-restriction="stencil"
              :stencil-size="stencilSize"
              :stencil-props="{
                lines: {},
                handlers: {},
                movable: false,
                scalable: false,
                aspectRatio: 1,
                previewClass: 'twitter-cropper__stencil',
              }"
              :resize-image="{
                adjustStencil: false,
              }"
              :transitions="false"
              :debounce="false"
              :default-size="defaultSize"
              :min-width="150"
              :min-height="150"
              :src="image.src"
              @change="onChange"
            />
            <navigation :zoom="zoom" @change="onZoom" />

            <div class="button-wrapper">
              <v-btn
                color="purple darken-3"
                class="text-none mb-5"
                block
                text
                @click="$refs.uploader.click()"
              >
                <v-icon left> mdi-cloud-upload-outline </v-icon>
                {{ $t("components.preCadastro.template.form.Selectaphoto") }}
              </v-btn>
              <input
                ref="uploader"
                class="d-none"
                type="file"
                @change="loadImage($event)"
              />
            </div>
          </div>
        </v-card-text>

        <v-card-actions>
          <v-btn color="purple darken-3" text @click="dialogChangeFoto = false">
            {{ $t("components.preCadastro.template.form.CANCEL") }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="purple darken-3" elevation="0" @click="crop()" dark>
            {{ $t("components.preCadastro.template.form.SAVE") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Vue from "vue";
import Auth from "@/lib/Auth";
import { Cropper } from "vue-advanced-cropper";
import Navigation from "./AvatarNavigation.vue";
import "vue-advanced-cropper/dist/style.css";
import { Overlay } from "@/lib/Overlay";
import Api from "@/services/Api";
import { Snackbar } from "@/lib/Snackbar";
import { AxiosResponse } from "axios";
import Swal from "sweetalert2";
export default Vue.extend({
  name: "AvatarUpload",
  data() {
    return {
      zoom: 0,
      isMobile: false,
      dialogChangeFoto: false,
      selectedFile: null,
      btnLoading: false,
      profileAvatar: "",
      image: {
        src: "",
        type: "",
      },
      coordinates: {
        width: 0,
        height: 0,
        left: 0,
        top: 0,
      },
      rand: 0,
    };
  },
  components: {
    Cropper,
    Navigation,
  },
  created() {
    this.rand = new Date().getTime();
    this.isMobile = this.detectMobile();
    const avatar = sessionStorage.userAvatar;
    this.image.src = avatar + "?v=" + this.rand;

    this.profileAvatar = avatar;
  },
  methods: {
    openDialogChangeFoto() {
      Overlay.show();
      this.$nextTick(() => {
        window.setTimeout(
          () =>
            (this.image.src = sessionStorage.userAvatar + "?v=" + this.rand),
          1000
        );
        window.setTimeout(() => (this.dialogChangeFoto = true), 1000);
        window.setTimeout(() => Overlay.hide(), 1000);
      });
    },
    detectMobile() {
      if (screen.width <= 760) {
        return true;
      } else {
        return false;
      }
    },
    randomNumber: function () {
      return Math.floor(Math.random() * (10 - 1 + 1)) + 1;
    },
    crop() {
      const { coordinates, canvas } = this.$refs.cropper.getResult();
      this.coordinates = coordinates;
      // You able to do different manipulations at a canvas
      // but there we just get a cropped image
      // console.log(canvas.toDataURL());
      this.upddateAvatar(canvas.toDataURL());
      //this.image = canvas.toDataURL();
    },
    upddateAvatar(img) {
      this.dialogChangeFoto = false;

      this.btnLoading = true;
      Overlay.show();
      Api.post("/v2/client/profile/avatar", { avatar: img })
        .then((response) => {
          //

          sessionStorage.setItem("userAvatar", response.data.body.imagem);
          this.dialogChangeFoto = false;
          Snackbar.show("Foto atualizada com sucesso");
          this.$router.go(this.$router.currentRoute);
        })
        .catch((error) => {
          const response = error.response;
          const status = response.status;
          const data = response.data;

          Swal.fire({
            text: data.body.error,
            icon: "error",
            confirmButtonText: "Ok",
            showConfirmButton: true,
            customClass: {
              confirmButton: "d-block",
            },
          });
          this.dialogChangeFoto = true;
        })
        .finally(() => {
          this.btnLoading = false;

          Overlay.hide();
        });
    },
    loadImage(event) {
      // Reference to the DOM input element
      const { files } = event.target;
      // Ensure that you have a file before attempting to read it
      if (files && files[0]) {
        // 1. Revoke the object URL, to allow the garbage collector to destroy the uploaded before file
        if (this.image.src) {
          URL.revokeObjectURL(this.image.src);
        }
        // 2. Create the blob link to the file to optimize performance:
        const blob = URL.createObjectURL(files[0]);

        // 3. The steps below are designated to determine a file mime type to use it during the
        // getting of a cropped image from the canvas. You can replace it them by the following string,
        // but the type will be derived from the extension and it can lead to an incorrect result:
        //
        // this.image = {
        //    src: blob;
        //    type: files[0].type
        // }

        // Create a new FileReader to read this image binary data
        const reader = new FileReader();
        // Define a callback function to run, when FileReader finishes its job
        reader.onload = (e) => {
          // Note: arrow function used here, so that "this.image" refers to the image of Vue component
          this.image = {
            // Set the image source (it will look like blob:http://example.com/2c5270a5-18b5-406e-a4fb-07427f5e7b94)
            src: blob,
            // Determine the image type to preserve it during the extracting the image from canvas:
            type: "",
          };
        };
        // Start the reader job - read file as a data url (base64 format)
        reader.readAsArrayBuffer(files[0]);
      }
    },
    destroyed() {
      // Revoke the object URL, to allow the garbage collector to destroy the uploaded before file
      if (this.image.src) {
        URL.revokeObjectURL(this.image.src);
      }
    },
    defaultSize({ imageSize }) {
      return {
        width: Math.min(imageSize.height, imageSize.width),
        height: Math.min(imageSize.height, imageSize.width),
      };
    },
    stencilSize({ boundaries }) {
      return {
        width: Math.min(boundaries.height, boundaries.width) - 48,
        height: Math.min(boundaries.height, boundaries.width) - 48,
      };
    },
    onChange(result) {
      const cropper = this.$refs.cropper;
      if (cropper) {
        const { coordinates, imageSize } = cropper;
        if (
          imageSize.width / imageSize.height >
          coordinates.width / coordinates.height
        ) {
          // Determine the position of slider bullet
          // It's 0 if the stencil has the maximum size and it's 1 if the has the minimum size
          this.zoom =
            (cropper.imageSize.height - cropper.coordinates.height) /
            (cropper.imageSize.height - cropper.sizeRestrictions.minHeight);
        } else {
          this.zoom =
            (cropper.imageSize.width - cropper.coordinates.width) /
            (cropper.imageSize.width - cropper.sizeRestrictions.minWidth);
        }
      }
    },
    onZoom(value) {
      const cropper = this.$refs.cropper;
      if (cropper) {
        if (cropper.imageSize.height < cropper.imageSize.width) {
          const minHeight = cropper.sizeRestrictions.minHeight;
          const imageHeight = cropper.imageSize.height;
          // Determine the current absolute zoom and the new absolute zoom
          // to calculate the sought relative zoom value
          cropper.zoom(
            (imageHeight - this.zoom * (imageHeight - minHeight)) /
              (imageHeight - value * (imageHeight - minHeight))
          );
        } else {
          const minWidth = cropper.sizeRestrictions.minWidth;
          const imageWidth = cropper.imageSize.width;
          cropper.zoom(
            (imageWidth - this.zoom * (imageWidth - minWidth)) /
              (imageWidth - value * (imageWidth - minWidth))
          );
        }
      }
    },
  },
});
</script>

<style lang="scss">
.twitter-cropper {
  height: 300px;
  &__background {
    background-color: #edf2f4;
  }
  &__foreground {
    background-color: #edf2f4;
  }
  &__stencil {
    border: solid 5px rgb(29, 161, 242);
  }
}
</style>
